import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';

import SectionContainer from '../../../common/Containers/Section';
import GroupMemberCard from './GroupMemberCard';
import MemberDescription from './GroupMemberCard/MemberDescription';
import Raters from './Raters';

import Button from '../../../common/Button';

import { ModalContext } from '../../../../context/Modal';

import ConfirmModal from '../../../common/Modal/ConfirmModal';

const GroupMembers = ({
  assessment,
  groupId,
  members,
  emailMemberReport,
  inviteToUpgrade,
  removeMember,
  remindMember,
  onCancelRater,
  isIbi,
  groupOrTeam,
  handleSetNotification,
  onResend,
  removeRater,
  memberWithNewRaters,
  totalPendingReminders,
  sendAllReminders,
  wasReminded,
  completedCount,
  emailAsmtGroupReport,
  disableGroupReport,
}) => {
  const [showRaters, setShowRaters] = useState(!!memberWithNewRaters);
  const [ratersForMember, setRatersForMember] = useState(memberWithNewRaters);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const ratersForMemberRef = useRef(null);

  useEffect(() => {
    if (memberWithNewRaters) {
      setShowRaters(true);
      setRatersForMember(memberWithNewRaters);
    }
  }, [memberWithNewRaters]);

  useEffect(() => {
    let timer;
    if (ratersForMemberRef.current) {
      timer = setTimeout(() => {
        ratersForMemberRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }, 250);
    }
    return () => clearTimeout(timer);
  }, [ratersForMember]);

  const toggleShowRaters = memberToShowRaters => {
    if (showRaters && ratersForMember === memberToShowRaters) {
      setShowRaters(false);
      setRatersForMember(null);
    } else {
      setShowRaters(true);
      setRatersForMember(memberToShowRaters);
    }
  };

  const handleOpenConfirmModal = () => {
    if (totalPendingReminders === 0) {
      handleOpenModal({
        content: <p className="text-base">All reminders processed. No pending actions from group members or raters.</p>,
      });
    } else {
      handleOpenModal({
        content: (
          <ConfirmModal
            confirmButtonText="Yes, Remind"
            onCancel={handleCloseModal}
            onConfirm={sendAllReminders}
            title="Send All Reminders"
            confirmText="REMIND"
          >
            <p className="text-base">This will send reminders to <b>{totalPendingReminders} {totalPendingReminders > 1 ? 'people' : 'person'}</b> for joining the group, completing the IBI, and/or completing the rater survey.</p>
            <p className="text-base">Enter <b>REMIND</b> into the box to confirm it.</p>
          </ConfirmModal>
        ),
      });
    }
  };

  return (
    <SectionContainer>
      <div className="inline-flex items-center justify-between w-full">
        <h4>{groupOrTeam} Members</h4>
        <div className="md:gap-2 md:flex">
          <Button
            className="block mb-4 ml-auto w-fit"
            isSmall
            isNormalCase
            variant="primary"
            onClick={handleOpenConfirmModal}
            disabled={wasReminded}
          >
            {wasReminded ? 'Sending Reminders' : 'Send All Reminders'}
          </Button>
          <Button
            className="block mb-4 ml-auto w-fit"
            isSmall
            isNormalCase
            variant="primary"
            disabled={disableGroupReport}
            onClick={emailAsmtGroupReport}
          >
            {groupOrTeam} Report ({completedCount.split(' ')[0]})
          </Button>
        </div>
      </div>
      <ul className="list-none">
        {members.map(member => {
          const {
            id,
            memberId,
            name,
            avatarUrl,
            surveyStatus,
            raters,
          } = member;
          const completedRaters = raters.filter(rater => rater.surveyStatus === 'finished').length;
          return (
            <li key={id} ref={id === ratersForMember ? ratersForMemberRef : null}>
              <GroupMemberCard
                id={id}
                avatarUrl={avatarUrl}
                memberId={memberId}
                displayName={name}
                displayDescription={(
                  <MemberDescription
                    {...member}
                    isIbi={isIbi}
                    inviteToUpgrade={inviteToUpgrade}
                  />
                )}
                surveyStatus={surveyStatus}
                isIbi={isIbi}
                emailMemberReport={emailMemberReport}
                removeMember={removeMember}
                remindMember={remindMember}
                withDrawer={!!(isIbi)}
                drawerLabel={`${member.raters.length ? 'Review Raters' : 'Add Raters'} (${completedRaters}/${raters.length})`}
                drawerContents={(
                  isIbi && (
                    <Raters
                      assessment={assessment}
                      groupId={groupId}
                      groupMember={member}
                      onCancelRater={onCancelRater}
                      handleSetNotification={handleSetNotification}
                      onResend={onResend}
                      removeRater={removeRater}
                    />
                  )
                )}
                showDrawerContents={showRaters && ratersForMember === id}
                toggleDrawer={toggleShowRaters}
                isIbi360={completedRaters >= 3}
              />
            </li>
          );
        })}
      </ul>
    </SectionContainer>
  );
};

GroupMembers.propTypes = {
  assessment: PropTypes.string.isRequired,
  groupId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
      avatarUrl: PropTypes.string,
      dateJoined: PropTypes.string,
      surveyStatus: PropTypes.string,
      remindedAt: PropTypes.string,
      accessCode: PropTypes.string,
    }),
  ).isRequired,
  emailMemberReport: PropTypes.func.isRequired,
  inviteToUpgrade: PropTypes.func.isRequired,
  removeMember: PropTypes.func.isRequired,
  remindMember: PropTypes.func.isRequired,
  onCancelRater: PropTypes.func.isRequired,
  isIbi: PropTypes.bool.isRequired,
  groupOrTeam: PropTypes.string.isRequired,
  handleSetNotification: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  removeRater: PropTypes.func.isRequired,
  memberWithNewRaters: PropTypes.string,
  totalPendingReminders: PropTypes.number,
  sendAllReminders: PropTypes.func.isRequired,
  wasReminded: PropTypes.bool,
  emailAsmtGroupReport: PropTypes.func.isRequired,
  disableGroupReport: PropTypes.bool,
  completedCount: PropTypes.string.isRequired,
};

GroupMembers.defaultProps = {
  memberWithNewRaters: null,
  totalPendingReminders: 0,
  wasReminded: false,
  disableGroupReport: false,
};

export default GroupMembers;
