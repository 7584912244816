import React, { useContext, useEffect, useReducer, useRef, useState } from 'react';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { addMinutes } from 'date-fns';

import { APIContext } from '../../context/API';
import { CurrentUserContext } from '../../context/CurrentUser';

import withServerSideData from '../../HOC/withServerSideData';

import ServerError from '../ServerError';
import PageHeader from '../PageHeader';
import DashboardNotifications from '../Notifications/DashboardNotifications';
import PaymentViaUrl from '../PaymentViaUrl';
import DashboardBlockSearch from './DashboardBlockSearch';
import DashboardBlocks from './DashboardBlocks';
import DashboardEvents from './Events';
import DashboardTips from './Tips';

import { trackViewDashboard, trackDashboardBlocksSearch } from '../../lib/tracker/dashboard';

import { formatSessionDates } from '../../lib/aperianLive';

const Dashboard = ({ initialData, location }) => {
  const { currentUser } = useContext(CurrentUserContext);

  const containerRef = useRef();

  const {
    token: authToken,
    accessLevel,
    organizations,
    allowTipsShowing,
    allowAperianLiveShowing,
  } = currentUser;

  const { apiService } = useContext(APIContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...initialData,
    error: '',
    currentAccessLevel: accessLevel,
  });

  const [filterValue, setFilterValue] = useState('');
  const [blocks, setBlocks] = useState(initialData.blocks);

  useEffect(() => {
    const previousAccessLevel = state.currentAccessLevel;
    if (currentUser.accessLevel.length !== previousAccessLevel.length) {
      Dashboard.getData(apiService)
        .then(({ dashboard }) => {
          setState({
            ...dashboard,
            currentAccessLevel: currentUser.accessLevel,
          });
          setBlocks(dashboard.blocks);
        });
    }
  }, [currentUser.accessLevel]);

  useEffect(() => {
    trackViewDashboard(organizations);
    const nowPlus15 = addMinutes(new Date(), 15);
    const formattedSessions = formatSessionDates(state.sessions);
    const upcomingSessions = formattedSessions
      .filter(session => new Date(session.dateTime) >= nowPlus15);
    if (upcomingSessions) {
      setState({ sessions: formattedSessions });
    }
  }, []);

  useEffect(() => {
    if (!filterValue) return setBlocks(state.blocks);
    const delayDebounceFn = setTimeout(() => {
      const specificKeys = ['title', 'typeName', 'tags', 'description', 'cultures', 'languages'];
      const searchTerm = filterValue.toLowerCase();
      const filteredBlocks = state.blocks.filter(block =>
        specificKeys.some(key =>
          block[key]?.toString().toLowerCase().includes(searchTerm)
          || JSON.stringify(block[key])?.toLowerCase().includes(searchTerm),
        ));
      setBlocks(filteredBlocks);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [filterValue]);

  const handleClearFilter = e => {
    e.preventDefault();
    setFilterValue('');
  };

  const handleChange = e => setFilterValue(e.target.value);

  const handleInputOnBlur = () => {
    if (filterValue.length > 2) {
      trackDashboardBlocksSearch(filterValue);
    }
  };

  const OrganizationMessages = ({ orgMessages, className }) => orgMessages
    .map(orgMessage => (
      <div className={className} key={Math.random()}>
        {parser(DOMPurify.sanitize(orgMessage, { ADD_ATTR: ['target'] }))}
      </div>
    ));

  const hasAperianLive = accessLevel.some(level => level.includes('aperianlive') || level.includes('premium'));
  const hasSessions = state.sessions.length > 0;

  if (state.error) return <ServerError pageTitle="Dashboard" error={state.error} />;
  if (!authToken) return null;

  return (
    <>
      <PageHeader pageTitle="Dashboard" skipTarget="#dashboard" hideHeading />
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
        <div
          className="xl:col-span-12 data-[live='true']:col-start-1 xl:col-start-3"
          data-live={(hasAperianLive && hasSessions && allowAperianLiveShowing) || allowTipsShowing}
        >
          <DashboardNotifications location={location} />
          <PaymentViaUrl location={location} />
        </div>
        <div
          className="flex flex-col xl:col-span-12 data-[live='true']:col-start-1 xl:col-start-3"
          data-live={(hasAperianLive && hasSessions && allowAperianLiveShowing) || allowTipsShowing}
        >
          <div className="flex flex-col-reverse gap-4 lg:flex-row">
            <div className="flex flex-col w-full gap-4 lg:w-2/3">
              <div className="hidden top-0 z-[301] overflow-hidden lg:sticky lg:block">
                <DashboardBlockSearch
                  filterValue={filterValue}
                  handleChange={handleChange}
                  handleClearFilter={handleClearFilter}
                  handleInputOnBlur={handleInputOnBlur}
                />
              </div>
              <div className="hidden lg:block">
                {!filterValue
                  ? <OrganizationMessages orgMessages={state.organizationMessages} />
                  : null}
              </div>
              <DashboardBlocks
                currentUser={currentUser}
                blocks={blocks}
                filterValue={filterValue}
                handleClearFilter={handleClearFilter}
              />
            </div>
            <div className="flex flex-col w-full h-auto gap-4 lg:w-1/3 lg:pt-4">
              <div className="block lg:hidden z-[301]">
                <DashboardBlockSearch
                  filterValue={filterValue}
                  handleChange={handleChange}
                  handleClearFilter={handleClearFilter}
                  handleInputOnBlur={handleInputOnBlur}
                />
              </div>
              <div className="block lg:hidden">
                {!filterValue
                  ? <OrganizationMessages orgMessages={state.organizationMessages} />
                  : null}
              </div>
              <div className="top-4 lg:sticky z-[301] flex flex-col gap-4 h-fit">
                {(!filterValue && state.sessions.length && allowAperianLiveShowing)
                  ? <DashboardEvents sessions={state.sessions} />
                  : null}
                {!filterValue && allowTipsShowing
                  ? <DashboardTips tips={state.tips} accessLevel={accessLevel} />
                  : null}
              </div>
            </div>

          </div>
        </div>
        <div ref={containerRef?.current?.elementRef || null} />
      </div>
    </>
  );
};

Dashboard.getAPIDataKey = () => 'dashboard';

Dashboard.getData = apiService => apiService.get('dashboard').then(data => ({ dashboard: data }));

Dashboard.propTypes = {
  initialData: PropTypes.shape({
    dashboard: PropTypes.shape({
      hasCompletedIBISurvey: PropTypes.bool.isRequired,
    }),
    organizationMessages: PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ),
    blocks: PropTypes.arrayOf(PropTypes.shape({
      blockImage: PropTypes.string,
      blockType: PropTypes.number,
      description: PropTypes.string,
      id: PropTypes.number,
      internalName: PropTypes.string,
      languages: PropTypes.arrayOf(PropTypes.string),
      moduleId: PropTypes.number,
      moduleRangeEnd: PropTypes.string,
      moduleRangeStart: PropTypes.string,
      sortOrder: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
      typeName: PropTypes.string,
    })).isRequired,
    sessions: PropTypes.arrayOf(
      PropTypes.shape({
        cohortCode: PropTypes.string,
        dateTime: PropTypes.string,
        description: PropTypes.string,
        duration: PropTypes.number,
        facilitator: PropTypes.string,
        isRegistered: PropTypes.bool,
        name: PropTypes.string,
        nameInternal: PropTypes.string,
        prework: PropTypes.arrayOf(PropTypes.string),
        tags: PropTypes.arrayOf(PropTypes.string),
        userJoinUrl: PropTypes.string,
      })),
    dashboardLayout: PropTypes.number,
    tips: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        text: PropTypes.string,
        tipLink: PropTypes.string,
        flagIcon: PropTypes.string,
        guidesLink: PropTypes.string,
      }),
    ),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    query: PropTypes.shape({
    }),
  }).isRequired,
};

export default withServerSideData(Dashboard);
