import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const DeleteSegmentModal = ({ onConfirm, onCancel }) => (
  <>
    <h2>Are you sure you want to delete this SAML property?</h2>
    <p className="mt-0.5 text-left">
      This cannot be undone. Any records associated with this SAML property will be removed.
    </p>
    <div className="flex justify-end gap-2">
      <Button onClick={onCancel}>No, Cancel</Button>
      <Button onClick={onConfirm} outlinedColor="red">Yes, Delete SAML Property</Button>
    </div>
  </>
);

DeleteSegmentModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default DeleteSegmentModal;
