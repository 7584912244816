import React from 'react';
import PropTypes from 'prop-types';

import BlockTimeRange from '../BlockTimeRange';
import Badges from '../../common/Badges';
import Icon from '../../common/Icon';
import BlockButton from '../BlockButton';
import Popover from '../../common/Popover';

import { translations } from '../../../lib/constants';

const BlockPopoverContent = (
  { title,
    tags,
    description,
    languages },
) => {
  const languageNames = translations
    .filter(language => languages?.includes(language.value))
    .map(({ name }) => name);
  return (
    <>
      <h4 className="-mb-1 font-serif text-xl font-semibold leading-snug tracking-wider text-left text-charcoal-900 md:text-base">
        {title}
      </h4>
      <Badges badges={tags} size="small" />
      <div className="border-b border-rust-500" />
      <p className="mt-2 font-sans text-sm font-light text-left md:text-base">
        {description}
      </p>
      {languageNames.length ? (
        <p className="pt-2 font-sans text-xs font-light text-left md:text-sm">
          Available languages: {
            languageNames.length > 2
              ? languageNames.join(', ').replace(/,([^,]*)$/, ', and$1')
              : languageNames.join(' and ')
          }
        </p>
      ) : null}
    </>
  );
};

BlockPopoverContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  languages: PropTypes.arrayOf(PropTypes.string),
};

BlockPopoverContent.defaultProps = {
  title: '',
  description: '',
  tags: [],
  languages: [],
};

const PortraitBlock = ({
  internalName,
  connection,
  blockType,
  title,
  description,
  completionRangeStart,
  completionRangeEnd,
  tags,
  blockLandscapeImage,
  defaultImageLink,
  buttonText,
  moduleId,
  directUrl,
  className,
  languages,
  status,
}) => (
  <Popover
    onHover
    position="right"
    content={(
      <BlockPopoverContent
        title={title}
        description={description}
        completionRangeStart={completionRangeStart}
        completionRangeEnd={completionRangeEnd}
        languages={languages}
        tags={tags}
      />
    )}
  >
    <div className={`${className} flex flex-col ${status === 'completed' ? 'bg-charcoal-900 bg-opacity-15' : 'bg-white'} rounded-md shadow-sm md:min-h-[410px]`}>
      {status && (
        /* eslint-disable no-nested-ternary */
        <div className={`absolute top-3 right-3 p-2 rounded-full font-sans text-xs font-semibold uppercase ${status === 'completed' ? 'bg-green-300' : status === 'in-progress' ? 'bg-sunshine-400' : ''}`}>
          {status}
        </div>
      )}
      <img
        src={blockLandscapeImage || defaultImageLink}
        alt={`${title} logo`}
        className="object-cover w-full rounded-t-md h-36"
      />
      <div className="flex flex-col justify-between flex-grow p-4">
        <div>
          <h4 className="flex items-center font-serif text-xl font-semibold leading-snug tracking-wider text-left text-charcoal-900 md:text-xl min-h-14">
            {title}
          </h4>
          <hr className="mb-2 border-b border-rust-500" />
          <div className={`flex ${completionRangeStart ? 'mt-2 mb-4' : 'mt-0 mb-2'} items-center`}>
            {(completionRangeStart) ? (
              <Icon
                icon="clock"
                iconColor="medium-primary"
                className="w-3 h-3"
              />
            ) : null}
            <BlockTimeRange
              completionRangeStart={completionRangeStart}
              completionRangeEnd={completionRangeEnd}
            />
          </div>
          <p className="font-sans text-sm font-light text-left md:text-base line-clamp-3">
            {description}
          </p>
        </div>
        <div>
          <BlockButton
            connection={connection}
            buttonText={buttonText}
            moduleId={moduleId}
            directUrl={directUrl}
            blockType={blockType}
            internalName={internalName}
            title={title}
            className={`w-full ${status === 'completed' && 'bg-stone-600'}`}
            launchedFrom="LM Home"
            status={status}
          />
        </div>
      </div>
    </div>
  </Popover>
);

PortraitBlock.propTypes = {
  connection: PropTypes.string,
  blockType: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  completionRangeStart: PropTypes.string,
  completionRangeEnd: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  blockLandscapeImage: PropTypes.string,
  defaultImageLink: PropTypes.string,
  buttonText: PropTypes.string,
  moduleId: PropTypes.number,
  directUrl: PropTypes.string,
  internalName: PropTypes.string,
  className: PropTypes.string,
  languages: PropTypes.arrayOf(PropTypes.string),
  status: PropTypes.string,
};

PortraitBlock.defaultProps = {
  connection: 'auth0',
  blockType: 0,
  title: 'Learning Modules',
  description: 'Block Description',
  completionRangeStart: '',
  completionRangeEnd: '',
  tags: [],
  blockLandscapeImage: '',
  buttonText: '',
  defaultImageLink: '',
  moduleId: 0,
  directUrl: '',
  internalName: '',
  className: '',
  languages: [],
  status: '',
};

export default PortraitBlock;
