/* eslint-disable prefer-arrow-callback */
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../common/Checkbox';
import DropdownSelect from '../../../common/Dropdowns/DropdownSelect';
import LabelInput from '../../../common/LabelInput';
import TimePickerInput from '../../../common/TimePicker';

import { platforms, translations } from '../../../../lib/constants';

import AutoSuggest from '../../../AutoSuggest';
import {
  determineAperianLiveType,
  determineIbiCertificationType,
  determineGspCertificationType,
} from '../../../../lib/cohorts/determineCohortByType';

const VirtualEventsCohortForm = forwardRef(
  function VirtualEventsForm({
    formData,
    handleFormData,
    plans,
    facilitators,
    topicOptions,
    formError,
  }, ref) {
    const timeRef = useRef(null);
    const stripeRef = useRef(null);

    useEffect(() => {
      handleFormData({
        displayName: (determineAperianLiveType((formData.type)))
          ? topicOptions?.at(0)?.value
          : null,
        name: null,
        facilitator: facilitators?.at(0)?.value.toString() || '',
        timezone: facilitators?.at(0)?.timezone || null,
        meetingPlatform: 'Zoom',
        language: 'en',
        eventDate: null,
        evaluationLink: null,
        org: null,
        linkExpiryDate: null,
        cohortTeam: false,
        cohortGroup: false,
      });
    }, []);

    const validateForm = () => {
      const errors = {};

      if ((!formData.stripePlans || formData.stripePlans.length === 0)
        && !determineAperianLiveType(formData.type)) {
        errors.stripePlans = 'Please select a plan for your cohort.';
      }

      if (!formData.eventDate) {
        errors.eventDate = 'Please select a date and time for your event.';
      }

      if (determineIbiCertificationType(formData.type)
        && formData.eventDate && formData.eventDate.length < 3) {
        errors.eventDate = 'Please select minimum 3 dates.';
      }

      return Object.keys(errors).length > 0 ? errors : null;
    };

    const resetForm = () => {
      handleFormData({
        displayName: topicOptions?.at(0)?.value,
        name: null,
        facilitator: facilitators?.at(0)?.value.toString() || '',
        timezone: facilitators?.at(0)?.timezone || null,
        meetingPlatform: 'Zoom',
        language: 'en',
        eventDate: null,
        evaluationLink: null,
        org: null,
        linkExpiryDate: null,
        cohortTeam: false,
        cohortGroup: false,
      });
    };

    useImperativeHandle(ref, () => ({
      validateForm,
      resetForm,
    }));

    const renderTimeZone = () => {
      const facilitatorValue = parseInt(formData?.facilitator, 10);
      const facilitatorObject = facilitators.find(obj => obj.value === facilitatorValue);
      if (facilitatorObject) {
        return facilitatorObject.timezone;
      }
      return null;
    };

    const handleChange = ({ target: { name, value } }) => {
      handleFormData({ [name]: value });
      if (name === 'facilitator') {
        const facilitatorValue = parseInt(value, 10);
        const facilitatorObject = facilitators.find(obj => obj.value === facilitatorValue);
        handleFormData({ timezone: facilitatorObject.timezone });
      }
    };

    const handleCheckboxChange = ({ value, checked }) => {
      handleFormData({ [value]: checked });
    };

    const handleTimeChange = time => handleFormData({
      eventDate: time,
      linkExpiryDate: time,
    });

    const handleRemovePlan = value => {
      handleFormData({
        stripePlans: formData.stripePlans.filter(plan => plan.value !== value),
      });
    };

    const isCertification = determineIbiCertificationType(formData.type)
    || determineGspCertificationType(formData.type);

    const isIBICertification = determineIbiCertificationType(formData.type);

    return (
      <>
        {(isCertification)
          ? (
            <div className="mb-4">
              <LabelInput
                id="displayName"
                name="displayName"
                labelType="text"
                isRequired
                labelText="Display Name"
                value={formData.displayName}
                onChangeValue={handleChange}
                helperText="Enter a friendly name that will appear to cohort members."
              />
            </div>
          )
          : (
            <DropdownSelect
              id="displayName"
              name="displayName"
              labelText="Display Name"
              helperText="Select a description that will appear to cohort members."
              isRequired
              options={topicOptions}
              value={formData.displayName}
              onChangeValue={handleChange}
            />
          )}
        <div className="mb-4">
          <LabelInput
            id="name"
            name="name"
            labelType="text"
            isRequired
            labelText="Name (Internal)"
            value={formData.name}
            onChangeValue={handleChange}
            helperText="Enter an internal name for search indexing."
          />
        </div>
        {(isCertification)
          ? (
            <div className="mb-4">
              <AutoSuggest
                ref={stripeRef}
                name="stripePlans"
                placeholder="Search or View All Plans"
                options={plans}
                selectedOptions={formData?.stripePlans}
                onSelectOption={handleChange}
                onRemoveSelectedOption={handleRemovePlan}
                isMultiSelect
                helperText="Select the products this cohort needs. Choose the most appropriate time length."
                labelText="Select Plan"
                errorMessage={formError.stripePlans}
              />
            </div>
          ) : null}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <DropdownSelect
              id="facilitator"
              name="facilitator"
              labelType="text"
              labelText="Facilitator"
              helperText="Choose a facilitator for your event."
              isRequired
              options={facilitators}
              value={formData?.facilitator || ''}
              onChangeValue={handleChange}
            />
          </div>
          <div>
            <LabelInput
              id="timezone"
              name="timezone"
              labelType="text"
              isRequired
              labelText="Facilitator Timezone"
              value={formData.timezone || renderTimeZone()}
              onChangeValue={handleChange}
              isDisabled
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-2">
          <div>
            <TimePickerInput
              ref={timeRef}
              id="eventDate"
              labelText="Event Time"
              handleIconClick={() => timeRef.current.setFocus()}
              value={formData.eventDate}
              handleTimeChange={handleTimeChange}
              onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              onChangeValue={handleChange}
              showTimeSelect
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="yyyy-MM-dd hh:mm aa"
              helperText="Choose the time of your event in the facilitator's time zone."
              errorMessage={formError.eventDate}
              multiple={determineIbiCertificationType(formData.type) === true}
            />

          </div>
          <div>
            <DropdownSelect
              id="language"
              name="language"
              labelType="text"
              labelText="Language"
              options={translations}
              value={formData.language || 'en'}
              onChangeValue={handleChange}
              isDisabled
              helperText="Choose the language for your event."
            />
          </div>
        </div>
        <div>
          <DropdownSelect
            id="meetingPlatform"
            name="meetingPlatform"
            labelType="text"
            labelText="Meeting Platform"
            options={platforms}
            value={formData.meetingPlatform}
            onChangeValue={handleChange}
            isDisabled
            helperText="Choose the meeting platform for your event."
          />
        </div>
        <LabelInput
          id="evaluationLink"
          name="evaluationLink"
          labelType="text"
          labelText="Evaluation Link"
          value={formData.evaluationLink}
          onChangeValue={handleChange}
          helperText="Enter the link to the post-evaluation survey"
        />
        {isCertification ? (
          <div className="flex flex-row items-center gap-2">
            <p className="block mb-0 font-sans text-sm font-medium leading-6 text-left text-gray-700">Create {isIBICertification ? 'an IBI Group' : 'a GSP Team'}</p>
            {isIBICertification ? (
              <Checkbox
                id="cohortGroup"
                name="cohortGroup"
                checked={formData.cohortGroup}
                onChange={e => handleCheckboxChange(e.target)}
              />
            ) : (
              <Checkbox
                id="cohortTeam"
                name="cohortTeam"
                checked={formData.cohortTeam}
                onChange={e => handleCheckboxChange(e.target)}
              />
            )}
          </div>
        ) : null}
      </>
    );
  });

VirtualEventsCohortForm.propTypes = {
  handleFormData: PropTypes.func.isRequired,
  plans: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  formData: PropTypes.shape({
    type: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    eventDate: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.array,
    ]),
    evaluationLink: PropTypes.string,
    eventPrework: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    eventTime: PropTypes.instanceOf(Date),
    facilitator: PropTypes.string,
    language: PropTypes.string,
    meetingPlatform: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
    timezone: PropTypes.string,
    name: PropTypes.string,
    displayName: PropTypes.string,
    stripePlans: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
      })),
    cohortTeam: PropTypes.bool,
    cohortGroup: PropTypes.bool,
  }).isRequired,
  facilitators: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      timezone: PropTypes.string,
    })),
  topicOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  formError: PropTypes.shape({
    eventDate: PropTypes.string,
    eventTime: PropTypes.string,
    eventDuration: PropTypes.string,
    stripePlans: PropTypes.string,
  }),
};

VirtualEventsCohortForm.defaultProps = {
  formError: {},
  facilitators: [],
  topicOptions: [],
  plans: [],
};

export default VirtualEventsCohortForm;
