import React, { useContext, useEffect, useReducer } from 'react';
import qs from 'query-string';
import PropTypes from 'prop-types';

import withServerSideData from '../../HOC/withServerSideData';

import { RouterContext } from '../../context/Router';
import { LanguageContext } from '../../context/Language';
import { CurrentUserContext } from '../../context/CurrentUser';

import PageHeader from '../PageHeader';
import FullScreenHeader from '../FullScreen/FullScreenHeader';
import FullScreenBody from '../FullScreen/FullScreenBody';
import ProfileGroup from '../ProfileCards/ProfileGroup';
import NewComparisonCTA from './NewComparisonCTA';
import PaymentViaUrl from '../PaymentViaUrl';
import ServerError from '../ServerError';

import { useFilter } from '../../hooks/useFilter';

import PremiumFeature from '../../lib/features/Premium';
import NeedsCompletedSurvey from '../../lib/NeedsCompletedSurvey';
import { GS_PREMIUM_STRIPE_PLAN } from '../../lib/constants';
import { trackBeginNewComparison } from '../../lib/tracker/comparison';

const paymentUrl = `/profile/comparison/new?purchase&plan=${GS_PREMIUM_STRIPE_PLAN}`;

const NewComparison = ({ initialData }) => {
  const { router } = useContext(RouterContext);
  const { selectedLanguage } = useContext(LanguageContext);
  const { currentUser } = useContext(CurrentUserContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    ...initialData,
    selectedComparables: [],
    filteredComparables: [],
    currentAccessLevel: currentUser.accessLevel,
  });
  const { filterValue, setFilterValue, filteredItems } = useFilter(state.compareTargets, { keys: ['name', 'values.*.name'] });

  const assembleCardData = cards => {
    if (!cards) return null;
    return (cards || [])
      .map(({ id, name, type, avatarUrl, values }) => {
        const displayName = type === 'org' ? `${name} Average` : name;
        return ({
          id,
          type,
          avatarUrl,
          displayName,
          displayDescription: '',
          isComparisonItem: true,
          values: type === 'segment' ? values : null,
        });
      })
      .sort((a, b) => {
        const nameA = a.displayName.toUpperCase(); // ignore upper and lowercase
        const nameB = b.displayName.toUpperCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
  };

  const getPreviousSelections = () => {
    /*
      The properties destructured off of the state object are ids from
      the url params and refer to the different compare selection types
        state: {
          culture: [ "AO", "AR"],
          person: [ "8361c755-8a74-4f24-860c-3397794413ff" "0d03001e-3496-4d56-8506-48b88c599f47"],
          team_members: "182",
          org: null,
          segment:"1"
        }
      If there was more than ome selection of a type, some of these properties can be arrays so
      we need to create a flat array of ids to pass to the compareObjects array
    */
    const { culture, person, teams_members: teamsMembers, org, segment } = state;
    const selectionIds = [culture, person, teamsMembers, org];

    const prevSelections = selectionIds
      .flat()
      .filter(selectionId => !!selectionId)
      .reduce((comparables, selectionId) => {
        const comparable = filteredItems.find(({ id }) => id.toString() === selectionId);
        if (comparable) comparables.push(comparable);
        return comparables;
      }, []);

    const prevSegments = [segment]
      .flat()
      .filter(segmentValueId => !!segmentValueId)
      .reduce((segmentValues, segmentValueId) => {
        const segments = filteredItems.filter(fi => fi.type === 'segment');
        const comparable = segments.find(({ values }) =>
          values.some(value => value.id.toString() === segmentValueId));

        const updatedComparableValues = comparable.values.map(cv =>
          (cv.id.toString() === segmentValueId ? { ...cv, checked: true } : cv));

        if (comparable) {
          comparable.values = updatedComparableValues;
          segmentValues.push(comparable);
        }
        return segmentValues;
      }, []);

    const uniqueSegments = [...new Set([...prevSegments])];

    setState({ selectedComparables: [...prevSelections, ...uniqueSegments] });
  };

  useEffect(() => {
    getPreviousSelections();
  }, []);

  useEffect(() => {
    const previousAccessLevel = state.currentAccessLevel;
    if (currentUser.accessLevel.length !== previousAccessLevel.length) {
      getPreviousSelections();
      setState({ currentAccessLevel: currentUser.accessLevel });
    }
  }, [currentUser.accessLevel]);

  const handleCheckboxChange = (item, include) => {
    const comparable = state.compareTargets.find(ct => ct.id === item.id);

    if (!comparable) return;

    const comparablesWithNew = [...new Set([...state.selectedComparables, ...[comparable]])];
    let comparablesWithRemoved = state.selectedComparables.filter(sc => sc.id !== item.id);

    if (item.type === 'segment') {
      if (item.values) {
        comparable.values = item.values;
      }

      if (item.valueId && !include) {
        const updatedValues = comparable.values
          .map(value => (value.id === item.valueId ? { ...value, checked: include } : value));

        comparable.values = updatedValues;
      }

      if (comparable.values.some(value => !!value.checked)) {
        const segmentWithRemainingSelections = state.selectedComparables
          .map(sc => (sc.id === item.id ? comparable : sc));

        comparablesWithRemoved = segmentWithRemainingSelections;
      }
    }
    setState({ selectedComparables: include ? comparablesWithNew : comparablesWithRemoved });
    setFilterValue('');
  };

  const compareTargets = types => {
    if (!filteredItems) return [];
    return filteredItems.filter(c => types.includes(c.type));
  };

  const handleCancel = () => {
    router.goBack();
  };

  const handleClickToCompare = () => {
    const queryStringData = state.selectedComparables
      .reduce((comparableObject, comparable) => {
        if (comparable.type === 'segment') {
          comparable.values.forEach(v => {
            if (v.checked) comparableObject[comparable.type].push(v.id);
          });
        } else {
          comparableObject[comparable.type].push(comparable.id);
        }
        return comparableObject;
      }, { person: [], team: [], org: [], culture: [], segment: [] });

    const queryString = qs.stringify({
      person: queryStringData.person,
      teams_members: queryStringData.team,
      teams_average: queryStringData.team,
      org: queryStringData.org,
      culture: queryStringData.culture,
      segment: queryStringData.segment,
      language: selectedLanguage,
    });
    trackBeginNewComparison()
      .then(() => router.push(`/profile/comparison?${queryString}`));
  };

  const clearAll = () => {
    const seletedSegmentIds = state.selectedComparables.filter(sc => sc.type === 'segment');
    if (seletedSegmentIds.length > 0) {
      const setAllUnchecked = array => array.map(item => ({ ...item, checked: false }));
      const updatedCompareTargets = filteredItems.map(fi => ((fi.type === 'segment')
        ? { ...fi, values: setAllUnchecked(fi.values) }
        : fi));

      setState({
        selectedComparables: [],
        compareTargets: updatedCompareTargets,
      });
    } else {
      setState({ selectedComparables: [] });
    }
    router.push('/profile/comparison/new');
  };

  const hasPremium = () => new PremiumFeature(currentUser)
    .positive(() => true)
    .negative(() => false)
    .execute();

  const sendInvitationsLink = () => (hasPremium() ? '/invitations/new' : paymentUrl);

  const createTeamLink = () => (hasPremium() ? '/profile/teams/new' : paymentUrl);

  const hasNoCompareTargets = () => state.selectedComparables.length === 0;

  const { compareTarget, selectedComparables, error } = state;
  if (error) return <ServerError pageTitle="New Comparison" error={error} />;

  return (
    <>
      <PageHeader
        pageTitle="New Comparison"
        skipTarget="#tabs-content"
        icon="profile"
      />
      <PaymentViaUrl {...state} />
      {/* Since we are calling <FullScreenHeader/> directly, we need to pass
          cancelHandler and confirmHandler instead of button onClick callbacks
        */}
      <FullScreenHeader
        confirmText="Create"
        confirmHandler={handleClickToCompare}
        confirmOptions={{ isDisabled: hasNoCompareTargets() }}
        cancelText="Cancel"
        cancelHandler={handleCancel}
        cancelOptions={{ className: 'mr-0.5' }}
        compareTarget={compareTarget}
        selectedComparables={selectedComparables}
        filteredComparables={compareTargets(['org', 'segment', 'team', 'person', 'culture']).slice(0, 5)}
        handleCheckboxChange={handleCheckboxChange}
        onRemove={handleCheckboxChange}
        filterValue={filterValue}
        onFilter={setFilterValue}
        onClearAll={clearAll}
        takeoverTitle="New Comparison"
      />

      <FullScreenBody>
        <div className="grid justify-start flex-grow grid-cols-1 grid-rows-3 gap-4 pb-8 m-auto md:pb-12 lg:pb-16 max-w-7xl auto-rows-max bg-ivory-400">
          {(filteredItems.some(ct => ct.type === 'segment')) ? (
            <>
              <ProfileGroup
                groupName="Organizations and Segments (A-Z)"
                cards={assembleCardData(compareTargets(['org', 'segment']))}
                onChange={handleCheckboxChange}
                selectedComparables={selectedComparables}
                hasPremium={hasPremium}
              />
              <ProfileGroup
                groupName="Teams (A-Z)"
                cards={assembleCardData(compareTargets(['team']))}
                onChange={handleCheckboxChange}
                selectedComparables={selectedComparables}
                hasPremium={hasPremium}
                createTeamLink={createTeamLink}
              />
            </>
          ) : (
            <ProfileGroup
              groupName="Teams and Organizations (A-Z)"
              cards={assembleCardData(compareTargets(['team', 'org']))}
              onChange={handleCheckboxChange}
              selectedComparables={selectedComparables}
              hasPremium={hasPremium}
              createTeamLink={createTeamLink}
            />
          )}
          <ProfileGroup
            groupName="Individuals (A-Z)"
            cards={assembleCardData(compareTargets(['person']))}
            onChange={handleCheckboxChange}
            selectedComparables={selectedComparables}
            hasPremium={hasPremium}
            sendInvitationsLink={sendInvitationsLink}
          />
          <ProfileGroup
            groupName="Cultures (A-Z)"
            cards={assembleCardData(compareTargets(['culture']))}
            onChange={handleCheckboxChange}
            selectedComparables={selectedComparables}
            hasPremium={hasPremium}
            {...state}
          />
        </div>
        <div className="w-full px-6 py-8 md:py-12 lg:px-20 lg:py-16 bg-slate-50">
          <NewComparisonCTA
            sendInvitationsLink={sendInvitationsLink}
            hasPremium={hasPremium}
            createTeamLink={createTeamLink}
            router={router}
          />
        </div>
      </FullScreenBody>
    </>
  );
};

NewComparison.needsCompletedSurvey = (router, currentUser) =>
  NeedsCompletedSurvey.apply(router, currentUser);

NewComparison.getAPIDataKey = () => 'newcomparison';

NewComparison.getData = apiService => apiService.get('profile/search?connections=true&teams=true&organizations=true&cultures=true&segments=true')
  .then(data => {
    const { currentUser: { blockedContent } } = apiService;
    const hasBlockedCultures = blockedContent.cultures.length;
    const hasCultures = data.cultures;
    const filteredCultures = (hasCultures && hasBlockedCultures)
      ? data.cultures.filter(culture => !blockedContent.cultures.some(c => c === culture.id))
      : data.cultures;

    return ({
      newcomparison: {
        compareTargets: [
          ...(data.teams || []),
          ...(data.organizations || []),
          ...(data.people || []),
          ...(filteredCultures || []),
          ...(data.segments || []),
        ],
      },
    });
  });

NewComparison.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    compareTargets: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default withServerSideData(NewComparison);
