import fetch from 'isomorphic-fetch';
import { get } from 'lodash';

import { AuthError, BadRequestError } from '../errors';

import { COOKIE_TOAST } from '../constants';

/* eslint-disable no-underscore-dangle */

class APIService {
  constructor(currentUser, cookies) {
    this._currentUser = currentUser;
    this.cookies = cookies;
  }

  /**
   *  Create the URL for a specific API endpoint
   *
   *  @param {String} Endpoint to call. ex: `profile`
   *  @return {String} The resolved endpoint URL
   */
  static endpoint(endpoint) {
    const { API_HOST } = process.env;
    return `${API_HOST}/${endpoint}`;
  }

  /**
   *  Create the headers to send to the API with the JWT
   *  @param {String} authToken JWT
   *  @return {Object} Headers to send with fetch
   */
  static buildHeaders(authToken) {
    const headers = { 'Content-Type': 'application/json' };
    if (this.language) {
      headers['accept-language'] = this.language;
    }

    if (authToken && authToken.length > 0) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    return headers;
  }

  /**
   *  Check for erors in response from server
   *  @param {Object} response response object from API
   *  @throws 'Bad response from server' for all error
   *  @return {Object} Response object from API
   */
  static checkForError(data) {
    if (data.status === 400) {
      throw new BadRequestError(`${data.status} - Bad Request`, data);
    }

    if (data.status === 401) {
      throw new AuthError(`${data.status} - Unauthorized`, data);
    }

    if (data.status > 401) {
      throw data;
    }

    return data;
  }

  /**
   *  Send the complete request to the provided endpoint.
   *
   *  For local use.  Use get/post/patch functions instead of calling directly.
   *
   *  @param {String} endpoint The API endpoint to hit (e.g. "users", "session/legacy")
   *  @param {String} req The request including method, headers, and body
   *  @return {Object} Resulting JSON object from API
   */
  static callApi(
    endpoint,
    req,
    currentUser,
    cookies,
  ) {
    req.credentials = 'omit';

    return fetch(APIService.endpoint(endpoint), req)
      .then(response => {
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.indexOf('json') >= 0) {
          return response
            .json()
            .then(data => ({ ...data, status: response.status }));
        }
        return response;
      })
      .then(APIService.checkForError)
      .then(data => {
        APIService.updateCurrentUser(currentUser, data, cookies);
        return data;
      });
  }

  static updateCurrentUser(currentUser, data, cookies) {
    const profile = get(data, 'meta.auth.profile', null);
    const skills = get(data, 'meta.skills', null);

    if (currentUser.allowSkillsTracking && skills?.activitiesCompleted) {
      cookies.set(COOKIE_TOAST, { skills });
    }
    if (profile) {
      currentUser.setCurrentUser(profile);
    }
  }

  get currentUser() {
    return this._currentUser;
  }

  set currentUser(currentUser) {
    this._currentUser = currentUser;
  }

  getAuthToken() {
    let token = null;
    if (this._currentUser && this._currentUser.token) {
      token = this._currentUser.token;
    }

    return token;
  }

  /**
   *  Get data from Sails API.
   *
   *  @param {String} endpoint The API endpoint to hit (e.g. "users", "session/legacy")
   *  @return {Object} Resulting JSON object from API
   */
  get(endpoint) {
    const headers = APIService.buildHeaders(this.getAuthToken());

    return APIService.callApi(
      endpoint,
      { headers },
      this._currentUser,
      this.cookies,
    );
  }

  /**
   *  Update data in the Sails API. This function once used the HTTP `PATCH` verb
   *  to accomplish this however some clients experienced their requests being
   *  blocked. We have opted to retain `patch` in the app to describe these operations
   *  to reduce the impact on the app and to allow for the possibility that these
   *  operations may be distinct from each other in the future. For now, this proxies
   *  to `APIService.post`.
   *
   *  @param {String} endpoint The API endpoint to hit (e.g. "users", "session/legacy")
   *  @param {Object} body The request body to send to the API
   *  @return {Object} Resulting JSON object from API
   */
  patch(endpoint, body) {
    return this.post(endpoint, body);
  }

  /**
   *  Send PUT data to Sails API.
   *
   *  @param {String} endpoint The API endpoint to hit (e.g. "users", "session/legacy")
   *  @param {Object} body The request body to send to the API
   *  @return {Object} Resulting JSON object from API
   */
  put(endpoint, body) {
    const headers = APIService.buildHeaders(this.getAuthToken());
    const method = 'PUT';

    return APIService.callApi(
      endpoint,
      { headers, method, body: JSON.stringify(body) },
      this._currentUser,
      this.cookies,
    );
  }

  /**
   * A wrapper around fetch for sending data to the Sails API.
   *
   * @param {String} endpoint - The API endpoint we are POSTing to
   * @param {Object} body - An object containing the data for the request body.
   * @return {Promise} - A promise that will resolve with a JSON object. Includes the JWT.
   */
  post(endpoint, body) {
    const headers = APIService.buildHeaders(this.getAuthToken());
    const method = 'POST';
    return APIService.callApi(
      endpoint,
      { headers, method, body: JSON.stringify(body) },
      this._currentUser,
      this.cookies,
    );
  }

  /**
   * A wrapper around fetch for sending data to the Sails API.
   *
   * @param {String} endpoint - The API endpoint we are POSTing to
   * @param {Object} body - An object containing file in formData format.
   * @return {Promise} - A promise that will resolve with a JSON object. Includes the JWT.
   */
  upload(endpoint, body) {
    const headers = new Headers(APIService.buildHeaders(this.getAuthToken()));
    const method = 'POST';
    headers.delete('Content-Type');
    return APIService.callApi(
      endpoint,
      { headers, method, body },
      this._currentUser,
      this.cookies,
    );
  }

  /**
   * A wrapper around fetch for sending data to the Sails API.
   *
   * @param {String} endpoint - The API endpoint we are DELETEing from
   * @param {Object} body - An object containing the data for the request body.
   * @return {Promise} - A promise that will resolve with a JSON object. Includes the JWT.
   */
  delete(endpoint, body) {
    const headers = APIService.buildHeaders(this.getAuthToken());
    const method = 'DELETE';

    return APIService.callApi(
      endpoint,
      { headers, method, body: JSON.stringify(body) },
      this._currentUser,
      this.cookies,
    );
  }
}

export default APIService;
