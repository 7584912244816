import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../common/Table';
import Button from '../../../common/Button';
import EditableCell from '../../../common/Table/EditableCell';
import DropdownSelect from '../../../common/Dropdowns/DropdownSelect';
import AutoSuggest from '../../../AutoSuggest';
import Badges from '../../../common/Badges';

import { incrementsObject } from '../../../../lib/increments';
import ToggleSwitch from '../../../common/ToggleSwitch';

const AperianLiveTopicsTable = ({
  sessionTopicsData,
  handleChange,
  handleEditSave,
  preworkOptions,
  tagOptions,
  handleSuggestChange,
  tagsRef,
  preworkRef,
  handleRemoveTag,
  handleRemovePrework,
}) => {
  const renderCellNameDescription = (value, id, field) => {
    if (!value && field === 'name') {
      return (
        <EditableCell
          id={`${id}-name`}
          name={`${id}-name`}
          text={value}
          onTextChange={handleChange}
          inputType="input"
        />
      );
    }
    if (!value && field === 'description') {
      return (
        <EditableCell
          id={`${id}-description`}
          name={`${id}-description`}
          text={value}
          onTextChange={handleChange}
          inputType="textarea"
        />
      );
    }
    return (value);
  };

  return (
    <Table
      className="min-w-full divide-y divide-gray-300 table-fixed"
      columns={[
        {
          title: 'Course Name and Description',
          cellStyle: 'whitespace-wrap lg:whitespace-nowrap w-96 flex px-2 py-4 md:pl-4 md:pr-3 text-sm text-gray-900 relative',
          field: 'name',
          render: rowData => (
            <div className="flex flex-col flex-grow gap-4">
              <div className={`flex-grow text-wrap w-full font-medium ${!rowData.name ? 'before:content-["+"] before:absolute before:-left-2 before:md:left-0 before:bottom-[1.125rem] before:text-2xl' : ''}`}>
                {rowData.isEditing ? (
                  <EditableCell
                    id={`${rowData.id}-name`}
                    name={`${rowData.id}-name`}
                    text={rowData.name}
                    onTextChange={handleChange}
                    inputType="input"
                  />
                ) : renderCellNameDescription(rowData.name, rowData.id, 'name')}
              </div>
              <div className="w-full text-sm text-gray-500 line-clamp-2 text-wrap">
                {rowData.isEditing ? (
                  <EditableCell
                    id={`${rowData.id}-description`}
                    name={`${rowData.id}-description`}
                    text={rowData.description}
                    onTextChange={handleChange}
                    inputType="textarea"
                  />
                ) : renderCellNameDescription(rowData.description, rowData.id, 'description')}
              </div>
            </div>
          ),
        },
        {
          title: 'Course Duration',
          cellStyle: 'px-3 py-4',
          field: 'duration',
          render: rowData => (
            <div className="w-max">
              {rowData.isEditing ? (
                <DropdownSelect
                  id="duration"
                  name={`${rowData.id}-duration`}
                  labelType="text"
                  options={incrementsObject(0, 120, 5)}
                  value={rowData.duration}
                  onChangeValue={e => { handleChange(`${rowData.id}-duration`, e.target.value); }}
                />
              ) : (
                <p className="text-sm font-normal text-gray-500">{rowData.duration} minutes</p>
              )}
            </div>
          ),
        },
        {
          title: 'Course Type',
          cellStyle: 'px-3 py-4',
          field: 'isPremium',
          render: rowData => (
            <div className="w-max">
              {rowData.isEditing ? (
                <div className="flex flex-col items-center">
                  <ToggleSwitch
                    id={`${rowData.id}-isPremium`}
                    name={`${rowData.id}-isPremium`}
                    checked={rowData.isPremium}
                    onChange={e => { handleChange(`${rowData.id}-isPremium`, e.target.checked); }}
                  />
                  <p className="mt-1 text-sm font-medium text-gray-500">
                    {rowData.isPremium ? 'Premium' : 'Standard'}
                  </p>
                </div>
              ) : (
                <p className="text-sm font-normal text-gray-500">{rowData.isPremium ? 'Premium' : 'Standard'}</p>
              )}
            </div>
          ),
        },
        {
          title: 'Course Prework',
          cellStyle: 'px-3 py-4',
          field: 'prework',
          render: rowData => (
            <div className="w-full">
              {rowData.isEditing ? (
                <AutoSuggest
                  key={`${rowData.id}-prework`}
                  ref={preworkRef}
                  name="prework"
                  placeholder="Select Prework"
                  options={preworkOptions}
                  selectedOptions={rowData.prework || []}
                  onSelectOption={e => {
                    handleSuggestChange(e, rowData.id);
                  }}
                  onRemoveSelectedOption={e => {
                    handleRemovePrework(e, rowData.id);
                  }}
                  isMultiSelect
                />
              ) : (
                <Badges
                  badges={rowData.prework?.map(prework => prework.name) || []}
                  size="small"
                  color="blue"
                />
              )}
            </div>
          ),
        },
        {
          title: 'Course Tags',
          cellStyle: 'px-3 py-4',
          field: 'tags',
          render: rowData => (
            <div className="w-full">
              {rowData.isEditing ? (
                <AutoSuggest
                  key={`${rowData.id}-tags`}
                  ref={tagsRef}
                  name="tags"
                  placeholder="Select Tags"
                  options={tagOptions}
                  selectedOptions={rowData.tags || []}
                  onSelectOption={e => {
                    handleSuggestChange(e, rowData.id);
                  }}
                  onRemoveSelectedOption={e => {
                    handleRemoveTag(e, rowData.id);
                  }}
                  isMultiSelect
                />
              ) : (
                <Badges
                  badges={rowData.tags?.map(tag => tag.name) || []}
                  size="small"
                  color="green"
                />
              )}
            </div>
          ),
        },
        {
          title: 'Button',
          headerStyle: 'hidden',
          cellStyle: 'px-3 py-4 align-top text-sm text-gray-500',
          render: rowData => (
            <div className="flex justify-center">
              {rowData.id ? (
                <Button
                  isExtraSmall
                  variant="secondary"
                  onClick={() => handleEditSave(rowData.id)}
                >
                  {rowData.isEditing ? 'Save' : 'Edit'}
                </Button>
              ) : (
                <Button
                  isExtraSmall
                  variant="secondary"
                  onClick={() => handleEditSave(rowData.id)}
                >
                  Create
                </Button>
              )}
            </div>
          ),
        },
      ]}
      data={sessionTopicsData}
      options={{
        headersStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        bodyStyle: 'divide-y',
      }}
    />
  );
};

AperianLiveTopicsTable.propTypes = {
  sessionTopicsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
      isEditing: PropTypes.bool,
      isPremium: PropTypes.bool,
    })).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleEditSave: PropTypes.func.isRequired,
  preworkOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })).isRequired,
  tagOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
    })).isRequired,
  handleSuggestChange: PropTypes.func,
  handleRemoveTag: PropTypes.func,
  handleRemovePrework: PropTypes.func,
  tagsRef: PropTypes.shape({}),
  preworkRef: PropTypes.shape({}),
};

AperianLiveTopicsTable.defaultProps = {
  handleSuggestChange: () => { },
  handleRemoveTag: () => { },
  handleRemovePrework: () => { },
  tagsRef: undefined,
  preworkRef: undefined,
};

export default AperianLiveTopicsTable;
