import React from 'react';
import PropTypes from 'prop-types';

import Table from '../../../../common/Table';
import Checkbox from '../../../../common/Checkbox';
import Button from '../../../../common/Button';
import EditableCell from '../../../../common/Table/EditableCell';

const OrganizationSegmentsList = ({
  segments,
  handleChangeEnabled,
  handleChangeDisplayName,
  handleEditSave,
  onDelete,
}) => (
  <Table
    className="min-w-full divide-y divide-gray-300"
    emptyMessage="This organization does not have any segments at this time."
    columns={[
      {
        title: 'SAML Property Name',
        cellStyle: 'whitespace-normal lg:whitespace-nowrap pr-2 pl-3 py-4 md:pl-4 md:pr-3 text-sm font-medium text-gray-900 cursor-pointer',
        field: 'property name',
        render: rowData => rowData.samlPropertyName,
      },
      {
        title: 'Display Name',
        cellStyle: 'whitespace-normal lg:whitespace-nowrap pr-2 pl-3 py-4 md:pl-4 md:pr-3 text-sm font-medium text-gray-900 cursor-pointer',
        field: 'display name',
        render: rowData => (
          rowData.isEditing ? (
            <EditableCell
              id={`${rowData.id}-displayName`}
              name={`${rowData.id}-displayName`}
              text={rowData.displayName}
              onTextChange={handleChangeDisplayName}
              inputType="input"
            />
          ) : rowData.displayName
        ),
      },
      {
        title: 'Comparisons Allowed',
        cellStyle: 'table-cell h-full whitespace-nowrap pl-2 pr-4 md:px-3 py-4 text-sm text-gray-500',
        render: rowData => (
          <div className="flex justify-center">
            <Checkbox
              id={`enabled-${rowData.id}`}
              name="enabled"
              value="enabled"
              checked={rowData.enabled}
              onChange={e => handleChangeEnabled(e, rowData.id)}
              isDisabled={!rowData.isEditing}
            />
          </div>
        ),
      },
      {
        title: 'Edit',
        headerStyle: 'hidden',
        cellStyle: 'px-3 py-4 align-top text-sm text-gray-500',
        render: rowData => (
          <div className="flex justify-center">
            <Button
              className="relative float-right w-8 h-8 bg-ivory-400 hover:bg-slate-25 focus:bg-slate-25"
              outlinedColor="white"
              onClick={() => handleEditSave(rowData.id)}
              leadingButtonIcon={rowData.isEditing ? 'save' : 'edit'}
              iconColor="primary"
              isCircle
            />
          </div>
        ),
      },
      {
        title: 'Delete',
        headerStyle: 'hidden',
        cellStyle: 'table-cell h-full whitespace-nowrap pl-2 pr-4 md:px-3 py-4 text-sm text-gray-500',
        render: rowData => (
          <Button
            className="relative float-right w-8 h-8 bg-ivory-400 hover:bg-slate-25 focus:bg-slate-25"
            outlinedColor="white"
            onClick={() => onDelete(rowData)}
            leadingButtonIcon="trash"
            iconColor="primary"
            isCircle
          />
        ),
      },
    ]}
    data={segments}
    options={{
      headersStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
      bodyStyle: 'divide-y',
    }}
  />
);

OrganizationSegmentsList.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      samlPropertyName: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      enabled: PropTypes.bool,
    })).isRequired,
  handleChangeEnabled: PropTypes.func.isRequired,
  handleChangeDisplayName: PropTypes.func.isRequired,
  handleEditSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default OrganizationSegmentsList;
