import React, { useContext } from 'react';
import Button from '../../../common/Button';
import { trackClickCTA } from '../../../../lib/tracker/common';
import { APIContext } from '../../../../context/API';

const divCTAInfo = [
  {
    id: 1,
    headerText: 'Collaboration Catalysts',
    bodyText: 'Exercises and activities designed to strengthen team dynamics, communication, and trust.',
    imageUrl: `${process.env.AWS_APG_RESOURCES_HOST}images/Resource_CollaborationCatalysts`,
    location: `${process.env.AWS_APG_RESOURCES_HOST}mod/help/Collaboration%20Catalysts%20-%20Exercises%20for%20Building%20Stronger%20Teams.pdf`,
  },
  {
    id: 2,
    headerText: 'Team Breakthrough Cards',
    bodyText: 'Quick advice to overcome five of the most common challenges global teams face.',
    imageUrl: `${process.env.AWS_APG_RESOURCES_HOST}images/Resource_TeamCollaborationCards`,
    location: `${process.env.AWS_APG_RESOURCES_HOST}mod/help/Team%20Breakthrough%20Cards%20-%20on%20website.pdf`,
  },
];

const ProfileCompareCTA = () => {
  const { apiService } = useContext(APIContext);
  return (
    <div className="px-4 py-8 md:py-16 lg:px-8 lg:py-20 bg-slate-50">
      <h1 className="mb-8 font-serif text-4xl text-center lg:mb-12">Related Resources</h1>
      <div className="flex flex-col justify-center gap-6 md:flex-row lg:flex-row">
        {divCTAInfo.map(({ id, headerText, bodyText, imageUrl, location }) => (
          <div key={id} className="flex flex-col-reverse justify-between w-full overflow-auto bg-white rounded-lg shadow-lg xl:flex-row md:w-1/2">
            <div className="flex flex-col justify-between flex-1 p-6">
              <h1 className="mb-2 font-serif text-2xl xl:text-3xl">{headerText}</h1>
              <p className="mb-4 font-sans text-sm text-gray-700 md:text-base 2xl:text-lg">{bodyText}</p>
              <Button
                variant="primary"
                trailingButtonIcon="arrow-right"
                isExtraSmall
                className="w-1/2 mt-4 2xl:text-xl"
                onClick={async () => {
                  await trackClickCTA(
                    apiService,
                    'GSP Comparison',
                    'View',
                    location,
                    window.location.pathname,
                    headerText,
                  );
                  await window.open(location);
                }}
              >
                View
              </Button>
            </div>

            <div className="flex-1">
              <img
                src={imageUrl}
                alt={headerText}
                className="object-cover w-full h-full"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProfileCompareCTA;
