import React, {
  useContext,
  useEffect,
  useState,
  useReducer,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';

import { CurrentUserContext } from '../../context/CurrentUser';
import { RouterContext } from '../../context/Router';
import { APIContext } from '../../context/API';
import { ModalContext } from '../../context/Modal';

import AperianWordmark from '../AperianWordmark';
import Notification from '../Notifications/Notification';
import PaymentHeader from './PaymentHeader';
import PaymentPurchaseDetails from './PaymentPurchaseDetails';
import PaymentBillingForm from './PaymentBillingForm';
import PaymentShippingForm from './PaymentShippingForm';
import PaymentPurchaseForm from './PaymentPurchaseForm';
import PaymentSuccessMessage from './PaymentSuccessMessage';

import logger from '../../lib/logger';
import PaymentAction from '../../actions/payment';
import TotalTaxAmountAction from '../../actions/totalTaxAmount';
import GetCouponAction from '../../actions/coupons';
import { trackPaymentModalOpen } from '../../lib/tracker/payment-modal-open';
import { trackPaymentFormSubmit, trackPaymentFormFailure } from '../../lib/tracker/payment-form-submit';

const discountAmount = (amount, coupon = {}) => {
  let discounted = amount;
  if (coupon.amount_off) {
    discounted = amount - (coupon.amount_off / 100);
  } else if (coupon.percent_off) {
    discounted = amount - (amount * (coupon.percent_off / 100));
  }

  return Math.max(discounted, 0);
};

const PaymentForm = ({
  planInfo,
  coupon:
  couponId,
  isForOthers,
}) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);
  const { apiService } = useContext(APIContext);
  const { handleCloseModal } = useContext(ModalContext);

  const stripe = useStripe();

  const { plan: { name: planName, id: planId, price: planPrice } } = planInfo;
  const numericPrice = parseInt(planPrice, 10);

  const [quantity, setQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(numericPrice);
  const [tax, setTax] = useState(null);
  const [total, setTotal] = useState(numericPrice);
  const [coupon, setCoupon] = useState({ id: couponId });
  const [stripeToken, setStripeToken] = useState(null);
  const [formData, setFormData] = useReducer((state, newState) =>
    ({ ...state, ...newState }),
  {
    name: '',
    org: '',
    billing_address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    },
    shipping_address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    },
  });

  const [notification, setNotification] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [forOthers, setForOthers] = useState(isForOthers);
  const [isSameShippingAddress, setIsSameShippingAddress] = useState(true);
  const [acceptPurchaseAgreement, setAcceptPurchaseAgreement] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [accessCode, setAccessCode] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [billingAddressError, setBillingAddressError] = useState(null);
  const [shippingAddressError, setShippingAddressError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);

  const notificationRef = useRef();

  useEffect(() => {
    trackPaymentModalOpen({ planName, planId });
  }, []);

  useEffect(() => {
    if (stripeToken?.id) {
      setNotification({});
      setIsProcessing(true);
      setCurrentStep(4);
    }
  }, [stripeToken]);

  useEffect(() => {
    let redirectTimeout;
    if (paymentComplete && !isProcessing) {
      const { location: { query }, push } = router;
      if (query.redirect_to) {
        redirectTimeout = setTimeout(() => {
          handleCloseModal();
          push(query.redirect_to);
        }, 5000);
      }
    }
    return () => clearTimeout(redirectTimeout);
  }, [paymentComplete, isProcessing]);

  const handleNotification = ({ type, message }) => setNotification({ type, message });

  const focusNotification = () => {
    notificationRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleForOthersChange = ({ target: { checked } }) => {
    // If unchecked, set quantity to 1
    if (!checked) {
      const singlePlanPrice = planPrice * 1;
      setQuantity(1);
      setSubTotal(singlePlanPrice);
      setTotal(discountAmount(singlePlanPrice, coupon));
    }
    setForOthers(checked);
  };

  const couponSuccess = newCoupon => {
    setCoupon(newCoupon);
    setTotal(discountAmount(subTotal, newCoupon));
    setNotification({});
    focusNotification();
  };

  const couponFail = () => {
    setCoupon({});
    setNotification({
      type: 'warning',
      message: 'This coupon code is invalid or has expired. Contact <a href="mailto:support@aperian.com">support@aperian.com</a> if you think this is in error.',
    });
    focusNotification();
  };

  const applyCoupon = async newCoupon => {
    try {
      const result = await new GetCouponAction(apiService).execute(newCoupon);
      if (result.coupon.valid) {
        return couponSuccess(result.coupon);
      }
      return couponFail();
    } catch (e) {
      return couponFail();
    }
  };

  const handleQuantityChange = e => {
    const { value: quantityValue } = e.target;
    const quantityNumber = parseInt(quantityValue, 10);
    let newQuantity = quantityNumber;
    if (isNaN(quantityNumber)) {
      newQuantity = 1;
    }
    const newSubTotal = planPrice * newQuantity;
    setQuantity(quantityValue);
    setSubTotal(newSubTotal);
    setTotal(discountAmount(newSubTotal, coupon));
  };

  const handleFormChange = e => {
    if (e.elementType === 'address') {
      let address = { ...e.value.address };
      if (address.country === 'HK') {
        address = { ...address, postal_code: '000' };
      }
      if (e.elementMode === 'billing') {
        setFormData({
          billing_address: address,
          name: e.value.name,
        });
        if (isSameShippingAddress) {
          setFormData({
            shipping_address: address,
          });
        }
        return;
      }
      if (e.elementMode === 'shipping') {
        setFormData({
          shipping_address: address,
        });
        return;
      }
    }
    const { name, value } = e.target;
    setFormData({ [name]: value });
  };

  const handleShippingAddress = ({ target: { checked } }) => {
    if (checked === false && stripe) {
      setFormData({
        shipping_address: {},
      });
    }
    setIsSameShippingAddress(checked);
  };

  const getTotalTaxAmount = async () => {
    try {
      const response = await new TotalTaxAmountAction(apiService)
        .execute({
          formData,
          lines: [{
            number: 1,
            quantity,
            amount: total,
            description: planInfo.plan.name,
            taxCode: planInfo.plan.taxCode,
            itemCode: planInfo.plan.id,
          }],
          discount: 0,
          amount: total,
          description: planInfo.plan.name,
          currencyCode: 'USD',
          isSameShippingAddress,
        });
      return setTax(response.totalTaxCalculated);
    } catch (e) {
      return logger.error('Unable to calculate total amount try again', e);
    }
  };

  const handleAcceptPurchaseAgreement = ({ target: { checked } }) =>
    setAcceptPurchaseAgreement(checked);

  const handleSetStripeToken = token => setStripeToken(token);

  const handleChargeErrors = error => {
    // TODO: Use result to handle charge errors, including validation errors
    // See https://stripe.com/docs/api/errors
    if (error.type !== 'validation_error') {
      logger.error('Stripe elements createToken error', error);
    }

    setPaymentError(`${error.message}`);

    setIsProcessing(false);
    focusNotification();
  };

  const processPayment = async () => {
    try {
      const response = await new PaymentAction(apiService)
        .execute({
          planName,
          planId,
          quantity,
          forOthers,
          token: stripeToken,
          coupon: coupon.id,
          metadata: {
            organization: formData.org,
            'Purchasing for Others': forOthers,
            'Plan ID': planId,
            'Unit price': planPrice,
            Quantity: quantity,
            ItemCode: planId,
            TaxCode: planInfo.plan.taxCode,
          },
          shipping: {
            address: {
              line1: formData.shipping_address.line1,
              city: formData.shipping_address.city,
              country: formData.shipping_address.country,
              postal_code: formData.shipping_address.postal_code,
              state: formData.shipping_address.state,
            },
            name: formData.name,
          },
        });

      if (forOthers) {
        const { charge } = response;
        setAccessCode(charge.accessCode);
      }

      await trackPaymentFormSubmit({
        userid: currentUser?.userid,
        planName,
        planId,
        amount: total,
        quantity,
        forOthers,
        couponCode: coupon.id,
      });
      setFormData({ country: '' });
      setPaymentComplete(true);
      setIsProcessing(false);
    } catch (response) {
      const { error } = response.reason;
      // Generic error message
      let message = 'Something went wrong while processing your purchase. Please try again later or contact <a href="mailto:support@aperian.com">support@aperian.com</a> for assistance.';
      const serverMessage = error.message;
      // More specific error message if card error
      if (error.type === 'card_error') {
        message = `There was a problem processing your payment: <strong>${serverMessage}</strong>`;
      } else {
        // Log error details if error other than card error
        logger.error('Payment processing failed', response);
      }
      setNotification({
        type: 'warning',
        message,
      });
      setIsProcessing(false);
      focusNotification();
      try {
        await trackPaymentFormFailure({
          planName,
          planId,
          amount: total,
          quantity,
          forOthers,
          couponCode: coupon.id,
        }, serverMessage);
      } catch (e) {
        logger.error(e);
      }
    }
  };

  const validateForm = () => {
    if (forOthers && !quantity) {
      setNotification({
        type: 'warning',
        message: 'Please choose a number of licenses to purchase',
      });
      setIsProcessing(false);
      focusNotification();
      return false;
    }
    return true;
  };

  const submitForm = async () => {
    if (!validateForm()) return;
    if (!stripe) {
      console.log('Stripe.js has not loaded yet.');
      return;
    }
    processPayment();
  };

  const handlePrevious = () => {
    let previousStep = currentStep - 1;
    if (currentStep === 3 && isSameShippingAddress) previousStep = 1;
    if (currentStep <= 1) previousStep = 1;

    if (previousStep === 1) {
      setTax(null);
    }
    setCurrentStep(previousStep);
  };

  useEffect(() => {
    if (formData.name && formData.billing_address.postal_code) {
      setBillingAddressError(null);
    }
    if (!formData.isSameShippingAddress && formData.shipping_address.postal_code) {
      setShippingAddressError(null);
    }
  }, [
    formData.name,
    formData.billing_address,
    formData.isSameShippingAddress,
    formData.shipping_address.postal_code,
  ]);

  const handleNext = async () => {
    if (currentStep === 1) {
      if (!formData.name || !formData.billing_address.postal_code) {
        setBillingAddressError('Please fill out the form below.');
        return;
      }
      if (isSameShippingAddress) {
        await getTotalTaxAmount();
        setCurrentStep(3);
        return;
      }
    }

    if (currentStep === 2) {
      if (!formData.isSameShippingAddress && !formData.shipping_address.postal_code) {
        setShippingAddressError('Please fill out the form below.');
        return;
      }
      await getTotalTaxAmount();
    }
    const nextStep = currentStep < 4 ? currentStep + 1 : currentStep;
    setCurrentStep(nextStep);
  };

  return (
    <>
      <div className="globe-smart-logo">
        <AperianWordmark />
      </div>
      <div ref={notificationRef}>
        <Notification {...notification} />
      </div>
      {planInfo && <PaymentHeader planInfo={planInfo.plan} />}
      <PaymentPurchaseDetails
        planInfo={planInfo}
        quantity={quantity}
        subtotal={subTotal}
        coupon={coupon}
        tax={tax}
        total={total}
        applyCoupon={applyCoupon}
        forOthers={forOthers}
        handleForOthersChange={handleForOthersChange}
        handleQuantityChange={handleQuantityChange}
        currentStep={currentStep}
      />
      <form className="mt-8 payment">
        <PaymentBillingForm
          currentStep={currentStep}
          handleNext={handleNext}
          handleFormChange={handleFormChange}
          handleShippingAddress={handleShippingAddress}
          isSameShippingAddress={isSameShippingAddress}
          handleNotification={handleNotification}
          billingAddressError={billingAddressError}
          stripe={stripe}
        />
        <PaymentShippingForm
          currentStep={currentStep}
          handleNext={handleNext}
          handleFormChange={handleFormChange}
          handlePrevious={handlePrevious}
          isSameShippingAddress={isSameShippingAddress}
          handleShippingAddress={handleShippingAddress}
          handleNotification={handleNotification}
          shippingAddressError={shippingAddressError}
          formData={formData}
          stripe={stripe}
        />
        <PaymentPurchaseForm
          currentStep={currentStep}
          handlePrevious={handlePrevious}
          formData={formData}
          handleFormChange={handleFormChange}
          acceptPurchaseAgreement={acceptPurchaseAgreement}
          handleAcceptPurchaseAgreement={handleAcceptPurchaseAgreement}
          handleSetStripeToken={handleSetStripeToken}
          handleChargeErrors={handleChargeErrors}
          paymentError={paymentError}
        />
        <PaymentSuccessMessage
          currentStep={currentStep}
          planName={planName}
          forOthers={forOthers}
          accessCode={accessCode}
          submitForm={submitForm}
          isProcessing={isProcessing}
        />
      </form>
    </>
  );
};

PaymentForm.propTypes = {
  planInfo: PropTypes.shape({
    plan: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      price: PropTypes.string,
      duration: PropTypes.string,
      taxCode: PropTypes.string,
    }),
  }),
  coupon: PropTypes.string,
  isForOthers: PropTypes.bool,
};

PaymentForm.defaultProps = {
  planInfo: null,
  coupon: null,
  isForOthers: false,
};

export default PaymentForm;
