import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import { ModalContext } from '../../../context/Modal';
import { RouterContext } from '../../../context/Router';

import Button from '../../common/Button';
import AperianWordmark from '../../AperianWordmark';
import Icon from '../../common/Icon';

const renderJoinedMessage = (team, group) => {
  const joined = ' and joined the connected';
  if (team && group) {
    return `${joined} Team and Group`;
  }
  if (team) {
    return `${joined} Team`;
  }
  if (group) {
    return `${joined} Group`;
  }
  return '';
};

const CohortEnrollmentSuccessMessage = ({
  displayName,
  redirectUrl,
  isAlreadyEnrolled,
  meetingDateTime,
  cohortTeamId,
  cohortGroupId,
}) => {
  const { handleCloseDialog } = useContext(ModalContext);
  const { router } = useContext(RouterContext);

  useEffect(() => {
    let timeOut = 5000;
    if (cohortGroupId || cohortTeamId) {
      timeOut = 7000;
    }
    const displayTimer = setTimeout(() => {
      handleCloseDialog();
    }, timeOut);
    return () => clearTimeout(displayTimer);
  }, []);

  const handleRedirectClick = redirect => {
    handleCloseDialog();
    if (redirect === 'group') {
      router.push('/assessments/ibi/');
    }
    if (redirect === 'team') {
      router.push(`/profile/teams/${cohortTeamId}`);
    }
  };

  const handleAcceptButtonClick = () => {
    handleCloseDialog();
  };

  const alreadyEnrolledMessage = (
    <span>
      <b>You have already enrolled in {displayName}.</b><br />
      You can access GlobeSmart anytime at <a href={process.env.SITE_URL}>{process.env.SITE_URL}</a>
    </span>
  );

  const enrollMessage = (
    <span className="text-lg">
      You have successfully enrolled in <br />{displayName}
      {renderJoinedMessage(cohortTeamId, cohortGroupId)}
      {meetingDateTime && <> <br />on {format(new Date(meetingDateTime), 'dd MMM yyyy')} at {format(new Date(meetingDateTime), 'hh:mm a')}</>}.<br />
      {redirectUrl && <p className="mt-2">You will be redirected to your first step.</p>}
    </span>
  );

  return (
    <>
      <div className="flex justify-center">
        <AperianWordmark />
      </div>
      <hr className="my-4" />
      <div className="flex flex-col gap-4 text-center">
        <div className="flex items-center justify-center w-16 h-16 m-auto border rounded-full shadow-md">
          <Icon icon="check" iconColor="green" className="w-12 h-12" />
        </div>
        <h2 className="m-0">Success!</h2>
        { isAlreadyEnrolled ? alreadyEnrolledMessage : enrollMessage }
        <div className="flex flex-col gap-2 ml-auto md:flex-row">
          {cohortGroupId ? (
            <Button variant="secondary" onClick={() => handleRedirectClick('group')}>
              Go To Groups
            </Button>
          ) : null}
          {cohortTeamId ? (
            <Button variant="secondary" onClick={() => handleRedirectClick('team')}>
              Go To Team
            </Button>
          ) : null}
          <Button variant="primary" onClick={handleAcceptButtonClick}>
            Okay
          </Button>
        </div>
      </div>
    </>
  );
};

CohortEnrollmentSuccessMessage.propTypes = {
  displayName: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  isAlreadyEnrolled: PropTypes.bool,
  meetingDateTime: PropTypes.string,
  cohortTeamId: PropTypes.number,
  cohortGroupId: PropTypes.number,
};

CohortEnrollmentSuccessMessage.defaultProps = {
  redirectUrl: null,
  isAlreadyEnrolled: false,
  meetingDateTime: null,
  cohortTeamId: null,
  cohortGroupId: null,
};

export default CohortEnrollmentSuccessMessage;
