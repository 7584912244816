import React, { useEffect, useRef } from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import Icon from '../../common/Icon';

import { navItems } from './navItems';

const SidebarNavigationLinks = ({
  accessLevel,
  allowGSPShowing,
  allowLearningModules,
  location,
  premiumUser,
  hasCompletedIBI,
  handleSidebarOpen,
  allowAperianLiveShowing,
}) => {
  const buttonRefs = useRef([]);

  let navigation = navItems(hasCompletedIBI);

  if (!allowGSPShowing) {
    navigation = navigation.filter(item => item.premiumFeature !== true);
  }
  if (!allowLearningModules) {
    navigation = navigation.filter(item => item.name !== 'Learning Modules');
  }
  if (!allowAperianLiveShowing) {
    navigation = navigation.filter(item => item.name !== 'Aperian Live');
  }

  useEffect(() => {
    buttonRefs?.current.forEach(ref => {
      if (ref.open && !location.pathname.includes(ref.path)) {
        ref.close();
      }
    });
  }, [location]);

  return navigation.map((item, i) => (
    item.criteria === 'all' || accessLevel?.some(string => string.includes(item.criteria) || string.includes(item.secondaryCriteria))
      ? (
        <li key={item.name}>
          {(!item.children || (item.premiumFeature && !premiumUser)) ? (
            <Link
              to={item.to}
              onClick={() => handleSidebarOpen && handleSidebarOpen(false)}
              className={twMerge(
                'group flex gap-x-3 rounded-tr-3xl rounded-br-3xl p-2 text-sm font-semibold leading-4 items-center pl-8',
                (location?.pathname === item.to)
                  ? 'bg-charcoal-800 text-white'
                  : 'text-gray-400 hover:bg-charcoal-800 hover:text-white',
              )}
            >
              <Icon icon={item.icon} iconColor="gray" className="w-6 h-6 shrink-0" aria-hidden="true" />
              {item.name}
              {(item.premiumFeature && !premiumUser) ? (
                <span className="ml-auto flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border border-charcoal-700 bg-charcoal-800 text-[0.625rem] font-medium text-gray-400 group-hover:text-white">
                  <Icon icon="lock" iconColor="gray" className="w-4 h-4 shrink-0" aria-hidden="true" />
                </span>
              ) : null}
            </Link>
          ) : (
            <Disclosure defaultOpen={location.pathname.includes(item.to)} as="div">
              {({ open, close }) => (
                item.children ? (
                  <>
                    <Disclosure.Button as="div">
                      <button
                        ref={() => { buttonRefs.current[i] = { open, path: item.to, close }; }}
                        className={twMerge(
                          'text-gray-400 hover:bg-charcoal-800 hover:text-white',
                          'flex items-center w-full text-left rounded-tr-3xl p-2 pl-8 font-semibold rounded-br-3xl gap-x-3 text-sm leading-4',
                        )}
                      >
                        <Icon
                          icon={item.icon}
                          iconColor="gray"
                          className="w-6 h-6 shrink-0"
                          aria-hidden="true"
                        />
                        {item.name}
                        <Icon
                          icon="chevron-right"
                          iconColor="gray"
                          className="w-3 h-3 ml-auto mr-4 text-gray-400 ui-open:rotate-90 ui-open:transform ui-open:transition-transform ui-open:duration-300 ui-not-open:rotate-0 ui-not-open:transform ui-not-open:transition-transform ui-not-open:duration-300"
                          aria-hidden="true"
                        />
                      </button>
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className="px-2 mt-1">
                      {item.children.map(subItem => (
                        <li key={subItem.name}>
                          <Link
                            to={subItem.to}
                            onClick={() => handleSidebarOpen && handleSidebarOpen(false)}
                            className={twMerge(
                              (subItem.exact
                                ? location.pathname === subItem.to
                                : location?.pathname?.includes(subItem.to))
                                ? 'bg-charcoal-800 text-white'
                                : 'text-gray-400 hover:bg-charcoal-800 hover:text-white',
                              'font-semibold block rounded-tr-3xl rounded-br-3xl py-2 pr-2 pl-[4.5rem] leading-4 text-sm text-gray-400 hover:text-white',
                            )}
                          >
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                    </Disclosure.Panel>
                  </>
                ) : (
                  <Link
                    to={item.to}
                    className={twMerge(
                      'group flex gap-x-3 rounded-md p-2 text-sm font-semibold items-center leading-4 pl-8',
                      location?.pathname?.includes(item.to)
                        ? 'bg-charcoal-800 text-white'
                        : 'text-gray-400 hover:bg-charcoal-800 hover:text-white',
                    )}
                  >
                    <Icon
                      icon={item.icon}
                      iconColor="gray"
                      className="w-6 h-6 shrink-0"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>
                ))}
            </Disclosure>
          )}
        </li>
      )
      : null),
  );
};

SidebarNavigationLinks.propTypes = {
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
  allowGSPShowing: PropTypes.bool,
  allowLearningModules: PropTypes.bool,
  allowAperianLiveShowing: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  premiumUser: PropTypes.bool.isRequired,
  hasCompletedIBI: PropTypes.bool.isRequired,
  handleSidebarOpen: PropTypes.func,
};

SidebarNavigationLinks.defaultProps = {
  handleSidebarOpen: null,
  allowGSPShowing: true,
  allowLearningModules: true,
  allowAperianLiveShowing: true,
};

export default SidebarNavigationLinks;
