import React, { useContext } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import Button from '../../common/Button';
import Payment from '../../Payment';
import { Slider } from './Slider';

import { GS_PREMIUM_STRIPE_PLAN } from '../../../lib/constants';

import CompareCard from './CompareCard';
import MultiSelectCompareCard from './MultiSelectCompareCard';
import DropdownMultiSelectSearchModal from '../../common/Dropdowns/DropdownMultiSelectSearchModal';

const ProfileGroup = props => {
  const { router, handleOpenModal, handleCloseModal } = useContext(Context);
  const {
    groupName,
    sendInvitationsLink,
    hasPremium,
    createTeamLink,
  } = props;

  const renderGroupItems = ({ cards, onChange, selectedComparables }) => {
    if (!cards) return null;

    let sortedCards = cards;

    if (groupName === 'Cultures (A-Z)') {
      sortedCards = cards.sort((a, b) => a.displayName.localeCompare(b.displayName, undefined, { sensitivity: 'base' }));
    }

    if (groupName === 'Organizations and Segments (A-Z)') {
      sortedCards = cards.sort((a, b) => {
        if (a.type === 'org' && b.type === 'segment') return -1;
        if (a.type === 'segment' && b.type === 'org') return 1;
        return a.displayName.localeCompare(b.displayName);
      });
    }

    return sortedCards.map(card => {
      let checked;
      if (selectedComparables) {
        checked = !!selectedComparables.find(sc => sc.id === card.id);
      } else {
        checked = card.isVisible;
      }

      const listClasses = classNames({
        checked,
        peer: true,
        'h-44 min-w-44 max-w-44': !props.isGrid,
        'h-28 min-w-28 max-w-28': props.isGrid,
        'p-1 m-1 md:h-32 md:min-w-32 md:max-w-32 lg:min-w-32 lg:max-w-32 lg:h-32 xl:min-w-36 xl:max-w-36 xl:h-36 xxl:min-w-38 xxl:max-w-38 xxl:h-38': true,
      });

      const comparable = {
        id: card.id,
        type: card.type,
        htmlElementId: () => `${card.type}-${card.id}`,
      };

      const handleOpenConfirmModal = () => {
        handleOpenModal({
          content: (
            <DropdownMultiSelectSearchModal
              card={card}
              onChange={onChange}
              handleClose={handleCloseModal}
            />
          ),
          className: 'max-w-60 mt-40 p-4',
        });
      };
      return (card.type === 'segment' && !Object.keys(card).includes('isVisible'))
        ? (
          <MultiSelectCompareCard
            key={(card.displayName || card.shortDisplayName) + card.id}
            className={listClasses}
            cardId={card.id}
            cardDisplayName={card.displayName || card.shortDisplayName}
            avatarUrl={card.avatarUrl}
            checked={checked}
            onChange={onChange}
            handleOpenConfirmModal={handleOpenConfirmModal}
          />
        )
        : (
          <CompareCard
            key={(card.displayName || card.shortDisplayName) + card.id}
            className={listClasses}
            comparable={comparable}
            cardDisplayName={card.displayName || card.shortDisplayName}
            avatarUrl={card.avatarUrl}
            checked={checked}
            onChangeCard={onChange}
            isCulture={card.type === 'culture'}
          />
        );
    });
  };

  const handleEmptyGroup = () => {
    switch (groupName) {
      case 'Individuals (A-Z)': return (
        <div className="text-center">
          <Button
            variant="primary"
            className="m-auto"
            onClick={() => router.replace(sendInvitationsLink())}
            leadingButtonIcon={hasPremium() ? null : 'lock'}
          >
            Invite Colleagues
          </Button>
        </div>
      );
      case 'Teams (A-Z)':
      case 'Teams and Organizations (A-Z)': return (
        <div className="text-center">
          <Button
            variant="primary"
            className="m-auto"
            onClick={() => router.replace(createTeamLink())}
            leadingButtonIcon={hasPremium() ? null : 'lock'}
          >
            Create a Team
          </Button>
        </div>
      );
      case 'Cultures (A-Z)': return (
        <div className="text-center">
          {
            hasPremium()
              ? (
                <Button
                  className="m-auto"
                  variant="primary"
                >
                  No results found
                </Button>
              )
              : (
                <Button
                  variant="primary"
                  className="m-auto"
                  onClick={() => handleOpenModal({
                    content: <Payment
                      planId={GS_PREMIUM_STRIPE_PLAN}
                      handleCloseModal={handleCloseModal}
                    />,
                  })}
                  leadingButtonIcon={hasPremium() ? null : 'lock'}
                  iconColor="white"
                >
                  Upgrade Your Account
                </Button>
              )
          }
        </div>
      );
      default: return <div />;
    }
  };

  return (
    <section>
      <h4 className="mb-2">{props.groupName}</h4>
      {props.isGrid
        ? (
          <div className="flex flex-wrap justify-center h-full gap-1 md:gap-2">
            {renderGroupItems(props)}
          </div>
        )
        : (
          <div className="mx-8">
            {(!props.cards || !props.cards.length)
              ? handleEmptyGroup()
              : (
                <Slider>
                  {renderGroupItems(props)}
                </Slider>
              )}
          </div>
        )}
    </section>
  );
};

ProfileGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      type: PropTypes.string,
      avatarUrl: PropTypes.string,
      displayName: PropTypes.string,
      displayDescription: PropTypes.string,
      isComparisonItem: PropTypes.bool,
      values: PropTypes.arrayOf(
        PropTypes.shape({}),
      ),
    }),
  ).isRequired,
  hasPremium: PropTypes.func,
  isGrid: PropTypes.bool,
  createTeamLink: PropTypes.func,
  sendInvitationsLink: PropTypes.func,
};

ProfileGroup.defaultProps = {
  isGrid: false,
  createTeamLink: undefined,
  sendInvitationsLink: undefined,
  hasPremium: undefined,
};

export default ProfileGroup;
