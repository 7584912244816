import React, { Fragment } from 'react';
import { Link } from 'react-router';
import { Popover, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';

import Icon from '../../common/Icon';

const ThreadHistory = ({ threadHistory }) => (
  <Popover className="relative mb-2">
    {({ open, close }) => (
      <>
        <Popover.Button
          className="w-full flex justify-between items-center px-4 py-2 mb-2 gap-x-1.5 rounded-md bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-stone-25 focus:ring-inset focus:ring-rust-500 focus:shadow-input"
        >
          <span>Select</span>
          <Icon
            icon="chevron-down"
            iconColor="gray"
            className={`ml-4 w-3 h-3 ${open ? '-rotate-180 transition-transform duration-500'
              : 'rotate-0 transition-transform duration-500'}`}
            aria-hidden="true"
          />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 transform -translate-x-1/2 left-1/2 sm:px-0 lg:max-w-md xl:translate-x-0 xl:translate-y-0 xl:left-0">
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5">
              <div className="relative grid gap-4 p-2 bg-white">
                {threadHistory?.map(({ id, title }) => (
                  <Link
                    key={id}
                    className="p-2 no-underline rounded-lg hover:bg-ivory-500"
                    to={`/administration/copilot?c=${id}`}
                    onClick={() => close()}
                  >
                    {title}
                  </Link>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </>
    )}

  </Popover>
);

ThreadHistory.propTypes = {
  threadHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ).isRequired,
};

export default ThreadHistory;
