/* eslint-disable new-cap */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import { getTips } from '../../../actions/tips';

import TipCard from './TipCard';
import Tip from './TipCard/Tip';

import { trackDashboardTipsClick } from '../../../lib/tracker/dashboard';

import { AuthError } from '../../../lib/errors';

const DashboardTips = ({ tips: initialTips, accessLevel }) => {
  const [tips, setTips] = useState(initialTips);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [getMoreTips, setGetMoreTips] = useState(true);

  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const handleGetMoreTips = async () => {
    try {
      const response = await getTips(apiService);
      setTips(response.tips);
    } catch (err) {
      if (err instanceof AuthError) {
        router.push('/logout');
      } else {
        setTips(initialTips);
        setGetMoreTips(false);
      }
    }
  };

  // make another call or reset to initial tips
  const handleNextTip = () => {
    const isLastTip = currentIndex === tips.length - 1;
    if (isLastTip && !getMoreTips) {
      setTips(initialTips);
    } else if (isLastTip && getMoreTips) {
      handleGetMoreTips();
    }
  };

  useEffect(() => {
    handleNextTip();
  }, [currentIndex]);

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentIndex(prev => ((prev === tips.length - 1) ? 0 : prev + 1));
    }, 12000);
    return () => clearInterval(id);
  }, [tips]);

  const hasPremiumAccess = accessLevel.some(level => level.startsWith('premium'));

  const trackClickLink = ({ tipType, title, tipLink, text }) => {
    trackDashboardTipsClick(
      apiService,
      tipType,
      title.split('In')[1],
      tipLink.split('/')[1],
      text.replace(/<\/?[^>]+(>|$)/g, ''),
    );
  };

  return (
    <TipCard
      tips={tips}
      currentIndex={currentIndex}
      hasPremiumAccess={hasPremiumAccess}
      trackClickLink={trackClickLink}
    >
      {tips.map((tip, index) => (
        <Tip
          key={tip.id}
          tip={tips[index]}
          currentTipId={tips[currentIndex].id}
          hasPremiumAccess={hasPremiumAccess}
        />
      ))}
    </TipCard>
  );
};

DashboardTips.propTypes = {
  tips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      tipLink: PropTypes.string,
      flagIcon: PropTypes.string,
      guidesLink: PropTypes.string,
    }),
  ).isRequired,
  accessLevel: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DashboardTips;
