import tracker from '../index';

const { eventNames, eventProps, resolveAfterTimeout } = tracker;

const trackViewCopilot = () => {
  tracker.track(eventNames.VIEW_GUIDES_COPILOT);
  return resolveAfterTimeout;
};

const trackSubmitCopilotChat = async (apiService, text) => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.SUBMIT_COPILOT_CHAT,
    properties: {
      [eventProps.CHAT_TEXT]: text,
    },
  });

  return resolveAfterTimeout;
};

const trackCopyCopilotResponse = async apiService => {
  await apiService.post('trackers/mixpanel', {
    eventName: eventNames.COPY_COPILOT_RESPONSE,
  });

  return resolveAfterTimeout;
};

export {
  trackViewCopilot,
  trackSubmitCopilotChat,
  trackCopyCopilotResponse,
};
