import React from 'react';
import PropTypes from 'prop-types';
import Button, { propTypes as buttonPropTypes, defaultProps as defaultButtonProps } from '../../common/Button';

import Avatar from '../../common/Avatars/Avatar';
import Drawer from '../../Drawer';
import Icon from '../../common/Icon';

import { rotate } from '../../../../styles/theme';

function renderButton(text, options, token, id, isIbi) {
  if (!text) return null;

  return (
    <Button
      id={id}
      {...options}
      dataID={token}
      className={`w-full ${isIbi ? 'border-none mb-1 bg-transparent hover:bg-transparent px-1' : ''}`}
    >
      <div className={`flex items-center ${text === 'Remove' ? 'justify-end text-sm' : ''}`}>
        {options.icon && (<Icon icon={options.icon} iconColor="white" className="w-5 h-5" />)}
        <span className="flex-grow px-1 m-0">{text}</span>
      </div>
    </Button>
  );
}

function renderDescription(description, isIbi) {
  if (!description) return null;

  return (
    <div className={`leading-snug card__description hyphens-none ${isIbi ? 'min-h-24 lg:min-h-28' : ''}`}>
      {description}
    </div>
  );
}

const DrawerButton = ({
  drawerLabel,
  showDrawerContents,
  toggleDrawer,
  memberUserId,
  isIbi,
}) => (
  <Button
    variant="secondary"
    isFullWidth
    onClick={() => toggleDrawer(memberUserId)}
    className="text-sm"
  >
    <div className="flex items-center">
      {!isIbi && (
        <span className="flex-grow m-0">
          {drawerLabel}
        </span>
      )}
      <Icon
        icon="chevron-down"
        iconColor="current"
        className={`w-3 h-3 ${rotate(showDrawerContents)}`}
      />
      {isIbi && (
        <span className="flex-grow px-2 m-0">
          {drawerLabel}
        </span>
      )}
    </div>
  </Button>
);

DrawerButton.propTypes = {
  drawerLabel: PropTypes.string.isRequired,
  showDrawerContents: PropTypes.bool.isRequired,
  toggleDrawer: PropTypes.func.isRequired,
  memberUserId: PropTypes.string.isRequired,
  isIbi: PropTypes.bool,
};

DrawerButton.defaultProps = {
  isIbi: false,
};

const ProfileCardAvatarWithButton = props => {
  const {
    memberUserId,
    token,
    displayName,
    displayDescription,
    confirmText,
    confirmId,
    confirmButton,
    cancelText,
    cancelId,
    cancelButton,
    withDrawer,
    drawerLabel,
    showDrawerContents,
    toggleDrawer,
    drawerContents,
    isIbi,
    isIbi360,
  } = props;

  return (
    <div className="relative card">
      {isIbi && (
        <div className="absolute z-10 right-1">
          {renderButton(cancelText, cancelButton, token, cancelId, isIbi)}
        </div>
      )}
      <div className="relative card__container">
        <Avatar
          avatarUrl={props.avatarUrl}
          avatarImgAlt={props.displayName}
          id={props.memberUserId}
        />
        {isIbi && isIbi360 && (
          <Icon
            icon="ibi-360"
            className="absolute w-6 h-6 sm:w-8 sm:h-8 md:w-8 md:h-8 lg:w-9 lg:h-9 top-16 sm:top-18 md:top-[5.5rem] lg:top-20 left-10 sm:left-12"
          />
        )}
        <div className="card__content">
          <div className="card__heading text-charcoal-900">
            {displayName}
          </div>
          {renderDescription(displayDescription, isIbi)}
        </div>
        <div className={`gap-1 ${isIbi ? '!mt-4 lg:!mt-6 md:!mt-8 col-start-1 sm:col-start-2 md:!col-start-4' : ''} leading-snug card__actions`}>
          {renderButton(confirmText, confirmButton, token, confirmId)}
          {!isIbi && renderButton(cancelText, cancelButton, token, cancelId)}
          {withDrawer
            ? (
              <DrawerButton
                drawerLabel={drawerLabel}
                showDrawerContents={showDrawerContents}
                toggleDrawer={toggleDrawer}
                memberUserId={memberUserId}
                isIbi={isIbi}
              />
            )
            : null}
        </div>
      </div>
      <Drawer
        id={`raters-${memberUserId}`}
        showDrawerContents={showDrawerContents}
      >
        {drawerContents}
      </Drawer>
    </div>
  );
};

ProfileCardAvatarWithButton.propTypes = {
  avatarUrl: PropTypes.string,
  displayName: PropTypes.string.isRequired,
  displayDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
  ]),
  confirmText: PropTypes.string.isRequired,
  confirmId: PropTypes.string,
  confirmButton: PropTypes.shape(buttonPropTypes),
  cancelText: PropTypes.string.isRequired,
  cancelId: PropTypes.string,
  cancelButton: PropTypes.shape(buttonPropTypes),
  token: PropTypes.string,
  withDrawer: PropTypes.bool,
  drawerLabel: PropTypes.string,
  showDrawerContents: PropTypes.bool,
  drawerContents: PropTypes.node,
  toggleDrawer: PropTypes.func,
  memberUserId: PropTypes.string,
  isIbi: PropTypes.bool,
  isIbi360: PropTypes.bool,
};
ProfileCardAvatarWithButton.defaultProps = {
  avatarUrl: '',
  displayDescription: null,
  confirmButton: defaultButtonProps,
  confirmId: 'confirm',
  cancelButton: defaultButtonProps,
  cancelId: 'cancel',
  token: null,
  withDrawer: false,
  drawerLabel: '',
  showDrawerContents: false,
  toggleDrawer: () => { },
  drawerContents: undefined,
  memberUserId: '',
  isIbi: false,
  isIbi360: false,
};

export default ProfileCardAvatarWithButton;
