import React from 'react';
import PropTypes from 'prop-types';

import ProfileChartTransformer from '../../../common/ProfileChartTransformer';
import Tooltip from '../../../common/Tooltip';
import Button from '../../../common/Button';
import PopoverComponent from '../../../common/Popover';
import Icon from '../../../common/Icon';

const ShareProfilePopover = props => (
  <div className="divide-y divide-charcoal-900/10 text-charcoal-900">
    {props.profileShareOptions.map(option => (
      <div key={option.id} className="flex justify-start align-center">
        <button
          type="button"
          className="flex items-center w-full p-2 text-sm group hover:bg-charcoal-25 active:bg-charcoal-25 text-charcoal-800"
          onClick={option.onClick}
        >
          <Icon
            icon={option.icon}
            iconColor="primary"
            className="w-5 h-5 mr-2"
          />
          <p className="mb-0 text-sm font-medium">{option.name}</p>
          {option.isCopy && props.isCopied ? (
            <Icon
              icon="check"
              iconColor="green"
              className="w-5 h-5 ml-2"
            />
          ) : null}
        </button>
      </div>
    ))}
  </div>
);

ShareProfilePopover.propTypes = {
  profileShareOptions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      onClick: PropTypes.func,
      icon: PropTypes.string,
    }),
  ).isRequired,
  isCopied: PropTypes.bool,
};

ShareProfilePopover.defaultProps = {
  isCopied: false,
};

const ProfileSummary = props => {
  const profileShareOptions = [];
  if (props.allowLink) {
    profileShareOptions.push({
      id: 1,
      name: 'Copy Shareable Link',
      onClick: props.createShareableLink,
      icon: 'link',
      isCopy: true,
    });
  }
  if (props.allowLinkedIn) {
    profileShareOptions.push({
      id: 2,
      name: 'Share To LinkedIn',
      onClick: props.shareToLinkedIn,
      icon: 'linkedin',
    });
  }

  return (
    <div id="about-my-profile" className="grid justify-center w-full max-w-6xl gap-4 px-6 pb-6 m-auto bg-white md:gap-8 md:p-10 md:grid-cols-2 rounded-3xl print:flex print:max-w-[24rem] print:!py-0 print:!px-4 print:mt-4">
      <div className="w-full max-w-sm m-auto">
        <ProfileChartTransformer
          isMe
          isProfile
          displayDimensionLink={false}
          {...props}
        />
      </div>
      <div className="grid w-full max-w-md px-0 m-auto text-left lg:mx-0 print:hidden" id="congratulations ">
        <h2 className="mb-6 text-2xl font-semibold leading-8 font-headline md:text-3xl font-display text-charcoal-900 lg:mb-8 md:leading-10">
          Congratulations, {props.firstName}. You&#39;ve completed your Profile.
        </h2>
        <p className="mb-6 font-sans text-sm leading-6 lg:mb-10 md:leading-8 md:text-base lg:text-lg lg:leading-9">
          {props.summaryContent}
        </p>
        <div className="flex w-full m-auto justify-evenly md:m-0 align-center">
          {props.buttons.map(({
            icon,
            iconColor,
            isShare,
            listRef,
            onClick,
            ref,
            to,
            tooltip,
          }) => (
            <Tooltip
              onHover
              key={icon}
              position="top"
              content={tooltip}
            >
              {isShare ? (
                <div ref={listRef}>
                  <PopoverComponent
                    position="bottom"
                    content={(
                      <ShareProfilePopover
                        isCopied={props.isCopied}
                        profileShareOptions={profileShareOptions}
                      />
                    )}
                    className="w-60"
                    isStatic
                  >
                    <Button
                      className="p-4 w-14 h-14"
                      filledColor="secondary"
                      leadingButtonIcon={icon}
                      iconColor={iconColor}
                      isCircle
                      ariaLabel={tooltip}
                      ref={ref}
                    />
                  </PopoverComponent>
                </div>
              ) : (
                <Button
                  className="p-4 w-14 h-14"
                  filledColor="secondary"
                  to={to}
                  onClick={onClick}
                  leadingButtonIcon={icon}
                  iconColor={iconColor}
                  isCircle
                  ariaLabel={tooltip}
                  ref={ref}
                />
              )}
            </Tooltip>
          ))}
        </div>
      </div>
    </div>
  );
};

ProfileSummary.propTypes = {
  firstName: PropTypes.string.isRequired,
  summaryContent: PropTypes.string.isRequired,
  state: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatarUrl: PropTypes.string,
    profileDetails: PropTypes.shape({
    }),
    hideProfileToggleText: PropTypes.string,
    myProfileVisibility: PropTypes.string,
    error: PropTypes.string,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      actionClass: PropTypes.string,
      iconClass: PropTypes.string,
      to: PropTypes.string,
      label: PropTypes.string,
      isShare: PropTypes.bool,
    }),
  ),
  createShareableLink: PropTypes.func.isRequired,
  shareToLinkedIn: PropTypes.func.isRequired,
  isCopied: PropTypes.bool,
  allowLink: PropTypes.bool.isRequired,
  allowLinkedIn: PropTypes.bool.isRequired,
};

ProfileSummary.defaultProps = {
  state: {},
  buttons: [],
  isCopied: false,
};

export default ProfileSummary;
