import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import OnboardingHeader from '../Header';
import Background from '../Background';
import Footer from '../../../common/Footer';

const OnboardingForm = ({
  children,
  path,
  step,
  currentStep,
  handleSubmit,
  handleRedirectLogin,
  formData,
  isSingleStepForm,
}) => (
  <div className="flex flex-col justify-between h-screen">
    <Background path={path} step={step} currentStep={currentStep} />
    <OnboardingHeader
      currentStep={currentStep}
      handleRedirectLogin={handleRedirectLogin}
      formData={formData}
    />
    <CSSTransition
      in={step === currentStep || isSingleStepForm}
      timeout={600}
      classNames="fade"
      unmountOnExit
    >
      <form
        className="flex items-center justify-center flex-grow"
        onSubmit={handleSubmit}
      >
        {children}
      </form>
    </CSSTransition>
    <Footer />
  </div>
);
OnboardingForm.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.shape({

  }).isRequired,
  step: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleRedirectLogin: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  isSingleStepForm: PropTypes.bool,
};

OnboardingForm.defaultProps = {
  isSingleStepForm: false,
};

export default OnboardingForm;
