import React from 'react';
import { propTypes } from './types';

const ProfileConnectingLine = ({
  id,
  avatarPosition,
  traitLinePosition,
  svgContainer,
  myProfileVisibility,
  isHistoryProfile,
}) => {
  if (!avatarPosition || !svgContainer) return null;

  const svgLeft = svgContainer.getBoundingClientRect().left;
  const x1 = avatarPosition - svgLeft;
  const x2 = traitLinePosition - svgLeft;

  return (
    <line
      key={id}
      className={`line${isHistoryProfile ? ' history' : ''}${(!traitLinePosition) ? 'invisible' : ''}`}
      x1={x1}
      y1="0"
      x2={x2}
      y2="98%"
      visibility={myProfileVisibility}
    />
  );
};

ProfileConnectingLine.propTypes = propTypes;

export default ProfileConnectingLine;
