import { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';

import { Context } from '../../../context/ContextProvider';

import RedeemAccessCodeAction from '../../../actions/accessCodes/reedeemAccessCode';
import needsAuthentication from '../../../lib/needsAuthentication';
import accessCodeResultsMeta from '../../../lib/accessCodeResultsMeta';
import { trackAccessCodeRedeem, trackAccessCodeRedeemFailure } from '../../../lib/tracker/access-codes';
import { COOKIE_NOTIFICATION, IS_PRODUCTION } from '../../../lib/constants';
import logger from '../../../lib/logger';

const requiresHardReload = targetUrl => {
  const nonReactUrlIdentifiers = [
    'guides',
    'culture-guides',
    'gslearning.aperianglobal.com',
    'gslearning-stage.aperianglobal.com',
    'resources.aperian.com',
    'resources.aperianglobal.com',
    '/samlp/',
  ];
  return nonReactUrlIdentifiers.some(url => targetUrl.includes(url)) || targetUrl.startsWith('https');
};

const AccessCodeRedeem = ({ params }) => {
  const { apiService, currentUser, router } = useContext(Context);
  const [accessCode] = useState(params.accessCode);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookies] = useCookies();

  const redirectToDashboard = apiResponse => {
    const notification = accessCodeResultsMeta(apiResponse);
    const { type, message } = notification;
    setCookies(COOKIE_NOTIFICATION, { type, message }, { path: '/', secure: IS_PRODUCTION });
    if (notification.redirectUrl) {
      const { redirectUrl } = notification;
      return requiresHardReload(redirectUrl)
        ? window.location.assign(redirectUrl)
        : router.push(redirectUrl);
    }
    return router.push('/dashboard');
  };

  const handleRedeemFailure = response => {
    const { meta: { accessCodeResults: { reason } } } = response;
    return trackAccessCodeRedeemFailure(reason)
      .then(() => redirectToDashboard(response));
  };

  useEffect(() => {
    needsAuthentication(router, currentUser, `redirect_to=/access-codes/${accessCode}/redeem&accessCode=${accessCode}`, () => {
      new RedeemAccessCodeAction(apiService)
        .execute(accessCode)
        .then(response => {
          const { meta: {
            accessCodeResults: { accepted, alreadyRedeemed, orgHasAccess },
          } } = response;

          if (alreadyRedeemed || orgHasAccess) {
            router.push('/dashboard');
            return null;
          }

          if (accepted) {
            const {
              auth: { profile: { accessLevel, userid } },
              accessCodeResults: { planInfo: { name } },
            } = response.meta;

            return trackAccessCodeRedeem({
              userid,
              accessLevel,
              accessCode,
              planName: name,
            }).then(() => redirectToDashboard(response));
          }
          return handleRedeemFailure(response);
        })
        .catch(e => {
          logger.error(`Error redeeming access code ${accessCode}`, e);
          router.push('/error');
        });
    });
  }, []);

  return null;
};

AccessCodeRedeem.propTypes = {
  params: PropTypes.shape({
    accessCode: PropTypes.string,
  }).isRequired,
};

export default AccessCodeRedeem;
