import { useMemo, useState } from 'react';
import { matchSorter } from 'match-sorter';

export function useFilter(items = [], options = {}) {
  const [filterValue, setFilterValue] = useState('');

  const filteredItems = useMemo(() => {
    if (!filterValue) return items;
    const filterOptions = { keys: options.keys, threshold: options.threshold };
    return matchSorter(
      items,
      filterValue,
      filterOptions,
    );
  }, [items, filterValue, options.keys, options.threshold]);

  return { filterValue, setFilterValue, filteredItems };
}
