import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import withServerSideData from '../../../../HOC/withServerSideData';
import { APIContext } from '../../../../context/API';
import { ModalContext } from '../../../../context/Modal';

import Notification from '../../../Notifications/Notification';
import LabelInput from '../../../common/LabelInput';
import Button from '../../../common/Button';
import OrganizationSegmentsList from './OrganizationSegmentsList';
import DeleteSegmentModal from '../../../DeleteModals/DeleteSegmentModal';
import ConfirmModal from '../../../common/Modal/ConfirmModal';

import { getErrorMessage } from '../../RenderNotification';
import { addOrganizationSegment, updateOrganizationSegment, deleteOrganizationSegment } from '../../../../actions/organizations/organizationSegments';

const OrganizationSegments = ({
  params: { id: orgId },
  initialData: { segments },
}) => {
  const [initialSegments] = useState(segments);
  const [editableSegments, setEditableSegments] = useState(segments?.map(
    segment => ({
      ...segment,
      isEditing: false,
    }),
  ));
  const [newSegment, setNewSegment] = useState({
    samlPropertyName: '',
    displayName: '',
  });
  const [notification, setNotification] = useState(null);

  const { apiService } = useContext(APIContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);

  const handleOnChange = ({ target: { name, value } }) => setNewSegment(prevState => ({
    ...prevState,
    [name]: value,
  }));

  useEffect(() => {
    let timeOut;
    if (notification) timeOut = setTimeout(() => setNotification(null), 3000);
    return () => clearTimeout(timeOut);
  }, [notification]);

  const onAddSegment = async e => {
    e.preventDefault();
    try {
      const { segment } = await addOrganizationSegment(apiService, orgId, { segment: newSegment });
      setEditableSegments([...editableSegments, segment]);
      setNewSegment({
        samlPropertyName: '',
        displayName: '',
      });
      setNotification({ type: 'confirm', message: 'Segment added.' });
    } catch (err) {
      setNotification({ type: 'warning', message: `Error adding segment: ${getErrorMessage(err)}` });
    }
  };

  const handleChangeEnabled = (e, id) => {
    e.preventDefault();
    const updatedSessions = editableSegments
      .map(segment => (segment.id === +id ? { ...segment, enabled: e.target.checked } : segment));
    setEditableSegments(updatedSessions);
  };

  const handleChangeDisplayName = (name, value) => {
    const [id, property] = name.split('-');
    const updatedSegments = editableSegments
      .map(segment => (segment.id === +id ? { ...segment, [property]: value } : segment));
    setEditableSegments(updatedSegments);
  };

  const handleEditState = async id => {
    try {
      const originalSegment = initialSegments.find(segment => segment.id === id);
      const editedSegment = editableSegments.find(segment => segment.id === id);
      const data = {
        id,
        samlPropertyName: editedSegment.samlPropertyName,
        displayName: editedSegment.displayName,
        enabled: editedSegment.enabled,
      };

      const displayNamesMatch = originalSegment.displayName === editedSegment.displayName;
      const enabledStatesMatch = originalSegment.enabled === editedSegment.enabled;

      let newSegments = editableSegments;

      if (!displayNamesMatch || !enabledStatesMatch) {
        const { segment: updatedSegment } = await updateOrganizationSegment(
          apiService,
          orgId,
          { segment: data },
        );

        setNotification({ type: 'confirm', message: 'Segment updated.' });
        newSegments = editableSegments
          .map(segment => (segment.id === id
            ? { ...updatedSegment, isEditing: !segment.isEditing }
            : segment));
      }
      newSegments = editableSegments
        .map(session => (session.id === id
          ? { ...session, isEditing: !session.isEditing }
          : session));
      setEditableSegments(newSegments);
    } catch (err) {
      const error = err;
      if (err.status === 404) error.error = 'Segment not found';
      setNotification({ type: 'warning', message: `Error updatingsegment: ${getErrorMessage(err)}` });
    }
  };

  const onDeleteSegment = segmentData =>
    deleteOrganizationSegment(apiService, orgId, { segmentId: segmentData.id })
      .then(() => {
        handleCloseModal();
        const updatedSegments = editableSegments
          .filter(existingSegment => existingSegment.id !== segmentData.id);
        setEditableSegments(updatedSegments);
        setNotification({
          type: 'success',
          message: `${segmentData.samlPropertyName} property was deleted successfully.`,
        });
      })
      .catch(err => {
        handleCloseModal();
        setNotification({
          type: 'failure',
          message: `Could not delete the SAML property: ${err}`,
        });
      });

  const handleOpenConfirmModal = segmentData => {
    handleCloseModal();
    handleOpenModal({
      content: (
        <ConfirmModal
          confirmButtonText="Yes, Delete SAML Property"
          onCancel={handleCloseModal}
          onConfirm={() => onDeleteSegment(segmentData)}
          title="Confirm Deletion"
          confirmText="DELETE"
        >
          <p className="text-base">You are about to delete this SAML property and all related data. Confirm deletion by typing <b>DELETE</b> in the box.</p>
        </ConfirmModal>),
    });
  };

  const handleOpenDeleteModal = async segmentData => {
    handleOpenModal({
      content: <DeleteSegmentModal
        onCancel={handleCloseModal}
        onConfirm={() => handleOpenConfirmModal(segmentData)}
      />,
      modalSize: 'large',
    });
  };

  return (
    <>
      {notification ? <Notification {...notification} /> : null}
      <div className="grid gap-2 mt-4 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="font-serif text-xl">Organization Segments</h5>
        </div>
        <div className="flex flex-col mb-8 md:col-span-4">
          <form onSubmit={onAddSegment}>
            <LabelInput
              id="samlPropertyName"
              name="samlPropertyName"
              labelText="SAML Property Name"
              labelType="text"
              value={newSegment.samlPropertyName}
              maxLength={255}
              isRequired
              onChangeValue={handleOnChange}
              helperText="Enter the segment name from the SAML connection."
            />
            <LabelInput
              id="displayName"
              name="displayName"
              labelText="Display Name"
              labelType="text"
              value={newSegment.displayName}
              maxLength={255}
              isRequired
              onChangeValue={handleOnChange}
              helperText="Enter a display name for end users on comparison."
            />
            <div className="flex justify-end mb-4">
              <Button isSmall filledColor="secondary" type="submit">Create Segment</Button>
            </div>
          </form>
          <div className="overflow-x-auto">
            <OrganizationSegmentsList
              segments={editableSegments}
              handleChangeEnabled={handleChangeEnabled}
              handleChangeDisplayName={handleChangeDisplayName}
              handleEditSave={handleEditState}
              onDelete={handleOpenDeleteModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

OrganizationSegments.getAPIDataKey = () => 'organizationSegments';

OrganizationSegments.getData = (apiService, { id }) =>
  apiService.get(`organizations/${id}/segments`).then(({ segments }) => ({ organizationSegments: { segments } }));

OrganizationSegments.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    segments: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        samlPropertyName: PropTypes.string,
        displayName: PropTypes.string,
        enabled: PropTypes.bool,
      }),
    ),
  }).isRequired,
};

export default withServerSideData(OrganizationSegments);
