import React from 'react';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import { propTypes } from './types';

import DropdownMenu from '../../../common/Dropdowns/DropdownMenu';
import { languageOptions } from '../../../../lib/language';

const CompareDetails = props => {
  if (!props.profiles?.length || !props.initialProfiles?.length) return null;
  if (props.profiles.length === 2 && props.initialProfiles.length === 2) {
    const { shortDisplayName, type, segmentValue } = props.profiles[1];
    const displayName = type === 'segment' ? `${shortDisplayName}: ${segmentValue}` : shortDisplayName;

    return (
      <div className="bg-slate-50 full-width">
        <section className="block py-10 breakout print:hidden">
          <div className="w-full min-h-screen px-4 py-6 bg-white md:px-6 md:py-10 lg:px-10 lg:py-16 rounded-3xl">
            <header className="flex flex-col justify-between mb-4 md:mb-2 md:flex-row">
              <h2 id="advice" className="w-full md:w-3/4">
                Advice for Working with {parser(DOMPurify.sanitize(displayName, { ADD_ATTR: ['target'] }))}
              </h2>
              <DropdownMenu
                options={languageOptions('advice')}
                value={props.selectedLanguage || 'en'}
                onChangeValue={e => props.onChangeLanguage(e)}
                isRound
              />
            </header>
            {React.cloneElement(props.children, { ...props, displayName })}
          </div>
        </section>
      </div>
    );
  }

  return null;
};

CompareDetails.propTypes = propTypes;

export default CompareDetails;
