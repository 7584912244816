async function getCopilotConfig(apiService) {
  return apiService.get('copilot/admin');
}

async function saveThreadMessage(apiService, question, threadId) {
  let url = 'copilot/threads';
  if (threadId) url += `/${threadId}`;
  return apiService.post(url, { question });
}

async function updateCopilotConfig(apiService, config) {
  return apiService.post('copilot/config', { config });
}

export {
  getCopilotConfig,
  saveThreadMessage,
  updateCopilotConfig,
};
