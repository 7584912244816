import React from 'react';
import PropTypes from 'prop-types';

import ProfileTraitLine from '../../ProfileTraitLine';

const TraitLines = ({
  profiles,
  compareProfile,
  myProfileVisibility,
  hasResizeSupport,
  traitLinePositions,
  isTeamOwner,
}) => {
  if (!profiles || !profiles[0].grid) {
    return null;
  }
  const hasVisibility = profile => (compareProfile && profile.isMe
    ? myProfileVisibility
    : null);

  return profiles.filter(profile => !hasResizeSupport || (hasResizeSupport && profile.isVisible))
    .map(profile => (
      <ProfileTraitLine
        key={profile.id}
        color={profile.profileColor}
        {...profile.grid}
        id={profile.id}
        isCompare={profiles.length > 1}
        first={profile.isMe}
        isHistoryProfile={profile.isHistoryProfile}
        isCulture={profile.type === 'culture'}
        onTraitLinePositionsChanged={traitLinePositions}
        myProfileVisibility={hasVisibility(profile)}
        isTeamOwner={isTeamOwner}
      />
    ));
};

const item = PropTypes.shape({
  title: PropTypes.string.isRequired,
  positioningStatement: PropTypes.string.isRequired,
  tendencyHeading: PropTypes.string.isRequired,
  differenceCTA: PropTypes.string,
  tendencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  styleSwitchingLink: PropTypes.string,
});

const ProfileDimensionItemPropTypes = {
  you: item.isRequired,
  other: item,
  conflictVideoID: PropTypes.string,
  conflictVideoSentence: PropTypes.string,
};

TraitLines.propTypes = {
  profiles: PropTypes.arrayOf(
    PropTypes.shape({
      shortDisplayName: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      avatarUrl: PropTypes.string,
      grid: PropTypes.shape({
        egalitarianism_status: PropTypes.number,
        direct_indirect: PropTypes.number,
        independent_interdependent: PropTypes.number,
        task_relationship: PropTypes.number,
        risk_certainty: PropTypes.number,
      }),
      profileDetails: PropTypes.shape({
        summaryContent: PropTypes.string.isRequired,
        dimensions: PropTypes.arrayOf(PropTypes.shape(ProfileDimensionItemPropTypes)).isRequired,
      }),
    })),
  compareProfile: PropTypes.bool,
  myProfileVisibility: PropTypes.string.isRequired,
  hasResizeSupport: PropTypes.bool.isRequired,
  traitLinePositions: PropTypes.func.isRequired,
  isTeamOwner: PropTypes.bool,
};

TraitLines.defaultProps = {
  compartProfile: false,
  isTeamOwner: false,
};

export default TraitLines;
