import React, { useContext, useEffect, useState, useRef } from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

// Import localization support for SurveyJS for the languages we need
import 'survey-core/i18n/french';
import 'survey-core/i18n/german';
import 'survey-core/i18n/japanese';
import 'survey-core/i18n/spanish';
import 'survey-core/i18n/simplified-chinese';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';

import withServerSideData from '../../../HOC/withServerSideData';

import Notification from '../../Notifications/Notification';

import SiteHeader from '../../SiteHeader';
import Icon from '../../common/Icon';
import Footer from '../../common/Footer';

import theme from '../assessmentSurveyTheme.json';

import {
  manageAssessmentSurveyInstanceAction,
  submitAssessmentSurveyAnswerAction,
  setAssessmentSurveyLanguageAction,
} from '../../../actions/assessments/manageSurveyInstance';

import { CurrentUserContext } from '../../../context/CurrentUser';
import { ModalContext } from '../../../context/Modal';

import RefreshMessage from '../../GlobeSmartDialog/RefreshMessage';

import { SESSION_ALERT_INTERVAL } from '../../../lib/constants';

const AssessmentRaterSurvey = ({ location, initialData, params }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { handleOpenDialog } = useContext(ModalContext);
  const intervalId = useRef(null);

  const {
    displayName,
    assessment,
    surveyJson,
    surveyData,
  } = initialData;
  const pageTitle = displayName;
  const { raterId } = params;

  useEffect(() => {
    if (intervalId.current === null) {
      intervalId.current = setInterval(() => {
        handleOpenDialog({
          content: <RefreshMessage />,
          afterCloseAction: () => currentUser.refreshAuthToken(),
        });
      }, SESSION_ALERT_INTERVAL);
    }

    return () => {
      clearInterval(intervalId.current);
      intervalId.current = null;
    };
  }, []);

  useEffect(() => {
    if (surveyData.surveyComplete) {
      const timeout = window.setTimeout(() => {
        router.push('/dashboard');
      }, 5000);

      return () => clearTimeout(timeout);
    }
    return undefined;
  }, []);

  const [surveyStarted, setSurveyStarted] = useState(surveyData?.state === 'started');
  const [surveyResponseId, setSurveyResponseId] = useState(surveyData?.surveyResponseId || null);
  const [isMounted, setIsMounted] = useState(false);
  let isCurrentSurveyPageChanged = true;

  // Store the survey instance and keep it persistent across renders
  const surveyRef = useRef(null);

  // Initialize the survey model only once
  if (!surveyRef.current) {
    surveyRef.current = new Model(surveyJson);
  }

  const survey = surveyRef.current;

  const languageOptions = [
    { code: 'en', name: 'English', label: 'English' },
    { code: 'fr', name: 'French', label: 'Français' },
    { code: 'de', name: 'German', label: 'Deutsch' },
    { code: 'ja', name: 'Japanese', label: '日本語' },
    { code: 'es', name: 'Spanish', label: 'Español' },
    { code: 'zh-cn', name: 'Chinese (simplified)', label: '中文' },
  ];

  const getLanguageName = localeCode => {
    const language = languageOptions.find(option => option.code === localeCode);
    return language ? language.name : 'English';
  };

  const getLanguageCode = languageName => {
    const language = languageOptions.find(option => option.name === languageName);
    return language ? language.code : 'en';
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    surveyData?.languagePreference ? getLanguageCode(surveyData.languagePreference) : 'en',
  );

  const [defaultLanguage, setDefaultLanguage] = useState(
    surveyData?.languagePreference ? getLanguageCode(surveyData.languagePreference) : 'en',
  );

  const startSurvey = async sender => {
    if (!surveyStarted) {
      const data = {
        currentSurveyPage: sender.currentPageNo,
        asmtType: assessment,
        version: 3,
        language: getLanguageName(survey.locale),
        isRater: true,
        state: 'started',
        raterId,
      };
      const result = await manageAssessmentSurveyInstanceAction(apiService, data);
      if (result && result.status === 200 && result.responseId) {
        setSurveyResponseId(result.responseId);
        setSurveyStarted(true);
      }
    }
  };

  const submitSurveyAnswer = async ({
    sender, questionCode, answer,
  }) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      asmtType: assessment,
      questionCode,
      answer,
      surveyResponseId,
      isCurrentSurveyPageChanged,
      isRater: true,
    };
    await submitAssessmentSurveyAnswerAction(apiService, data);
  };

  const completeSurvey = async (sender, surveyResponses) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      asmtType: assessment,
      version: 3,
      language: getLanguageName(survey.locale),
      isRater: true,
      state: 'completed',
      raterId,
      surveyResponseId,
      surveyResponses,
    };
    await manageAssessmentSurveyInstanceAction(apiService, data);

    const timeout = window.setTimeout(() => {
      router.push('/dashboard');
    }, 5000);

    return () => clearTimeout(timeout);
  };

  const setSurveyLanguage = async ({
    sender, language,
  }) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      surveyResponseId,
      language,
    };
    await setAssessmentSurveyLanguageAction(apiService, data);
  };

  const handleValueChanging = async (sender, { name, value, question }) => {
    if (name.startsWith('IMP_') || (!!question && !question.isVisible)) return;

    await submitSurveyAnswer({
      sender,
      questionCode: name,
      answer: value,
    });
    isCurrentSurveyPageChanged = false;
  };

  const handleAfterRenderPage = () => {
    const navigationButtons = document.querySelectorAll('.sd-btn');
    navigationButtons.forEach(button => {
      button.classList.remove('sd-btn'); // Remove the default class
    });
  };

  const handlePageChanging = async sender => {
    if (sender.currentPageNo === 0 && !surveyStarted) {
      await startSurvey(sender);
    }
  };

  const handlePageChanged = () => {
    isCurrentSurveyPageChanged = true;
  };

  const handleAfterRenderQuestion = (_, options) => {
    if (options.question.getType() === 'nouislider') {
      const sliderElement = options.htmlElement.querySelector('.noUi-target');
      if (sliderElement) {
        const noUiSliderInstance = sliderElement.noUiSlider;

        // Set the tooltip visibility and format of value
        noUiSliderInstance.updateOptions({
          tooltips: options.question.value >= 1 ? [true] : [false],
          format: {
            to(value) {
              return Math.round(value);
            },
            from(value) {
              return Number(value);
            },
          },
        });

        // Show tooltip when the slider is touched or moved
        noUiSliderInstance.on('start', () => {
          noUiSliderInstance.updateOptions({
            tooltips: [true],
          });
        });

        noUiSliderInstance.on('change', () => {
          noUiSliderInstance.updateOptions({
            tooltips: [true],
          });
        });

        // Handle the N/A checkbox
        if (options.question.jsonObj.showNA) {
          let naCheckbox = sliderElement.parentElement.querySelector('.na-checkbox-container');

          // Define the text based on the selected language
          const naText = 'Not Applicable'; // Add more translations as needed

          if (!naCheckbox) {
          // Create the checkbox if it doesn't exist
            naCheckbox = document.createElement('div');
            naCheckbox.classList.add('na-checkbox-container');
            sliderElement.parentElement.appendChild(naCheckbox);
            sliderElement.parentElement.setAttribute('data-na-checkbox-added', 'true');
          }

          // Update styling based on language because for German, Slider labels are very long
          naCheckbox.classList.remove('mt-4', 'pt-8', 'md:pt-3', 'pt-2', 'pb-2', 'block');
          if (selectedLanguage === 'de') {
            naCheckbox.classList.add('mt-4', 'pt-8', 'md:pt-3', 'pb-2', 'block');
          } else {
            naCheckbox.classList.add('mt-4', 'pt-2', 'pb-2', 'block');
          }

          // Update or set the checkbox HTML
          naCheckbox.innerHTML = `
          <label class="items-center mt-1 space-x-2 cursor-pointer -ml-3">
            <input type="checkbox" class="form-checkbox h-5 w-5 text-rust-600 rounded focus:ring-2 focus:ring-offset-2 focus:ring-rust-500" />
            <span class="text-gray-700 select-none text-sm">${naText}</span>
          </label>
        `;

          let previousValue = 1;
          const checkbox = naCheckbox.querySelector('input[type="checkbox"]');
          // Check if the question value is already set to 'not applicable'
          if (options.question.value === 'not applicable') {
            checkbox.checked = true;

            setTimeout(() => {
              sliderElement.noUiSlider.set(previousValue);
            }, 10);

            sliderElement.setAttribute('disabled', true);
            noUiSliderInstance.updateOptions({
              tooltips: [false],
            });
          }

          // Remove any existing event listeners to avoid duplicates (optional, if needed)
          const newCheckbox = checkbox.cloneNode(true);
          checkbox.replaceWith(newCheckbox);

          // Add event listener to the new checkbox
          newCheckbox.addEventListener('change', () => {
            if (newCheckbox.checked) {
              previousValue = sliderElement.noUiSlider.get();
              sliderElement.setAttribute('disabled', true);
              // eslint-disable-next-line no-param-reassign
              options.question.value = 'not applicable';
              noUiSliderInstance.updateOptions({
                tooltips: [false],
              });
            } else {
              sliderElement.removeAttribute('disabled');
              // eslint-disable-next-line no-param-reassign
              options.question.value = previousValue;
              noUiSliderInstance.updateOptions({
                tooltips: [true],
              });
            }
          });
        }
      }
    }
  };

  const handleComplete = async sender => {
    const surveyResponses = sender.data;
    await completeSurvey(sender, surveyResponses);
  };

  const customCss = {
    navigation: {
      complete: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 rounded-full cursor-pointer group active:ring-4 border-slate-400 disabled:pointer-events-none text-white shadow-sm bg-charcoal-900 border-0 active:ring-charcoal-50 hover:bg-charcoal-600 active:bg-charcoal-600 w-full mr-auto',
      prev: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 bg-white rounded-full cursor-pointer group active:ring-4 disabled:pointer-events-none text-charcoal-800 border-1 border-charcoal-500 active:ring-charcoal-50 hover:bg-charcoal-50 active:bg-charcoal-25',
      next: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 rounded-full cursor-pointer group active:ring-4 border-slate-400 disabled:pointer-events-none text-white shadow-sm bg-charcoal-900 border-0 active:ring-charcoal-50 hover:bg-charcoal-600 active:bg-charcoal-600 w-full mr-auto',
    },
  };

  useEffect(() => {
    if (surveyData && surveyData.responses) {
      survey.mergeData(surveyData.responses);
    }

    if (surveyData && surveyData.currentSurveyPage) {
      survey.currentPageNo = surveyData.currentSurveyPage;
    }

    survey.applyTheme(theme);
    survey.css = customCss;

    // Add event listeners
    survey.onAfterRenderPage.add(handleAfterRenderPage);
    survey.onValueChanging.add(handleValueChanging);
    survey.onCurrentPageChanging.add(handlePageChanging);
    survey.onCurrentPageChanged.add(handlePageChanged);
    survey.onAfterRenderQuestion.add(handleAfterRenderQuestion);
    survey.onComplete.add(handleComplete);

    // Clean up event listeners on unmount
    return () => {
      survey.onAfterRenderPage.remove(handleAfterRenderPage);
      survey.onValueChanging.remove(handleValueChanging);
      survey.onCurrentPageChanging.remove(handlePageChanging);
      survey.onCurrentPageChanged.remove(handlePageChanged);
      survey.onAfterRenderQuestion.remove(handleAfterRenderQuestion);
      survey.onComplete.remove(handleComplete);
    };
  }, [surveyStarted, survey]);

  // To resolve conditional rendering issue
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    survey.locale = selectedLanguage;
    survey.getAllQuestions().forEach(question => {
      const questionElement = document.querySelector(`#${question.id}`);
      if (questionElement) {
        handleAfterRenderQuestion(null, {
          question,
          htmlElement: questionElement,
        });
      }
    });

    if (selectedLanguage !== defaultLanguage && surveyStarted) {
      const updateSurveyLanguage = async () => {
        await setSurveyLanguage({ sender: survey, language: getLanguageName(selectedLanguage) });
        setDefaultLanguage(selectedLanguage);
      };
      updateSurveyLanguage();
    }
  }, [selectedLanguage]);

  // This prevents any client-specific content from rendering until after the initial mount
  if (!isMounted) {
    return null; // Alternatively, a fallback like a loading spinner
  }

  const completedSurveyMessage = `You've already completed the IBI 360 survey for ${surveyData?.groupMemberName}. Please click <a href="/dashboard">here</a> to go to the dashboard`;

  return (
    <div className="flex flex-col justify-between">
      <section className="flex flex-col flex-grow w-full h-full overflow-hidden bg-ivory-400">
        <Helmet>
          <title>{pageTitle} | Aperian</title>
        </Helmet>
        <SiteHeader pageTitle={pageTitle} location={location} />
        {surveyData?.surveyComplete ? (
          <div className="layout-max-width">
            <Notification type="success" message={completedSurveyMessage} />
          </div>
        ) : (
          <>
            <div className="my-8 bg-ivory-400">
              <h2 className="mb-0 leading-none text-center">You are rating {surveyData?.groupMemberName}</h2>
            </div>
            <div className="flex items-center justify-between w-full px-2 text-white top-16 bg-charcoal-900 h-9">
              <div /> {/* Placeholder div for alignment */}
              <div className="flex items-center">
                <Icon icon="globe" iconColor="primary" className="w-5 h-5 fill-white" />
                <select
                  id="language"
                  className="w-[5.25rem] p-1 text-sm font-bold text-white border-none bg-charcoal-900"
                  value={selectedLanguage}
                  onChange={e => setSelectedLanguage(e.target.value)}
                >
                  {languageOptions.map(lang => (
                    <option key={lang.code} value={lang.code}>
                      {lang.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col min-h-screen">
              <Survey model={survey} />
            </div>
          </>
        )}

      </section>
      <Footer />
    </div>
  );
};

AssessmentRaterSurvey.getAPIDataKey = () => 'asmtSurveyData';
AssessmentRaterSurvey.getData = (apiService, { raterId }) =>
  apiService.get(`assessments/ibi/survey-url?isRater=true&raterId=${raterId}`)
    .then(data => ({ asmtSurveyData: data }));

AssessmentRaterSurvey.propTypes = {
  params: PropTypes.shape({
    raterId: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    displayName: PropTypes.string,
    assessment: PropTypes.string,
    surveyJson: PropTypes.shape({
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
          elements: PropTypes.arrayOf(
            PropTypes.shape({
              html: PropTypes.objectOf(PropTypes.string),
              name: PropTypes.string,
              type: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
    surveyData: PropTypes.shape({
      surveyResponseId: PropTypes.number,
      currentSurveyPage: PropTypes.number,
      state: PropTypes.string,
      responses: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
      ),
      languagePreference: PropTypes.string,
      groupMemberName: PropTypes.string,
      surveyComplete: PropTypes.bool,
    }),
  }).isRequired,
};

export default withServerSideData(AssessmentRaterSurvey);
