import React from 'react';
import SlickSlider from 'react-slick';
import PropTypes from 'prop-types';

import { sliderSettings } from './settings';

export const Slider = ({ children }) => (
  <SlickSlider {...sliderSettings}>
    {children}
  </SlickSlider>
);

Slider.propTypes = {
  children: PropTypes.node.isRequired,
};
