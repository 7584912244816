import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../../common/Avatars/Avatar';
import LabelInput from '../../../common/LabelInput';
import Button from '../../../common/Button';

const CreateInitials = ({ handleClose, defaultInitials, handleSaveInitials }) => {
  const [initials, setInitials] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = event => {
    const { value } = event.target;
    const regex = /^[A-Za-z]*$/;

    if (regex.test(value)) {
      setError(null);
      setInitials(value.toUpperCase());
    } else if (value === '') {
      setInitials(null);
    }
  };

  const handleSave = e => {
    e.preventDefault();
    if (!initials) {
      setError('Please enter at least 1 initial');
      return;
    }
    handleSaveInitials({ initials });
  };

  return (
    <>
      <h3 className="mb-4">Edit Your Initials Avatar</h3>
      <div className="flex items-center gap-3 text-left">
        <Avatar
          avatarUrl={`initials-${initials || defaultInitials}`}
          avatarImgAlt={defaultInitials}
          className="min-w-[7rem] min-h-[7rem] m-auto mx-1"
          size="xl"
        />
        <div className="flex-grow">
          <LabelInput
            id="initials"
            name="initials"
            labelText="Initials"
            labelType="text"
            value={initials}
            maxLength={4}
            isRequired
            onChangeValue={handleChange}
            helperText="Enter up to 4 Initials"
            errorMessage={error}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2">
        <Button
          onClick={handleClose}
          isWarning
        >Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="primary"
        >Create
        </Button>
      </div>
    </>
  );
};

CreateInitials.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSaveInitials: PropTypes.func.isRequired,
  defaultInitials: PropTypes.string.isRequired,
};

export default CreateInitials;
