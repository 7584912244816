import React from 'react';
import { withCookies } from 'react-cookie';
import qs from 'query-string';
import { format } from 'date-fns';

import { propTypes } from './types';

import { Context } from '../../../../context/ContextProvider';

import CompleteRegistrationAction from '../../../../actions/completeRegistration';
import afterLoginUpdateAction from '../../../../actions/user/profile';

import Auth from '../../../../lib/auth/auth0/auth';

import logger from '../../../../lib/logger';
import { getRedirectUrl } from '../../../../lib/auth/authRedirect';
import { clientSuccessTrack } from '../../../../lib/clientSuccess';
import { trackRegistrationFailure } from '../../../../lib/tracker/registration';

import { tracks } from '../../../../pages/Onboarding/tracks';

import {
  COOKIE_REDIRECT,
  COOKIE_REGISTERED,
  IS_PRODUCTION,
} from '../../../../lib/constants';

const completeRegistration = (user, api) => {
  const registeredUser = { ...user, registrationStatus: 'complete' };
  if (user.externalAuth) {
    // Externally authorized users cannot edit name fields
    registeredUser.firstName = undefined;
    registeredUser.lastName = undefined;
  }
  return new CompleteRegistrationAction(api)
    .execute(registeredUser) // registration tracking happens in API
    .catch(err => trackRegistrationFailure(err));
};

const requiresHardReload = targetUrl => {
  const nonReactUrlIdentifiers = [
    'guides',
    'culture-guides',
    'gslearning.aperianglobal.com',
    'gslearning-stage.aperianglobal.com',
    'resources.aperian.com',
    'resources.aperianglobal.com',
    '/samlp/',
  ];
  return nonReactUrlIdentifiers.some(url => targetUrl.includes(url));
};

async function setUserProfile({
  state,
  router,
  apiService,
  token,
  cookies,
  isHubSpotTrial,
}) {
  try {
    const connectionId = token?.sub.startsWith('samlp') ? token.sub.split('|')[1] : null;
    const metaDataKey = Object.keys(token).find(key => key.includes('metaData'));
    const metaData = token[metaDataKey] || {};
    const response = await afterLoginUpdateAction(apiService, metaData, connectionId);

    const {
      meta: {
        auth: {
          profile,
          profile: {
            connection,
            email,
            firstName,
            lastName,
            organizations,
            registrationStatus,
            userid,
            hasCompletedSurvey,
            accessLevel,
            createdAt,
            trial,
            isTrial,
          },
        },
      },
    } = response;
    profile.connection = token.sub.split('|').shift();

    window.braze?.changeUser(userid);
    window.braze?.openSession();

    window?.mixpanel?.identify(userid);

    if (registrationStatus === 'complete') {
      window?.Appcues?.track('Login');
      clientSuccessTrack({
        user: profile,
        eventId: 'logins',
      });
    } else {
      window?.Appcues?.track('Sign Up');
      clientSuccessTrack({
        user: profile,
        eventId: 'signups',
      });
      await completeRegistration(profile, apiService);
    }

    const appCuesProperties = {
      firstName,
      lastName,
      email,
      createdAt: format(new Date(createdAt), 'MMMM dd, yyyy'),
      organizationName: organizations.map(org => org.name).join(','),
      organizationId: organizations.map(org => org.id).join(','),
      gspCompleted: hasCompletedSurvey,
      isBasic: (accessLevel.length === 0),
    };

    if (isHubSpotTrial) appCuesProperties.trialUser = true;

    if (isTrial) {
      appCuesProperties.trialUser = true;

      const selectedTrack = tracks.find(track => track.id === trial.trialTrack);
      const trackName = selectedTrack?.name || '';
      appCuesProperties.trialTrack = trackName;
    }

    window?.Appcues?.identify(userid, appCuesProperties);

    const redirectTo = getRedirectUrl(state, cookies, COOKIE_REDIRECT, connection);
    cookies.set(COOKIE_REGISTERED, true, { path: '/', secure: IS_PRODUCTION });

    if (requiresHardReload(redirectTo)) {
      return window.location.assign(redirectTo);
    }
    return router.push(redirectTo);
  } catch (error) {
    return error;
  }
}
class Authenticated extends React.PureComponent {
  static contextType = Context;

  componentDidMount() {
    const { location: { hash } } = this.props;
    const { router, cookies } = this.context;

    // example callback url: /authenticated#id_token=abc&access_token=abc&state=hello
    if (/access_token|id_token|error/.test(hash)) {
      const auth = new Auth();
      const hashParsed = qs.parse(hash);
      const { state } = hashParsed;
      return auth.handleAuthentication(hash, state)
        .then(authResult => {
          console.info('Auth0 authentication parsed:', authResult);
          this.context.currentUser.setAuthToken(authResult.idToken);
          return authResult.idTokenPayload;
        })
        .then(token => setUserProfile({
          state,
          router,
          apiService: this.context.apiService,
          token,
          cookies,
          isHubSpotTrial: hashParsed.hubspottrial,
        }))
        .catch(error => {
          logger.error('Auth0:', error);
          router.push('/error');
        });
    }

    console.info('Authentication request not recognized:', hash);
    router.push('/login');
    return null;
  }

  render() {
    return null;
  }
}

Authenticated.propTypes = propTypes;

export default withCookies(Authenticated);
export {
  Authenticated as Component,
};
