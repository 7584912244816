import PropTypes from 'prop-types';

const membersTypes = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    surveyStatus: PropTypes.string,
    remindedAt: PropTypes.string,
    dateJoined: PropTypes.string,
    accessCode: PropTypes.string,
    completionDate: PropTypes.string,
    avatarUrl: PropTypes.string,
  }),
);

const pendingInvitationsTypes = PropTypes.arrayOf(
  PropTypes.shape({
    email: PropTypes.string.isRequired,
    sentAt: PropTypes.string.isRequired,
  }.isRequired),
);

const ownersTypes = PropTypes.arrayOf(
  PropTypes.shape({
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    avatarUrl: PropTypes.string.isRequired,
  }),
);

const propTypes = {
  id: PropTypes.string,
  isOwner: PropTypes.bool,
  members: membersTypes,
  name: PropTypes.string,
  pendingInvitations: pendingInvitationsTypes,
  owners: ownersTypes,
  totalPendingReminders: PropTypes.number,
};

const defaultProps = {
  id: '',
  name: '',
  isOwner: false,
  totalPendingReminders: 0,
};

export {
  membersTypes,
  pendingInvitationsTypes,
  propTypes,
  defaultProps,
};
