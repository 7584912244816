/* eslint-disable react/function-component-definition */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ModalContext = createContext({});
/*
The ModalProvider allows us global access to display a Modal or Dialog component
anywhere in the application. In future work we can pass both components here at
a higher level rather than in each component that it's being used. See this article
for reference https://dev.to/alexandprivate/your-next-react-modal-with-your-own-usemodal-hook-context-api-3jg7
 */
export default function ModalProvider({ children }) {
  const [modalClassName, setModalClassName] = useState();
  const [modalOnCloseAction, modalSetOnCloseAction] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dialogClassName, setDialogClassName] = useState();
  const [dialogOnCloseAction, setDialogOnCloseAction] = useState(null);
  const [dialogContent, setDialogContent] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenModal = ({ content, className, afterCloseAction }) => {
    if (className) {
      setModalClassName(className);
    }
    if (afterCloseAction) {
      modalSetOnCloseAction(() => afterCloseAction);
    }
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    if (typeof modalOnCloseAction === 'function') { modalOnCloseAction(); }
  };

  const handleOpenDialog = ({ content, dialogSize, afterCloseAction }) => {
    if (dialogSize) {
      setDialogClassName(`Modal--${dialogSize}`);
    } else {
      setDialogClassName('Modal');
    }
    if (afterCloseAction) {
      setDialogOnCloseAction(() => afterCloseAction);
    }
    setDialogContent(content);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    if (typeof dialogOnCloseAction === 'function') { dialogOnCloseAction(); }
  };

  return (
    <ModalContext.Provider
      value={{
        modalClassName,
        modalContent,
        showModal,
        handleOpenModal,
        handleCloseModal,
        dialogClassName,
        dialogContent,
        showDialog,
        handleOpenDialog,
        handleCloseDialog,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ModalConsumer = ModalContext.Consumer;
