import React, { useRef, useReducer } from 'react';
import PropTypes from 'prop-types';

import DragAndDrop from '../../../DragAndDrop';
import Button from '../../../common/Button';
import UploadAvatarEditor from './UploadAvatarEditor';

const UploadAvatar = ({ handleClose, handleEditedAvatar, headerText }) => {
  const canvasRef = useRef(null);
  const initialData = { imageData: null, error: null, isDisabled: false };

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), initialData);

  const ResetImage = () => setState(initialData);

  const handleError = error => setState({ ...initialData, error });

  const handleImageUpload = newFile => {
    try {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(newFile);
      fileReader.onloadend = () => {
        const image = new Image();
        image.src = fileReader.result;
        image.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = image.width;
          canvas.height = image.height;
          const context = canvas.getContext('2d');
          context.drawImage(image, 0, 0, image.width, image.height);
          const dataUrl = canvas.toDataURL('image/png');
          setState({ error: null, imageData: dataUrl });
        };
        image.onerror = () => handleError('Image uploaded is invalid or corrupted');
      };
    } catch (err) {
      handleError('Image uploaded is invalid or corrupted');
    }
  };

  const handleSave = e => {
    e.preventDefault();
    setState({ isDisabled: true });
    const canvas = canvasRef?.current?.getImageScaledToCanvas();
    if (canvas) {
      handleEditedAvatar(canvas);
      setState({ isDisabled: false });
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <h3 className="mb-4">{headerText}</h3>
      {state.imageData
        ? <UploadAvatarEditor ref={canvasRef} image={state.imageData} />
        : (
          <DragAndDrop
            displayText="Drag and drop your image here or"
            handleFileUpload={handleImageUpload}
            errorText={state.error}
            handleError={handleError}
          />
        )}
      <div className="flex justify-end gap-3 mt-4">
        {state.imageData
          ? (
            <>
              <Button
                onClick={ResetImage}
                isWarning
              >Back
              </Button>
              <Button
                onClick={handleSave}
                variant="primary"
                disabled={state.isDisabled}
              >Upload
              </Button>
            </>
          ) : (
            <Button
              onClick={handleClose}
              isWarning
              className="ml-auto"
            >Cancel
            </Button>
          )}
      </div>
    </div>
  );
};

UploadAvatar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleEditedAvatar: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default UploadAvatar;
