import React, { Component, createContext } from 'react';
import { Cookies, withCookies } from 'react-cookie';
import { omit } from 'lodash';
import PropTypes from 'prop-types';
import DOMPurify from 'isomorphic-dompurify';
import { RouterContext } from '../Router';

import {
  ANONYMOUS_USER,
  COOKIE_AUTH,
  COOKIE_CONNECTION,
  COOKIE_PROFILE,
  COOKIE_REDIRECT,
  IS_PRODUCTION,
  MAX_AGE_IN_SECONDS_FOR_COOKIE_AUTH,
} from '../../lib/constants';

import Auth from '../../lib/auth/auth0/auth';
import verifyJWT from '../../lib/auth/jwt';
import logger from '../../lib/logger';

export const CurrentUserContext = createContext();

/*
 * oAuthSub format: authType|connection|userid
 * For example an SSO enterprise user from okta using saml2
 * would look like:
 * samlp|okta|123-abc-456...
 *
 * Contrast that with an internally authenticated user:
 * auth0|123-abc-456...
 */
function externalAuthConnection(oAuthSubClaim) {
  if (!oAuthSubClaim) { return null; }
  const parts = oAuthSubClaim.split('|');
  parts.pop(); // remove userid
  const connection = parts.pop();
  return connection;
}

class CurrentUserProvider extends Component {
  static contextType = RouterContext;

  // Remove claim URL from JWT object keys added by Auth0, if they exist
  static unprefixKeys(user) {
    return Object.keys(user).reduce((ob, key) => {
      const unprefixedKey = key.split('/').pop();
      return { ...ob, [unprefixedKey]: user[key] };
    }, {});
  }

  static cleanJWT(decodedJWT) {
    const blackList = ['exp', 'iat', 'type', 'state'];
    const cleanedJWT = omit(decodedJWT, blackList);
    return CurrentUserProvider.unprefixKeys(cleanedJWT);
  }

  constructor(props, context) {
    super(props);
    this.props = props;
    this.context = context;
    this.auth = new Auth();
    this.initialProfile = props.cookies.get(COOKIE_PROFILE) || ANONYMOUS_USER;
    this.token = props.cookies.get(COOKIE_AUTH);
    this.connection = props.cookies.get(COOKIE_CONNECTION);
    this.state = {
      currentUser: {
        ...this.initialProfile,
        token: this.token,
        connection: this.connection,
        setAuthToken: this.setAuthToken,
        refreshAuthToken: this.refreshAuthToken,
        isLoggedIn: this.isLoggedIn,
        setCurrentUser: this.setCurrentUser,
      },
    };
    this.isComponentMounted = false;
    this.cookies = props.cookies;
    this.verifyJWT = verifyJWT;
  }

  componentDidMount() {
    this.setCurrentUser();
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  redirectToLogout = () => {
    const { router: { location: { pathname, search } }, router } = this.context;
    if (!this.cookies.get(COOKIE_REDIRECT) && pathname !== '/authenticated' && pathname !== '/promo') {
      this.cookies.set(
        COOKIE_REDIRECT,
        DOMPurify.sanitize(`${pathname}${search}`),
        { path: '/', maxAge: 300, secure: IS_PRODUCTION },
      );
    }
    router.push('/logout');
  };

  setCurrentUser = (profile = this.cookies.get(COOKIE_PROFILE)) => {
    const { currentUser } = this.state;
    if (currentUser.token) {
      const verifiedAuth = this.checkIfTokenNeedsRefresh(currentUser.token);
      if (!verifiedAuth) this.redirectToLogout();
      if (verifiedAuth) {
        const cleanedJWT = CurrentUserProvider.cleanJWT(verifiedAuth);
        const externalConnection = externalAuthConnection(cleanedJWT.sub);
        const loggedInUser = {
          ...currentUser,
          ...profile,
          connection: externalConnection,
          authenticated: !!this.token,
        };
        this.cookies.set(
          COOKIE_PROFILE,
          profile,
          { path: '/', secure: IS_PRODUCTION },
        );
        this.cookies.set(
          COOKIE_CONNECTION,
          externalConnection,
          { path: '/', secure: IS_PRODUCTION },
        );
        this.setState({ currentUser: loggedInUser });
      }
    }
  };

  isLoggedIn() {
    // authenticated is defined by the presence of an Auth0 token
    return !!this.token;
  }

  setAuthToken = token => {
    this.cookies.set(
      COOKIE_AUTH,
      token,
      {
        path: '/',
        maxAge: MAX_AGE_IN_SECONDS_FOR_COOKIE_AUTH,
        secure: IS_PRODUCTION,
      },
    );
    const { currentUser } = this.state;
    this.setState({ currentUser: { ...currentUser, token } });
  };

  checkIfTokenNeedsRefresh = (token = this.cookies.get(COOKIE_AUTH)) => {
    if (token) {
      const verifiedJWT = this.verifyJWT({
        token,
        onError: this.redirectToLogout,
        isAuth0: true,
      });
      if (verifiedJWT) {
        // For calculating if the Auth0 token is expired
        const auth0TokenExpiration = new Date(verifiedJWT.exp * 1000);
        const now = new Date();
        const fiveMinutes = 1 * 60 * 1000;
        if (auth0TokenExpiration - now < fiveMinutes) {
          this.refreshAuthToken();
        }
        return verifiedJWT;
      }
    }
    return null;
  };

  refreshAuthToken = () => {
    if (this.isComponentMounted) {
      this.auth
        .refresh()
        .then(authResult => authResult.idToken)
        .then(token => this.setAuthToken(token))
        .catch(error => {
          if (error === 'login_required') {
            this.context.router.push('/logout');
          } else {
            logger.error(error);
            this.context.router.push('/error');
          }
        });
    }
  };

  render() {
    const { currentUser } = this.state;
    const { children, cookies } = this.props;
    return (
      <CurrentUserContext.Provider
        value={{
          currentUser,
          cookies,
        }}
      >
        {children}
      </CurrentUserContext.Provider>
    );
  }
}

CurrentUserProvider.propTypes = {
  children: PropTypes.node.isRequired,
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withCookies(CurrentUserProvider);

export { CurrentUserProvider as Component };

export const CurrentUserConsumer = CurrentUserContext.Consumer;
