import React, { Component } from 'react';

import FullScreen from '../../FullScreen';
import { propTypes } from './types';
import { HiddenProfilesButton } from '../Buttons';
import ProfileGroup from '../../ProfileCards/ProfileGroup';
import { byName } from '../../../lib/profile-utils';

class HiddenPeopleOverlay extends Component {
  static cardsFromProfiles(profiles) {
    return profiles
      .filter(profile => !profile.isMe)
      .sort(byName)
      .map(profile => ({
        id: profile.id,
        firstName: profile.firstName,
        lastName: profile.lastName,
        shortDisplayName: profile.type === 'segment' ? `${profile.shortDisplayName}: ${profile.segmentValue}` : profile.shortDisplayName,
        displayName:
          profile.firstName && profile.lastName
            ? `${profile.firstName} ${profile.lastName}`
            : null,
        avatarUrl: profile.avatarUrl,
        isComparisonItem: true,
        isVisible: profile.isVisible,
        type: profile.type,
        values: profile.values ? profile.values : null,
      }));
  }

  constructor(props) {
    super(props);

    this.state = {
      maxAvatars: props.maxAvatars,
      numHidden: props.numHidden,
    };

    this.state.cards = HiddenPeopleOverlay.cardsFromProfiles(
      this.props.profiles,
    );
    this.state.takeoverOptions = this.buildTakeoverOptions(this.state);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      cards: HiddenPeopleOverlay.cardsFromProfiles(nextProps.profiles),
    });

    this.setState({
      maxAvatars: nextProps.maxAvatars,
      numHidden: nextProps.numHidden,
      tooManyComparisonObjects: nextProps.tooManyComparisonObjects,
      takeoverOptions: this.buildTakeoverOptions(nextProps),
    });
  }

  onCancel = () => {
    this.setState({
      cards: HiddenPeopleOverlay.cardsFromProfiles(this.props.profiles),
    });
  };

  onUpdate = () => this.props.onUpdate(this.state.cards);

  updateList = ({ id }) => {
    const { cards } = this.state;
    const newList = cards.map(item => {
      if (item.id.toString() === id.toString()) {
        item.isVisible = !item.isVisible; // eslint-disable-line no-param-reassign
      }
      return item;
    });
    this.setState({ cards: newList });
  };

  buildTakeoverOptions(source) {
    return {
      takeoverTrigger: <HiddenProfilesButton numHidden={source.numHidden} />,
      takeoverTitle: `${source.maxAvatars} Profiles Visible`,
      cancelText: 'Cancel',
      cancel: {
        isCancel: true,
        onClick: this.onCancel,
        className: 'mr-0.5',
      },
      confirmText: 'Update',
      confirmOptions: {
        filledColor: 'green',
        onClick: this.onUpdate,
      },
    };
  }

  errorMessage() {
    if (!this.state.tooManyComparisonObjects) return null;
    return (
      <div className="mt-0 mb-1 notification-message notification-message--warning">
        <div className="notification-message__text">
          Your current screen size can accommodate {this.state.maxAvatars}{' '}
          comparisons at once. Please select no more than{' '}
          {this.state.maxAvatars - 1} and continue.
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        key="HiddenProfiles"
        className="border-none avatar-container__item js-hiddenProfilesButton"
      >
        <FullScreen
          {...this.state.takeoverOptions}
          headerContent={this.errorMessage()}
        >
          <div className="max-w-2xl m-auto">
            <h3>
              {this.state.maxAvatars - 1} Included in Comparison
            </h3>
            <p>
              Your screen size can accommodate only showing{' '}
              {this.state.maxAvatars - 1} of the {this.props.profiles.length - 1}&nbsp;
              profiles you selected to compare at once.
            </p>
          </div>
          <div className="max-w-3xl m-auto">
            <div className="profile-groups">
              <ProfileGroup
                groupName="Profiles to Show in Comparison"
                cards={this.state.cards}
                onChange={this.updateList}
                isGrid
              />
            </div>
          </div>
        </FullScreen>
      </div>
    );
  }
}

HiddenPeopleOverlay.propTypes = propTypes;

export default HiddenPeopleOverlay;
