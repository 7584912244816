import React, { useContext } from 'react';

import { APIContext } from '../../context/API';

import PageHeader from '../../components/PageHeader';
import CopilotChatbot from '../../components/CopilotChatbot';

import { trackCopyCopilotResponse, trackSubmitCopilotChat } from '../../lib/tracker/copilot';

const CopilotBeta = () => {
  const { apiService } = useContext(APIContext);

  const handleSubmit = async questionText => {
    await trackSubmitCopilotChat(apiService, questionText);
  };

  const handleCopy = () => {
    trackCopyCopilotResponse(apiService);
  };

  const defaultMessage = (
    <p className="px-4 text-base md:px-8">
      Get answers to your global business questions quickly,
      easily, and in any language!
      The <b>Aperian Copilot</b>, our AI&#8209;powered cultural expert,
      is now available. Ask about any of
      the <a href="https://aperian.zendesk.com/hc/en-us/articles/24341543840915-GlobeSmart-Guide-Cultures-List" target="_blank" rel="noopener noreferrer">100+ cultures</a> currently available in our GlobeSmart Guides
      by typing a question below.
    </p>
  );

  return (
    <>
      <PageHeader
        pageTitle="Aperian Copilot"
        icon="copilot"
        skipTarget="#copilot"
      />
      <section className="flex flex-col py-4 md:p-6">
        <p className="mb-2 text-base italic font-bold text-center">
          We want to get your feedback, so please share your thoughts <a href="https://form.asana.com/?k=wVsgVMC_3PxSQyh96JF_JQ&d=87210769388827" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
        <CopilotChatbot
          defaultMessage={defaultMessage}
          onSubmit={handleSubmit}
          onCopy={handleCopy}
        />
      </section>

      <span className="mb-4 text-sm text-center">
        Please remember that use of Copilot is covered by our&nbsp;
        <a
          href="https://app.aperian.com/content/terms-of-use"
          target="_blank"
          rel="noopener noreferrer"
        >Terms of Use
        </a> and our&nbsp;
        <a
          href="https://app.aperian.com/content/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >Privacy Policy
        </a>
        .&nbsp;
        As Copilot is an AI tool, do not submit any confidential information
        in your questions and be sure to assess and verify the responses
        using your own best judgement.
      </span>
    </>
  );
};

export default CopilotBeta;
