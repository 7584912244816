/* eslint-disable new-cap */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import Icon from '../../../common/Icon';

const TipCard = ({
  tips,
  currentIndex,
  hasPremiumAccess,
  trackClickLink,
  children,
}) => (
  <div className="flex flex-col items-center justify-end col-start-1">
    <div className="w-full h-auto p-5 bg-white 3xl:max-w-lg sm:p-6 rounded-xl">
      <div className="relative flex items-center justify-start gap-2 px-2 pb-4 mb-4 border-b-2 border-b-stone-400">
        <h3 className="m-0 font-serif text-xl font-semibold leading-snug tracking-wider md:text-2xl text-charcoal-900" id="slide-over-title">
          Stay Curious
        </h3>
        <div className="absolute w-16 h-16 rounded-full top-2 right-2 md:w-20 md:h-20 bg-ivory-400">
          <Icon
            icon="stay-curious"
            iconColor="black"
            className="w-12 h-12 mx-auto my-2 md:w-16 md:h-16"
          />
        </div>
      </div>
      {children}
      <div className="flex justify-end">
        {hasPremiumAccess ? (
          <a
            href={`${tips[currentIndex].guidesLink}${tips[currentIndex].tipLink}`}
            onClick={() => trackClickLink(tips[currentIndex])}
          >
            Learn more
          </a>
        ) : (<Link to="/dashboard?purchase&plan=starter-6mopaid">Learn more</Link>)}
      </div>
    </div>
  </div>
);

TipCard.propTypes = {
  children: PropTypes.node.isRequired,
  currentIndex: PropTypes.number.isRequired,
  tips: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      tipLink: PropTypes.string,
      flagIcon: PropTypes.string,
      guidesLink: PropTypes.string,
      tipType: PropTypes.string,
    }),
  ).isRequired,
  hasPremiumAccess: PropTypes.bool.isRequired,
  trackClickLink: PropTypes.func,
};

TipCard.defaultProps = {
  trackClickLink: () => { },
};

export default TipCard;
