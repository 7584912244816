import React from 'react';
import { withCookies } from 'react-cookie';
import DOMPurify from 'isomorphic-dompurify';

import { propTypes } from './types';

import { Context } from '../../../../context/ContextProvider';

import Auth from '../../../../lib/auth/auth0/auth';
import {
  COOKIE_REDIRECT,
  COOKIE_NOTIFICATION,
  COOKIE_REGISTERED,
  IS_PRODUCTION,
} from '../../../../lib/constants';

class Login extends React.PureComponent {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.redirectTo = props.location.query.redirect_to || props.location.query.RelayState || false;
  }

  componentDidMount() {
    const loginOptions = {
      connection: this.props.location.query.sp || null,
    };
    const notification = this.cookies.get(COOKIE_NOTIFICATION);
    const isRegistered = this.cookies.get(COOKIE_REGISTERED);

    if (notification) {
      loginOptions.notification = notification.message;
      loginOptions.notificationType = notification.type === 'success' ? 'success' : 'error';
      this.cookies.remove(COOKIE_NOTIFICATION, { path: '/' });
    }

    if (!isRegistered) {
      loginOptions.initialScreen = 'signUp';
    }

    // If a redirect URI is passed in, set it as a cookie to be used after Auth0 returns to our app
    if (this.redirectTo) {
      this.cookies.set(
        COOKIE_REDIRECT,
        DOMPurify.sanitize(this.redirectTo),
        { path: '/', maxAge: 300, secure: IS_PRODUCTION },
      );
    }

    const auth = new Auth();
    auth.login(loginOptions);
  }

  render() {
    return null;
  }
}

Login.propTypes = propTypes;

export default withCookies(Login);
