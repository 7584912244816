/* eslint-disable react/prop-types */
import React from 'react';

const SegmentAvatar = ({ className }) => (
  <svg className={className} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
    <rect x="2" y="2" width="64" height="64" rx="32" fill="#FFE3E0" />
    <circle fill="#FFE3E0" stroke="#FFC9C2" strokeMiterlimit="10" strokeWidth="4" cx="34" cy="34" r="33" />
    <g>
      <circle fill="none" stroke="#D45251" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" cx="39.27" cy="14.25" r="5.74" />
      <path fill="none" stroke="#D45251" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21.14,29.44c0,3.17-2.57,5.74-5.74,5.74s-5.74-2.57-5.74-5.74,2.57-5.74,5.74-5.74,5.74,2.57,5.74,5.74Z" />
      <path fill="none" stroke="#D45251" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M53.85,45.83c0,5.79-4.7,10.49-10.49,10.49s-10.49-4.7-10.49-10.49,4.7-10.49,10.49-10.49,10.49,4.7,10.49,10.49Z" />
      <line fill="none" stroke="#D45251" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="20.37" y1="26.49" x2="34.64" y2="17.47" />
      <line fill="none" stroke="#D45251" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="20.39" y1="32.56" x2="34.68" y2="41.38" />
    </g>
    <rect x="2" y="2" width="64" height="64" rx="32" stroke="#FFC9C2" strokeWidth="2" fill="none" />
  </svg>
);

export default SegmentAvatar;
