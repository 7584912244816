import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../../context/API';

import withServerSideData from '../../../../HOC/withServerSideData';

import LabelInput from '../../../common/LabelInput';
import EditableField from '../../../EditableField';
import Button from '../../../common/Button';
import OrganizationDomainList from './OrganizationDomainList';
import Notification from '../../../Notifications/Notification';

import { getErrorMessage } from '../../RenderNotification';
import OrganizationAddDomainAction from '../../../../actions/organizations/organizationAddDomain';
import OrganizationDeleteDomainAction from '../../../../actions/organizations/organizationDeleteDomain';

const OrganizationAuthorizations = ({
  params: { id: orgId },
  auth0ConnectionId,
  contentControllerAccountId,
  onUpdate,
  initialData,
}) => {
  const { apiService } = useContext(APIContext);

  const [state, setState] = useReducer((previousState, newState) =>
    ({ ...previousState, ...newState }), { domains: initialData.domains });
  const [notification, setNotification] = useState(null);

  const handleOnChange = ({ target: { name, value } }) => setState({ [name]: value });

  useEffect(() => {
    let timeOut;
    if (notification) timeOut = setTimeout(() => setNotification(null), 3000);
    return () => clearTimeout(timeOut);
  }, [notification]);

  async function onAddDomain(e) {
    e.preventDefault();
    try {
      const { domain } = await new OrganizationAddDomainAction(apiService)
        .execute(orgId, state.domain);
      setState({ domains: [...state.domains, domain], domain: '' });
      setNotification({ type: 'confirm', message: 'Domain added.' });
    } catch (err) {
      setNotification({ type: 'warning', message: `Error adding domain: ${getErrorMessage(err)}` });
    }
  }

  async function onDeleteDomain(e) {
    e.preventDefault();
    const { dataset: { id } } = e.target;
    const domainId = id.replace(/domain-/, '');
    const intDomainId = parseInt(domainId, 10);
    try {
      await new OrganizationDeleteDomainAction(apiService).execute(orgId, domainId);
      const filteredDomains = state.domains.filter(domain => domain.id !== intDomainId);
      setState({ domains: filteredDomains });
      setNotification({ type: 'confirm', message: 'Domain deleted.' });
    } catch (err) {
      const error = err;
      if (err.status === 404) error.error = 'Domain not found';
      setNotification({ type: 'warning', message: `Error deleting domain: ${getErrorMessage(err)}` });
    }
  }
  return (
    <>
      {notification ? <Notification {...notification} /> : null}
      <div className="grid gap-2 mt-6 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Connection ID</h5>
        </div>
        <div className="flex flex-col md:col-span-4">
          <EditableField
            name="auth0ConnectionId"
            label="Connection ID"
            value={auth0ConnectionId}
            onUpdate={onUpdate}
            maxLength={50}
            helperText="Enter the organization&apos;s Auth0 Connection Id."
          />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Organization Domains</h5>
        </div>
        <div className="flex flex-col md:col-span-4">
          <form onSubmit={onAddDomain}>
            <LabelInput
              id="domain"
              name="domain"
              labelText="Domain Value"
              labelType="text"
              value={state.domain}
              maxLength={255}
              isRequired
              onChangeValue={handleOnChange}
              helperText="Enter any domains for which new users will be added to this organization."
            />
            <div className="flex justify-end">
              <Button isSmall filledColor="secondary" type="submit">Add</Button>
            </div>
          </form>
          <div className="overflow-x-auto">
            <OrganizationDomainList
              domains={state.domains}
              onDelete={onDeleteDomain}
            />
          </div>
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 mb-8 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Content Controller Account ID</h5>
        </div>
        <div className="flex flex-col md:col-span-4">
          <EditableField
            name="contentControllerAccountId"
            label="Content Controller Account Id"
            value={contentControllerAccountId}
            onUpdate={onUpdate}
            maxLength={25}
            helperText="Enter the organization&apos;s Content Controller Account Id."
          />
        </div>
      </div>
    </>
  );
};

OrganizationAuthorizations.getAPIDataKey = () => 'domains';

OrganizationAuthorizations.getData = (apiService, { id }) =>
  apiService.get(`organizations/${id}/domains`).then(data => ({ domains: data }));

OrganizationAuthorizations.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  initialData: PropTypes.shape({
    domains: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        domain: PropTypes.string,
      }),
    ),
  }).isRequired,
  auth0ConnectionId: PropTypes.string,
  contentControllerAccountId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onUpdate: PropTypes.func,
};

OrganizationAuthorizations.defaultProps = {
  auth0ConnectionId: null,
  contentControllerAccountId: null,
  onUpdate: PropTypes.func,
};

export default withServerSideData(OrganizationAuthorizations);
