import React, { useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';

import SurveyQuestion from '../../../Survey/SurveyQuestion';
import SurveyQuestionNumber from '../../../Survey/SurveyQuestionNumber';

import { trackCompleteOnboardingStep } from '../../../../lib/tracker/onboarding';

const MiniSurvey = ({ nextStep, questions, handleUpdateForm }) => {
  const [survey, setSurvey] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    questions,
    currentQuestionIndex: 0,
    reload: false,
  });

  useEffect(() => {
    let timeout;
    if (survey.reload) {
      timeout = setTimeout(() => {
        setSurvey({ reload: false });
      }, 100);
    }

    return () => clearTimeout(timeout);
  }, [survey.reload]);

  useEffect(() => {
    if (survey.questions.length && survey.questions.every(q => q.response)) {
      handleUpdateForm({ responses: survey.questions });
      nextStep();
    }
  }, [survey.currentQuestionIndex]);

  const handleOnAnswer = async response => {
    const newQuestions = cloneDeep(survey.questions);
    newQuestions[survey.currentQuestionIndex].response = response;

    await trackCompleteOnboardingStep(`DI${survey.currentQuestionIndex + 1}`);
    setSurvey({
      questions: newQuestions,
      currentQuestionIndex: survey.currentQuestionIndex + 1,
      reload: true,
    });
  };

  const handlePrevQuestion = () =>
    setSurvey({ currentQuestionIndex: survey.currentQuestionIndex - 1, reload: true });

  const isLast = survey.currentQuestionIndex + 1 === survey.questions.length;

  return (!survey.reload && survey.questions[survey.currentQuestionIndex])
    ? (
      <div className="text-center fade-in md:max-w-3xl lg:w-11/12 lg:max-w-5xl">
        <SurveyQuestionNumber
          currentQuestionNumber={survey.currentQuestionIndex + 1}
          totalQuestionCount={questions.length}
        />
        <SurveyQuestion
          currentQuestionNumber={survey.currentQuestionIndex + 1}
          questionId={survey.questions[survey.currentQuestionIndex].id}
          questionText={survey.questions[survey.currentQuestionIndex].text}
          previousAnswer={survey.questions[survey.currentQuestionIndex].response}
          onBack={handlePrevQuestion}
          onAnswer={handleOnAnswer}
          isLast={isLast}
          isOnboarding
          onLanguageChange={() => null}
        />
      </div>
    ) : null;
};

MiniSurvey.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      text: PropTypes.string,
    }),
  ).isRequired,
  nextStep: PropTypes.func.isRequired,
  handleUpdateForm: PropTypes.func.isRequired,
};

export default MiniSurvey;
