import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import TipsForm from './TipsForm';
import GlobeSmartModal from '../../../GlobeSmartModal';
import withServerSideData from '../../../../HOC/withServerSideData';
import { APIContext } from '../../../../context/API';
import TipConfirmModal from './TipsConfirmModal';
import { ModalContext } from '../../../../context/Modal';
import { RouterContext } from '../../../../context/Router';
import { NotificationContext } from '../../../../context/Notification';
import { createTipAction } from '../../../../actions/tips';

const TipsNew = ({ initialData }) => {
  const { apiService } = useContext(APIContext);
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const { router } = useContext(RouterContext);
  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const handleModalRedirect = newTipId => {
    handleCloseModal();
    router.push(`/administration/tips/${newTipId}/edit`);
  };

  const handleRemain = () => {
    handleCloseModal();
    router.push('/administration/tips/new');
  };

  const handleFormSubmit = async (tipsData, clearForm) => {
    try {
      const { tip } = await createTipAction(apiService, tipsData);
      clearForm();
      handleOpenModal({
        content: (
          <TipConfirmModal
            onHandleRedirect={() => handleModalRedirect(tip.id)}
            onHandleRemain={handleRemain}
          />),
        className: 'max-w-md',
        afterCloseAction: handleRemain,
      });
    } catch (err) {
      const errorMsg = (err?.details?.message) ? err.details.message : 'Something went wrong!';
      addNotification({
        type: 'failure',
        message: `Error: ${errorMsg}`,
      });
    }
  };

  return (
    <>
      <TipsForm
        formData={initialData}
        handleFormSubmit={handleFormSubmit}
        buttonText="Publish"
        subHeader="Create a tip by filling out the details below."
      />
      <GlobeSmartModal />
    </>
  );
};

TipsNew.getAPIDataKey = () => 'tipsFormData';
TipsNew.getData = apiService => apiService.get('tips/form')
  .then(tipsFormData => ({ tipsFormData }));

TipsNew.propTypes = {
  initialData: PropTypes.shape({
    cultures: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    ),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      })),
  }).isRequired,
};

export default withServerSideData(TipsNew);
