import { forEach, pickBy } from 'lodash';

const comparableIDandType = props => {
  const comparables = pickBy(props, (value, key) => ['culture', 'person', 'teams_average', 'org', 'segment'].includes(key));

  let type = null;
  let id = null;

  // Find the key/value pair that is not null
  forEach(comparables, (value, key) => {
    if (value) {
      id = value;
      type = key;
    }

    if (type === 'teams_average') type = 'team';
  });

  return { id, type };
};

export default comparableIDandType;
