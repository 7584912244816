import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../common/Button';

const CopilotConfiguration = ({ configuration, handleInput, handleUpdate }) => (
  <form onSubmit={handleUpdate}>
    <label
      htmlFor="promptText"
      className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700"
    >
      Prompt Text
      <textarea
        id="promptText"
        name="promptText"
        className="[field-sizing:content] max-h-full w-full text-charcoal-900 bg-white border border-slate-300 appearance-none rounded-lg px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
        rows={1}
        value={configuration?.promptText}
        onChange={handleInput}
      />
    </label>
    <label
      htmlFor="promptText"
      className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700"
    >
      No Match Text
      <textarea
        id="noMatchText"
        name="noMatchText"
        className="[field-sizing:content] max-h-full w-full text-charcoal-900 bg-white border border-slate-300 appearance-none rounded-lg px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
        rows={1}
        value={configuration?.noMatchText}
        onChange={handleInput}
      />
    </label>
    <div className="flex gap-4">
      <label
        htmlFor="pineconeThreshold"
        className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700"
      >
        Pinecone Threshold
        <input
          id="pineconeThreshold"
          name="pineconeThreshold"
          type="number"
          step="0.1"
          min="0"
          max="1"
          className="block max-h-full text-charcoal-900 bg-white border border-slate-300 appearance-none rounded-lg px-3.5 py-2.5 outline-none focus:bg-white focus:border-indigo-400 focus:ring-2 focus:ring-indigo-100"
          value={configuration?.pineconeThreshold}
          onChange={handleInput}
        />
      </label>
      <label
        htmlFor="openAiModel"
        className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700"
      >
        Open AI Model
        <p
          id="openAiModel"
          name="openAiModel"
        >
          {configuration?.openAiModel}
        </p>
      </label>
    </div>
    <Button
      className="block ml-auto"
      type="submit"
      variant="primary"
      onClick={handleUpdate}
    >
      Save Configuration
    </Button>
  </form>
);

CopilotConfiguration.propTypes = {
  configuration: PropTypes.shape({
    promptText: PropTypes.string,
    noMatchText: PropTypes.string,
    pineconeThreshold: PropTypes.number,
    openAiModel: PropTypes.string,
  }).isRequired,
  handleInput: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default CopilotConfiguration;
