import React from 'react';
import { withCookies } from 'react-cookie';

import { propTypes } from './types';

import { Context } from '../../../context/ContextProvider';

import { withErrors } from '../../Errors';

import InvitationAcceptAction from '../../../actions/invitationAccept';

import currentUrl from '../../../lib/urls/currentUrl';
import {
  handleInvitationAccept,
  handleInvitationAcceptError,
  buildInvitationRedirectUrl,
} from '../../../lib/invitations';

import determinePlanByType from '../../../lib/assessments/determinePlanByType';

// This is a non-visual component. It only exists to
// make API requests and redirect people as needed.

// PureComponent to prevent re-render and second API call.
class InvitationAccept extends React.PureComponent {
  static contextType = Context;

  componentDidMount() {
    const { apiService, currentUser, router, addToast } = this.context;
    const { params, location } = this.props;
    const authToken = this.context.currentUser.token;

    if (!authToken) {
      const redirectUrl = currentUrl(location, { removeQueryParams: ['sp'] });
      const connection = location.query.sp || null;
      const getConnectionQuery = () => (connection ? `sp=${connection}&` : '');
      const pushUrl = `/login?${getConnectionQuery()}redirect_to=${encodeURIComponent(redirectUrl)}`;
      this.context.router.push(pushUrl);
      return null;
    }

    if (authToken === this.previousAuthToken) {
      //  Currently when accepting an invitation, this component is re-rendering 4 or 5 times due to
      //    the currentUser changing in context. Ultimately we need to minimize those changes to
      //    currentUser, which get passed through the context provider and causing rerenders of all
      //    child components.
      //
      //    Previously this was resulting in multiple repeated API calls in this component
      //    (at `new InvitationAcceptAction`). We can prevent those repeated API calls by
      //    returning null here if the authToken is the same as in the previous render.
      return null;
    }
    this.previousAuthToken = authToken;

    new InvitationAcceptAction(apiService)
      .execute(params.token)
      .then(data => handleInvitationAccept({
        currentUser,
        invitation: data,
        acceptMethod: 'Email',
      }))
      .then(data => {
        if (data.type === 'group' && data.group) {
          const {
            group: {
              id: groupId,
              addOwnRaters,
              name,
              assessment: {
                displayName,
                type,
              },
            },
          } = data;
          if (addOwnRaters && currentUser.allowAddOwnRaters && currentUser.hasCompletedIBI) {
            addToast({
              content: (
                <div className="flex items-center justify-between">
                  <p className="m-0 ml-4">You are now part of the  {displayName} Group, {name}.</p>
                </div>
              ),
              autoDismiss: 5000,
            });
            router.push(`/assessments/${type}/group/${groupId}/raters/new`);
            return;
          }
        }

        const redirectUrl = buildInvitationRedirectUrl(data);
        router.push(redirectUrl);
      })
      .catch(error => {
        if (error && error.resource === 'assessment') {
          router.push(`/dashboard?purchase&plan=${determinePlanByType(error.assessmentType)}&redirect_to=${location.pathname}`);
        } else {
          const errorResponse = handleInvitationAcceptError(error, this.props);
          this.context.addNotification({ type: 'failure', message: errorResponse });
          router.push('/invitations');
        }
      });

    return null;
  }

  render() {
    return null;
  }
}

InvitationAccept.propTypes = propTypes;

export default withCookies(withErrors(InvitationAccept));
export { InvitationAccept as Component };
