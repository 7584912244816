import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CK5EditorComponent from '../../../CK5Editor';
import Button from '../../../common/Button';

const OrganizationDashboardMessage = ({ message, onUpdate }) => {
  const [orgMessage, setOrgMessage] = useState(message || '');

  const updateOrgMessage = newMessage => setOrgMessage(newMessage);

  const handleMessageUpdate = () => onUpdate({ message: orgMessage });
  const defaultMessageTemplate = `
  <div class="container--float px-11 py-7">
    <h2>Title</h2>
    <p>Just pretend you are a whisper floating across a mountain. Let's get crazy. Just think about these things in your mind and drop em' on canvas. It's all a game of angles. Let's make some happy little clouds in our world.</p>
  </div>`;

  return (
    <div className="mt-2 mb-8">
      <div className="admin-dashboard-layout__header">
        <h5 className="mb-4 font-serif text-2xl">Message</h5>
        <Button
          filledColor="secondary"
          isSmall
          onClick={handleMessageUpdate}
        >
          SAVE
        </Button>
      </div>
      <CK5EditorComponent
        savedHTML={orgMessage || defaultMessageTemplate}
        updateHTML={updateOrgMessage}
      />
    </div>
  );
};

OrganizationDashboardMessage.propTypes = {
  message: PropTypes.string,
  onUpdate: PropTypes.func,
};

OrganizationDashboardMessage.defaultProps = {
  message: '',
  onUpdate: () => {},
};

export default OrganizationDashboardMessage;
