/* eslint-disable no-useless-escape */
export const stripMarkdown = markdown => {
  // Remove headers
  let text = markdown.replace(/^#{1,6}\s+/gm, '');

  // Remove bold and italic
  text = text.replace(/\*\*([^*]+)\*\*/g, '$1');
  text = text.replace(/__([^_]+)__/g, '$1');

  text = text.replace(/\*([^*]+)\*/g, '$1');
  text = text.replace(/_([^_]+)_/g, '$1');

  // Remove inline code
  text = text.replace(/`([^`]+)`/g, '$1');

  // Remove links
  text = text.replace(/\[([^\]]+)\]\([^)]+\)/g, '$1');

  // Remove images
  text = text.replace(/!\[([^\]]*)\]\([^)]+\)/g, '$1');

  // Remove blockquotes
  text = text.replace(/^>\s+/gm, '');

  // Remove horizontal rules
  text = text.replace(/^(-{3,}|_{3,}|\*{3,})$/gm, '');

  // Remove lists
  text = text.replace(/^\s*[\*\+\-]\s+/gm, '');
  text = text.replace(/^\s*\d+\.\s+/gm, '');

  return text;
};
