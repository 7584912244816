import React from 'react';
import { withCookies } from 'react-cookie';
import DOMPurify from 'isomorphic-dompurify';

import { propTypes } from './types';

import { Context } from '../../../../context/ContextProvider';

import Auth from '../../../../lib/auth/auth0/auth';
import { COOKIE_REDIRECT, IS_PRODUCTION } from '../../../../lib/constants';

class Registration extends React.PureComponent {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.cookies = props.cookies;
    this.redirectTo = props.location.query.redirect_to || props.location.query.RelayState || false;
  }

  componentDidMount() {
    // If a redirect URI is passed in, set it as a cookie to be used after Auth0 returns to our app
    if (this.redirectTo) {
      this.cookies.set(
        COOKIE_REDIRECT,
        DOMPurify.sanitize(this.redirectTo),
        { path: '/', maxAge: 60, secure: IS_PRODUCTION },
      );
    }

    const auth = new Auth();
    auth.login({ initialScreen: 'signUp' });
  }

  render() {
    return null;
  }
}

Registration.propTypes = propTypes;
export default withCookies(Registration);
