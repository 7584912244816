import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../../common/Button';

const renderButton = (text, options, userId) => {
  if (!text) return null;

  return (
    <Button {...options} isSmall isWarning={text === 'Remove User'} dataID={userId} className="card__button">
      {text}
    </Button>
  );
};

const renderButtonContainer = options => {
  const {
    userId,
    confirmText,
    confirmButton,
    cancelText,
    cancelButton,
  } = options;

  return (
    <div className="card__actions">
      {renderButton(confirmText, confirmButton, userId)}
      {renderButton(cancelText, cancelButton)}
    </div>
  );
};

const OrganizationManageUserButton = props => {
  const [confirmAction, setConfirmAction] = useState(null);

  const manageUserAction = e => {
    const { dataset: { id } } = e.target;
    if (confirmAction === 'add') props.onAdd(id);
    if (confirmAction === 'remove') props.onRemove(id);
    setConfirmAction(null);
  };

  const userActionConfig = props.organizations.some(org => org.id === props.orgId)
    ? { actionType: 'remove', buttonText: 'Remove User' }
    : { actionType: 'add', buttonText: 'Add User', buttonColor: 'primary' };

  const renderAddOrRemoveButton = ({ id }) => renderButtonContainer({
    userId: id,
    confirmText: userActionConfig.buttonText,
    confirmButton: {
      filledColor: userActionConfig.buttonColor,
      isNormalCase: true,
      onClick: () => setConfirmAction(userActionConfig.actionType),
    },
  });

  const renderConfirmCancel = ({ id }) => renderButtonContainer({
    userId: id,
    displayName: `Are you sure you want to ${confirmAction === 'add' ? 'Add' : 'Remove'} this user?`,
    confirmText: `Yes, ${confirmAction === 'add' ? 'Add' : 'Remove'}`,
    confirmButton: {
      filledColor: 'red',
      isNormalCase: true,
      onClick: manageUserAction,
    },
    cancelText: 'No, Cancel',
    cancelButton: {
      isNormalCase: true,
      isCancel: true,
      onClick: () => setConfirmAction(null),
    },
  });

  return confirmAction ? renderConfirmCancel(props) : renderAddOrRemoveButton(props);
};

OrganizationManageUserButton.propTypes = {
  onAdd: PropTypes.func.isRequired,
};

export default OrganizationManageUserButton;
