import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../common/Avatars/Avatar';

const MultiSelectCompareCard = ({
  className,
  cardDisplayName,
  handleOpenConfirmModal,
  avatarUrl,
  checked,
}) => (
  <div className={className}>
    <div
      data-checked={checked}
      className="flex flex-col items-center justify-center w-full h-full transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-md hover:shadow-xl data-[checked=true]:ring-1 data-[checked=true]:ring-inset data-[checked=true]:shadow-input data-[checked=true]:ring-rust-500"
      onClick={() => handleOpenConfirmModal(cardDisplayName)}
      onKeyDown={e => (e.key === 'Enter' || e.key === ' ') && handleOpenConfirmModal(cardDisplayName)}
      role="button"
      tabIndex={0}
    >
      <Avatar avatarUrl={avatarUrl} isCulture={false} />
      <span className="mt-2 font-sans text-sm font-medium text-center line-clamp-2 text-charcoal-900 text-balance">{cardDisplayName}
      </span>
    </div>
  </div>
);

MultiSelectCompareCard.propTypes = {
  className: PropTypes.string.isRequired,
  cardDisplayName: PropTypes.string.isRequired,
  handleOpenConfirmModal: PropTypes.func.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

export default MultiSelectCompareCard;
