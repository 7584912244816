import React from 'react';
import PropTypes from 'prop-types';

const OrgSegmentDisclaimer = ({ hasSegment, profiles }) => {
  if (hasSegment) {
    const orgSegments = profiles.filter(profile => (profile.type === 'segment'));
    return orgSegments.map(segment => (
      <div key={segment.id} className="flex justify-center px-4 text-center disclaimer md:px-0">
        <p className="font-sans text-sm font-light md:text-md text-charcoal-900">
          The {segment.orgName} Average {segment.shortDisplayName}
          : {segment.segmentValue} is built from&nbsp;
          {segment.profileCount} profiles in the organization.
        </p>
      </div>
    ));
  }
  return null;
};

OrgSegmentDisclaimer.propTypes = {
  hasSegment: PropTypes.bool,
  profiles: PropTypes.arrayOf(PropTypes.shape({})),
};

OrgSegmentDisclaimer.defaultProps = {
  hasSegment: false,
  profiles: [],
};

export default OrgSegmentDisclaimer;
