import React, { useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import LabelInput from '../../../common/LabelInput';
import OrganizationUserList from './OrganizationUserList';

import SearchUsersAction from '../../../../actions/users/search';
import OrganizationAddOwnerAction from '../../../../actions/organizations/organizationAddOwner';
import OrganizationRemoveOwnerAction from '../../../../actions/organizations/organizationRemoveOwner';
import { getErrorMessage } from '../../RenderNotification';
import withServerSideData from '../../../../HOC/withServerSideData';
import { NotificationContext } from '../../../../context/Notification';
import Notification from '../../../Notifications/Notification';
import { APIContext } from '../../../../context/API';

const OrganizationOwners = ({ initialData, params: { id: orgId } }) => {
  const { apiService } = useContext(APIContext);

  const {
    addNotification,
    notificationMessage,
    removeNotification,
  } = useContext(NotificationContext);

  const [state, setState] = useReducer((data, newData) =>
    ({ ...data, ...newData }), {
    owners: initialData?.owners,
    searchUsers: [],
  });

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        setState({ firstNotification: false, secondNotification: false });
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const onAdd = (userId, e) => {
    e.preventDefault();
    return new OrganizationAddOwnerAction(apiService)
      .execute(orgId, userId)
      .then(response => {
        setState({ secondNotification: true });
        addNotification({
          type: 'success',
          message: 'New owner added.',
        });
        return response.owners;
      })
      .then(owners => {
        if (!owners) return;
        const searchUsers = state.searchUsers.filter(u => u.id !== userId);
        setState({ searchUsers, owners });
      })
      .catch(err => {
        setState({ secondNotification: true });
        addNotification({
          type: 'warning',
          message: `Error adding user: ${getErrorMessage(err)}`,
        });
      });
  };

  const onRemove = (userId, e) => {
    e.preventDefault();

    return new OrganizationRemoveOwnerAction(apiService)
      .execute(orgId, userId)
      .then(() => {
        addNotification({
          type: 'info',
          message: 'Owner Removed.',
        });
        const owners = state.owners.filter(owner => owner.id !== userId);
        setState({ owners, firstNotification: true });
      })
      .catch(err => {
        const error = err;
        if (err.status === 404) {
          error.error = 'Organization not found';
        }
        setState({ firstNotification: true });
        addNotification({
          type: 'warning',
          message: `Error deleting owner: ${getErrorMessage(err)}`,
        });
      });
  };

  useEffect(() => {
    let timer;
    const { userEmail, owners } = state;

    if (!userEmail) {
      return setState({ searchUsers: [] });
    }

    if (timer) window.clearTimeout(timer);

    timer = setTimeout(async () => {
      new SearchUsersAction(apiService)
        .execute({
          firstName: '',
          lastName: '',
          email: userEmail,
        })
        .then(({ users }) => {
          const searchUsers = users.filter(user => !owners.find(owner => owner.id === user.id));
          setState({ searchUsers });
        })
        .catch(err => {
          setState({ secondNotification: true });
          addNotification({
            type: 'warning',
            message: `Error searching: ${getErrorMessage(err)}`,
          });
        });
    }, 250);

    return () => clearTimeout(timer);
  }, [state.userEmail]);

  const onSearchParamChange = ({ target: { value } }) => setState({ userEmail: value });

  return (
    <>
      {state.firstNotification && <Notification {...notificationMessage} />}
      <div className="grid gap-2 mt-4 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Organization Owners</h5>
        </div>
        <div className="flex flex-col overflow-x-auto md:col-span-4">
          <OrganizationUserList
            owners={state.owners}
            users={state.owners}
            onRemove={onRemove}
            isOwnersList
          />
        </div>
      </div>
      <hr className="my-8 border border-stone-400" />
      <div className="grid gap-2 mb-10 md:grid-cols-6">
        <div className="md:col-span-2">
          <h5 className="mb-4 font-serif text-xl">Add Owner</h5>
        </div>
        <div className="flex flex-col gap-4 overflow-x-auto md:col-span-4">
          <p className="mb-0 text-base">Search by email address to find and select organization owners.</p>
          {state.secondNotification && <Notification {...notificationMessage} />}
          <LabelInput
            id="userEmail"
            name="Email"
            labelText="Email"
            labelType="text"
            value={state.userEmail}
            onChangeValue={onSearchParamChange}
          />
          <OrganizationUserList
            owners={state.owners}
            users={state.searchUsers}
            onAdd={onAdd}
          />
        </div>
      </div>
    </>
  );
};

OrganizationOwners.getAPIDataKey = () => 'owners';

OrganizationOwners.getData = (apiService, { id }) => apiService
  .get(`organizations/${id}/owners`)
  .then(data => ({ owners: data }));

OrganizationOwners.propTypes = {
  initialData: PropTypes.shape({
    owners: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(OrganizationOwners);
