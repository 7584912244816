import React from 'react';

import Icon from '../../../common/Icon';

const ComparisonHeader = () => (
  <div>
    <div className="flex flex-col items-center gap-8 my-4 print:hidden">
      <div className="flex items-center gap-2 px-4 py-2 rounded-full bg-slate-50">
        <div className="flex items-center justify-center bg-white rounded-full">
          <Icon icon="help" />
        </div>
        <span className="ml-2 text-sm font-medium tracking-wide md:text-xl font-headline text-charcoal-900">Click on a Profile for personalized advice</span>
      </div>
    </div>
  </div>
);

export default ComparisonHeader;
