import React from 'react';

import SelectedComparables from '../../NewComparison/SelectedComparables';
import Button from '../../common/Button';

import { propTypes, defaultProps, contextTypes } from './types';
import Icon from '../../common/Icon';

const FullScreenHeader = props => {
  const {
    confirmText,
    cancelText,
    confirmHandler,
    confirmOptions,
    cancelHandler,
    cancelOptions,
    compareTargets,
    selectedComparables,
    filteredComparables,
    handleCheckboxChange,
    onRemove,
    filterValue,
    onFilter,
    onClearAll,
    isHiddenOverlay,
    children,
  } = props;

  return (
    <div className="w-full mx-auto max-w-7xl">
      <header className="mb-4">
        {isHiddenOverlay
          ? (
            <div className="flex items-center justify-center gap-4 mx-auto my-6 w-fit">
              <Icon icon="profile" iconColor="gray" className="w-8 h-8" />
              <h1 className="w-[95%] m-0 mx-auto font-serif text-2xl md:text-3xl lg:text-4xl text-charcoal-900">New Comparison
              </h1>
            </div>
          )
          : (
            <SelectedComparables
              compareTargets={compareTargets}
              selectedComparables={selectedComparables}
              filteredComparables={filteredComparables}
              onRemove={onRemove}
              filterValue={filterValue}
              onFilter={onFilter}
              updateSelectedComparables={handleCheckboxChange}
              handleClear={onClearAll}
              handleCreateComparison={confirmHandler}
            />
          )}

        {isHiddenOverlay
          ? (
            <div className={`flex ${isHiddenOverlay ? 'justify-center' : 'justify-between'} mt-4`}>
              <div className="flex gap-2">
                {/* The order of these props is important. We need on the `onClick`
                to be second so that it will override the default `onClick` from
                cancel
              */}
                <Button variant="secondary" {...cancelOptions} isSmall onClick={cancelHandler}>
                  {cancelText}
                </Button>
                {/* The order of these props is important. We need on the `onClick`
                  to be second so that it will override the default `onClick` from
                  confirmOptions
              */}
                {confirmOptions && confirmText && (
                  <Button variant="primary" {...confirmOptions} isSmall onClick={confirmHandler}>
                    {confirmText}
                  </Button>
                )}
              </div>

            </div>
          )
          : null}
        <div className={isHiddenOverlay ? 'mt-4' : ''}>
          {children}
        </div>
      </header>
    </div>
  );
};

FullScreenHeader.propTypes = propTypes;
FullScreenHeader.defaultProps = defaultProps;
FullScreenHeader.contextTypes = contextTypes;

export default FullScreenHeader;
