import React from 'react';
import PropTypes from 'prop-types';

import { membersTypes } from '../types';

import SectionContainer from '../../../common/Containers/Section';
import TeamMemberCards from './TeamMemberCards';
import Button from '../../../common/Button';

const TeamMembers = ({
  isOwner,
  members,
  onRemind,
  onRemove,
  currentUserId,
  wasReminded,
  handleOpenConfirmModal,
  pendingInvitations,
}) => (
  <SectionContainer>
    <div className="inline-flex items-center justify-between w-full">
      <h4>Team Members</h4>
      {(isOwner && pendingInvitations === 0) && (
        <div className="md:gap-2 md:flex">
          <Button
            className="block mb-4 ml-auto w-fit"
            isSmall
            isNormalCase
            variant="primary"
            onClick={handleOpenConfirmModal}
            disabled={wasReminded}
          >
            {wasReminded ? 'Sending Reminders' : 'Send All Reminders'}
          </Button>
        </div>
      )}
    </div>
    <ul className="profile-group__list">
      <TeamMemberCards
        isOwner={isOwner}
        members={members}
        onRemind={onRemind}
        onRemove={onRemove}
        currentUserId={currentUserId}
      />
    </ul>
  </SectionContainer>
);

TeamMembers.propTypes = {
  onRemind: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  members: membersTypes.isRequired,
  isOwner: PropTypes.bool,
  currentUserId: PropTypes.string.isRequired,
  wasReminded: PropTypes.bool,
  handleOpenConfirmModal: PropTypes.func,
  pendingInvitations: PropTypes.number,
};
TeamMembers.defaultProps = {
  isOwner: false,
  wasReminded: false,
  handleOpenConfirmModal: () => { },
  pendingInvitations: 0,
};

export default TeamMembers;
