import { tracks } from './tracks';
import { trialCreator, trialInvitee } from './paths';

const determineCustomTrack = pathname => {
  if (pathname.endsWith('ls')) {
    return '6';
  }
  if (pathname.endsWith('al')) {
    return '7';
  }
  return '8';
};

/*
  THE ORDER OF EVENTS MATTER HERE. Please make sure if you're adding or removing logic that
  is doesn't interrupt the trial signin/singup flow
*/
const handleResponseActions = response => {
  const redirect = `authenticated#access_token=${response?.accessToken}&id_token=${response?.idToken}`;
  const actions = {};

  if (response.isEmailInvalid) {
    actions.notification = 'Please use a valid email address. Contact support for additional assistance.';
    actions.error = true;
  }

  if (response.isOrgNameTaken) {
    actions.notification = 'Organization Name already taken by another organization.';
    actions.error = true;
  }

  if (response.isTrialExpired) {
    actions.redirect = '/try/expired';
  }

  if (response.isExistingUser) {
    const loginRedirect = response?.isOrgSSO ? `/login?sp=${response.orgConnectionId}` : '/login';
    actions.notification = 'User Already Exists. Redirecting to Login Page.';
    actions.redirect = loginRedirect;
  }

  // sso org => redirect to sso signin
  if (response.isExistingEnterpriseOrgNewUser && response.isOrgSSO) {
    actions.notification = 'Your organization already has an Aperian account. Redirecting to Login Page.';
    actions.redirect = `/login?sp=${response.orgConnectionId}`;
  }

  if (response.isNewUserCreated) {
    actions.notification = `${response.orgName} already has an active Aperian trial! Signing you up for Aperian.`;
    if (response.isTrialUserCreated) {
      if (response.isActiveTrial) {
        actions.type = 'success';
        actions.redirect = redirect;
      } else if (response.isTrialOwner) {
        actions.notification = 'You\'ve got full access to Aperian! Signing you up for Aperian.';
        actions.type = 'success';
        actions.redirect = redirect;
      }
    }

    // could be a previously existing org or converted trial org
    if (response.isExistingEnterpriseOrgNewUser) {
      actions.notification = 'Your organization already has an Aperian account. Signing you up for Aperian.';
      actions.type = 'success';
      actions.redirect = redirect;
    }
  }

  if (actions?.redirect) actions.redirectDelay = 8000;

  return actions;
};

export {
  tracks,
  trialCreator,
  trialInvitee,
  determineCustomTrack,
  handleResponseActions,
};
