import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../Checkbox';
import Button from '../../Button';
import LabelInput from '../../LabelInput';

import { useFilter } from '../../../../hooks/useFilter';

const DropdownMultiSelectSearchModal = ({
  card,
  onChange,
  handleClose,
}) => {
  const [newOptions, setNewOptions] = useState(card.values);
  const { filterValue, setFilterValue, filteredItems } = useFilter(newOptions, { keys: ['name'] });

  const noItems = filteredItems.length === 0;

  const handleSelectOption = event => {
    const { target: { id: optionId, checked } } = event;
    const updatedOptions = newOptions.map(opt =>
      ((opt.id.toString() === optionId.toString()) ? { ...opt, checked } : opt));
    setNewOptions(updatedOptions);
  };

  const handleUpdateSelectedOptions = () => {
    handleClose();
    const hasOptionsSelected = newOptions.some(option => option.checked);
    onChange({ ...card, values: newOptions }, hasOptionsSelected);
    setFilterValue('');
  };

  return (
    <>
      <span
        className="font-sans text-lg font-medium text-center line-clamp-2 text-charcoal-900 text-balance"
      >
        {card.displayName}
      </span>
      <div className="relative mt-2">
        <LabelInput
          id="search"
          name="search"
          type="text"
          placeholderText={`${card.displayName}`}
          value={filterValue}
          onChangeValue={e => setFilterValue(e.target.value)}
          leadingIcon="search"
          trailingIcon={filterValue ? 'x-close' : ''}
          handleIconClick={() => setFilterValue('')}
        />
      </div>
      <ul className="my-2 overflow-y-auto list-none cursor-pointer max-h-40 peer-checked:bg-slate-50">
        {filteredItems
          .map(({ id, name, checked }) => (
            <li key={`option-${id}`}>
              <Checkbox
                id={id}
                name={name}
                labelText={name}
                labelClasses="peer hover:bg-slate-50 p-2 rounded-md"
                checked={checked}
                onChange={handleSelectOption}
              />
            </li>
          ))}
      </ul>
      {noItems && <div className="p-2 text-base h-14 text-rust-500">No options found</div>}
      <Button
        filledColor="secondary"
        onClick={handleUpdateSelectedOptions}
        disabled={noItems}
        isFullWidth
      >
        Add
      </Button>
    </>
  );
};

DropdownMultiSelectSearchModal.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    displayName: PropTypes.string,
    type: PropTypes.string,
    values: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
      }),
    ).isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DropdownMultiSelectSearchModal;
