/* eslint-disable prefer-arrow-callback */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import EditableHeader from '../../../EditableHeader';

const Header = forwardRef(
  function HeaderWithRef({
    groupName,
    saveName,
    isEditing,
    toggleEditing,
    titleBadges,
  }, ref) {
    return (
      <div id="manage-group" className="flex flex-wrap items-center">
        <div className="flex-grow md:pr-8" ref={ref}>
          <EditableHeader
            name="name"
            label="Name"
            value={groupName}
            onUpdate={saveName}
            isEditing={isEditing}
            toggleEditing={toggleEditing}
            isHeading
            titleBadges={titleBadges}
          />
        </div>
      </div>
    );
  });

Header.propTypes = {
  groupName: PropTypes.string.isRequired,
  saveName: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  toggleEditing: PropTypes.func.isRequired,
  titleBadges: PropTypes.arrayOf(PropTypes.string),
};

Header.defaultProps = {
  titleBadges: [],
};

export default Header;
