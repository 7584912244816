import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { Context } from '../../context/ContextProvider';

import LogoutAction from '../../actions/logout';

import Auth from '../../lib/auth/auth0/auth';
import {
  COOKIE_AUTH, COOKIE_PROFILE, COOKIE_REDIRECT, IS_PRODUCTION,
} from '../../lib/constants';
import { trackLogout } from '../../lib/tracker/logout';

const Logout = props => {
  const [, setCookies, removeCookie] = useCookies();
  const { apiService } = useContext(Context);
  const redirectTo = props.location.query.redirect_to
    || props.location.query.RelayState
    || false;

  useEffect(() => {
    async function handleLogout() {
      await new LogoutAction(apiService).execute();
      await trackLogout();
      removeCookie(COOKIE_PROFILE);
      removeCookie(COOKIE_AUTH);
      // set redirect_to as a cookie to be used after Auth0 returns to our app
      if (redirectTo) {
        const purifiedRedirectTo = DOMPurify.sanitize(redirectTo);
        setCookies(
          COOKIE_REDIRECT,
          purifiedRedirectTo,
          { path: '/', maxAge: 300, secure: IS_PRODUCTION },
        );
      }
      new Auth().logout();
    }
    handleLogout();
  }, []);

  return null;
};

Logout.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      redirect_to: PropTypes.string,
      RelayState: PropTypes.string,
    }),
  }),
};

Logout.defaultProps = { location: null };

export default Logout;
