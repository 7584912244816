export const filterItems = (value, filterProperty, items) => {
  const countryAliasMap = {
    turkey: 'turkiye',
  };
  const pattern = countryAliasMap[value.toLowerCase()] || value.toLowerCase();
  const filteredPattern = pattern.replace(/[^\w\s-]*/g, '');
  const filterMatch = new RegExp(`.*${filteredPattern.split('').join('.*')}.*`);

  return items?.filter(item => item[filterProperty].toLowerCase().match(filterMatch)) || [];
};

export default filterItems;
