import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Avatar from '../common/Avatars/Avatar';
import Button from '../common/Button';
import UploadAvatar from './modals/UploadAvatar';
import DeleteAvatar from './modals/DeleteAvatar';
import CreateInitials from './modals/CreateInitials';

const AvatarEditor = props => {
  const [isOpen, setIsOpen] = useState(false);
  const handleDropdown = () => setIsOpen(!isOpen);
  const { options,
    handleCloseModal,
    handleOpenModal,
    buttonClass,
    entityType,
    uploadImage,
    deleteImage,
    createInitials,
    firstName,
    lastName,
    isOwner } = props;

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(e) {
      if ((dropdownRef?.current && !dropdownRef.current.contains(e.target))
        && (buttonRef?.current && !buttonRef.current.contains(e.target))) {
        e.preventDefault();
        buttonRef.current.blur();
        handleDropdown();
      }
    }
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [isOpen]);

  const handleEditedAvatar = canvas => {
    const type = 'image/png';
    canvas.toBlob(blob => {
      const file = new File([blob], `${props.id}.png`, { type });

      const formData = new FormData();
      formData.append(entityType === 'Team' ? 'team' : 'avatar', file);
      uploadImage(formData);
    }, type);
  };

  const handleDropdownOption = ({ target: { dataset: { id } } }) => {
    const modals = {
      1: (
        <UploadAvatar
          handleClose={handleCloseModal}
          handleEditedAvatar={handleEditedAvatar}
          headerText={`Upload ${entityType} Photo`}
        />
      ),
      2: entityType === 'Profile' ? (
        <CreateInitials
          handleClose={handleCloseModal}
          defaultInitials={`${firstName.charAt(0)}${lastName.charAt(0)}`}
          handleSaveInitials={createInitials}
        />
      ) : (
        <DeleteAvatar
          handleClose={handleCloseModal}
          handleConfirm={deleteImage}
          headerText="Are you sure you want to delete team avatar?"
        />
      ),
      3: (
        <DeleteAvatar
          handleClose={handleCloseModal}
          handleConfirm={deleteImage}
          headerText="Are you sure you want to delete your photo?"
        />
      ),
    };

    if (modals[id]) {
      handleOpenModal({ content: modals[id] });
    }

    setIsOpen(false);
  };

  const renderDropdownSelections = () =>
    options.map(option => (
      <li key={option.code}>
        <Button
          isSmall
          className="w-full hover:text-white hover:bg-slate-400"
          onClick={handleDropdownOption}
          outlinedColor="white"
          dataID={option.code}
        >
          {option.label}
        </Button>
      </li>
    ));

  return (
    <div className="relative block mx-1">
      <Avatar
        {...props}
        className="border-white min-w-[7rem] min-h-[7rem] border-6"
      />
      {((isOwner && entityType === 'Team') || entityType === 'Profile') && (
        <div className={buttonClass}>
          <Button
            className="relative float-right w-10 h-10 border-4 hover:bg-slate-50 focus:bg-slate-25"
            ref={buttonRef}
            outlinedColor="white"
            onClick={handleDropdown}
            leadingButtonIcon="edit"
            iconColor="gray"
            isCircle
          />
          {isOpen
            ? (
              <div ref={dropdownRef}>
                <ul className={`absolute z-10 shadow-md border border-[#e5e7eb] flex flex-col w-40 gap-1 p-1 mr-0 list-none transform -translate-x-24 translate-y-12 md:translate-x-8 md:translate-y-10 bg-white rounded-md ${isOpen ? 'opacity-100' : 'opacity-0'} transition-opacity delay-100 duration-300 ease-in-out`}>
                  {renderDropdownSelections()}
                </ul>
              </div>
            )
            : null}
        </div>
      )}
    </div>
  );
};

AvatarEditor.propTypes = {
  userId: PropTypes.string,
  uploadImage: PropTypes.func,
  handleCloseModal: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  avatarUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  createInitials: PropTypes.func,
  deleteImage: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
  buttonClass: PropTypes.string,
  entityType: PropTypes.string.isRequired,
  isOwner: PropTypes.bool,
};

AvatarEditor.defaultProps = {
  userId: '',
  uploadImage: () => { },
  buttonClass: '',
  firstName: '',
  lastName: '',
  createInitials: () => { },
  deleteImage: () => { },
  isOwner: true,
};

export default AvatarEditor;
