import AfterTrackSelectExplanation from '../../components/custom/Onboarding/AfterTrackSelectExplanation';
import AfterTrackSelectMessage from '../../components/custom/Onboarding/AfterTrackSelectMessage';
import AfterSurveyMessage from '../../components/custom/Onboarding/AfterSurveyMessage';
import BeforeSurveyMessage from '../../components/custom/Onboarding/BeforeSurveyMessage';
import EmailVerification from '../../components/custom/Onboarding/EmailVerification';
import Greeting from '../../components/custom/Onboarding/Greeting';
import GSPChart from '../../components/custom/Onboarding/GSPChart';
import InviteOthers from '../../components/custom/Onboarding/InviteOthers';
import Landing from '../../components/custom/Onboarding/Landing';
import MiniComparison from '../../components/custom/Onboarding/MiniComparison';
import MiniSurvey from '../../components/custom/Onboarding/MiniSurvey';
import NameInput from '../../components/custom/Onboarding/NameInput';
import OnboardingSingleForm from '../../components/custom/Onboarding/OnboardingSingleForm';
import PasswordInput from '../../components/custom/Onboarding/PasswordInput';
import SelectUseCase from '../../components/custom/Onboarding/SelectUseCase';
import Snapshot from '../../components/custom/Onboarding/Snapshot';
import TrackSelection from '../../components/custom/Onboarding/TrackSelection';

export const trialCreator = {
  1: OnboardingSingleForm,
};

export const fullFlowTrialCreator = {
  1: Landing,
  2: SelectUseCase,
  3: NameInput,
  4: Greeting,
  5: TrackSelection,
  6: AfterTrackSelectMessage,
  7: AfterTrackSelectExplanation,
  8: BeforeSurveyMessage,
  9: MiniSurvey,
  10: AfterSurveyMessage,
  11: Snapshot,
  12: MiniComparison,
  13: GSPChart,
  14: InviteOthers,
  15: EmailVerification,
};

export const trialInvitee = {
  1: Landing,
  2: NameInput,
  3: Greeting,
  4: PasswordInput,
};
