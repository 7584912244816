import classNames from 'classnames';
import React, { Component } from 'react';
import parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import Avatar from '../Avatar';
import AvatarNavigation from '../AvatarNavigation';
import { defaultProps, propTypes } from './types';

class ProfileChartAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCulture: props.type === 'culture',
      isHistoryProfile: props.isHistoryProfile || false,
      containerClasses: classNames({
        'avatar-container__item': true,
        'avatar-container__item--with-border': props.withBorder,
        'avatar-container__item--culture md:min-w-[6rem]': props.type === 'culture',
        'opacity-70': props.isHistoryProfile,
      }),
    };
  }

  isExpanded() {
    return this.props.selectedAvatar === this.props.index;
  }

  renderNameContainer() {
    if (!this.props.showName) return null;
    const displayName = this.props.segmentValue ? `${this.props.shortDisplayName}: ${this.props.segmentValue}` : this.props.shortDisplayName;

    return (
      <div className="mt-2 line-clamp-3">
        <span
          className={`block text-center text-responsive max-w-[5.25rem] font-sans font-semibold ${this.props.index ? 'text-gray-500' : 'text-charcoal-900'}`}
          data-number={this.props.index + 1}
          title={this.props.shortDisplayName}
        >
          {parser(DOMPurify.sanitize(displayName, { ADD_ATTR: ['target'] }))}
        </span>
      </div>
    );
  }

  renderWithNavigation() {
    const {
      id,
      addAvatarRef,
      avatarUrl,
      firstName,
      type,
      cultureGuideUrl,
    } = this.props;

    return (
      <div key={id} className={`z-[300] transform transition-transform scale-100 hover:scale-105 ${this.state.containerClasses} ${(this.props.selectedAvatar && this.props.selectedAvatar !== this.props.index) ? 'opacity-70' : ''} max-h-[10rem]`}>
        <button
          type="button"
          aria-expanded={this.isExpanded()}
          onClick={() => { this.props.handleAvatarClick(this); }}
          className={`${this.props.withBorder ? 'p-2 md:p-3 w-full avatar__button' : ''} js-avatar-nav-toggle`}
        >
          <div data-id={id} ref={addAvatarRef} className="w-full h-auto m-auto">
            <Avatar
              avatarUrl={avatarUrl}
              avatarImgAlt={firstName}
              isCulture={this.state.isCulture}
              isHistoryProfile={this.props.isHistoryProfile}
              id={id.toString()}
              className="m-auto"
            />
          </div>
          {this.renderNameContainer()}
        </button>
        <AvatarNavigation
          id={id}
          type={type}
          cultureGuideUrl={cultureGuideUrl}
        />
      </div>
    );
  }

  renderWithoutNavigation() {
    const {
      id,
      addAvatarRef,
      avatarUrl,
      firstName,
      myProfileVisibility,
    } = this.props;
    const styles = { visibility: myProfileVisibility };
    return (
      <div className={`${this.state.containerClasses} ${this.props.withBorder ? 'p-2 md:p-3' : ''}`} style={styles}>
        <div data-id={id} ref={addAvatarRef}>
          <Avatar
            avatarUrl={avatarUrl}
            avatarImgAlt={firstName}
            isHistoryProfile={this.state.isHistoryProfile}
            isCulture={this.state.isCulture}
            id={id.toString()}
            className="m-auto"
          />
        </div>

        {this.renderNameContainer()}
      </div>
    );
  }

  render() {
    if (this.props.hasNavigation) {
      return this.renderWithNavigation();
    }
    return this.renderWithoutNavigation();
  }
}

ProfileChartAvatar.propTypes = propTypes;
ProfileChartAvatar.defaultProps = defaultProps;

export default ProfileChartAvatar;
