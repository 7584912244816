import React, { useContext, useRef, useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import { Model } from 'survey-core';
import { Survey } from 'survey-react-ui';

// Import localization support for SurveyJS for the languages we need
import 'survey-core/i18n/french';
import 'survey-core/i18n/german';
import 'survey-core/i18n/japanese';
import 'survey-core/i18n/spanish';
import 'survey-core/i18n/simplified-chinese';

import withServerSideData from '../../../HOC/withServerSideData';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';

import SiteHeader from '../../SiteHeader';
import Icon from '../../common/Icon';
import Footer from '../../common/Footer';

import {
  manageAssessmentSurveyInstanceAction,
  submitAssessmentSurveyAnswerAction,
  setAssessmentSurveyLanguageAction,
} from '../../../actions/assessments/manageSurveyInstance';

const theme = require('../assessmentSurveyTheme.json');

const AssessmentSurvey = ({ location, initialData, params }) => {
  const { displayName, surveyJson, surveyData } = initialData;
  const { assessment } = params;
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);

  const pageTitle = displayName;
  const [surveyStarted, setSurveyStarted] = useState(surveyData?.state === 'started');
  const [surveyResponseId, setSurveyResponseId] = useState(surveyData?.surveyResponseId || null);
  const [isMounted, setIsMounted] = useState(false);
  let isCurrentSurveyPageChanged = true;

  // Store the survey instance and keep it persistent across renders
  const surveyRef = useRef(null);

  // Initialize the survey model only once
  if (!surveyRef.current) {
    surveyRef.current = new Model(surveyJson);
  }

  const survey = surveyRef.current;

  const languageOptions = [
    { code: 'en', name: 'English', label: 'English' },
    { code: 'fr', name: 'French', label: 'Français' },
    { code: 'de', name: 'German', label: 'Deutsch' },
    { code: 'ja', name: 'Japanese', label: '日本語' },
    { code: 'es', name: 'Spanish', label: 'Español' },
    { code: 'zh-cn', name: 'Chinese (simplified)', label: '中文' },
  ];

  const getLanguageName = localeCode => {
    const language = languageOptions.find(option => option.code === localeCode);
    return language ? language.name : 'English';
  };

  const getLanguageCode = languageName => {
    const language = languageOptions.find(option => option.name === languageName);
    return language ? language.code : 'en';
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    surveyData.languagePreference ? getLanguageCode(surveyData.languagePreference) : 'en',
  );

  const [defaultLanguage, setDefaultLanguage] = useState(
    surveyData?.languagePreference ? getLanguageCode(surveyData.languagePreference) : 'en',
  );

  const startSurvey = async sender => {
    if (!surveyStarted) {
      const data = {
        currentSurveyPage: sender.currentPageNo,
        asmtType: assessment,
        version: 3,
        language: getLanguageName(survey.locale),
        state: 'started',
      };
      const result = await manageAssessmentSurveyInstanceAction(apiService, data);
      if (result && result.status === 200 && result.responseId) {
        setSurveyResponseId(result.responseId);
        setSurveyStarted(true);
      }
    }
  };

  const submitSurveyAnswer = async ({
    sender, questionCode, answer,
  }) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      asmtType: assessment,
      questionCode,
      answer,
      surveyResponseId,
      isCurrentSurveyPageChanged,
    };
    await submitAssessmentSurveyAnswerAction(apiService, data);
  };

  const completeSurvey = async (sender, surveyResponses) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      asmtType: assessment,
      version: 3,
      language: getLanguageName(survey.locale),
      state: 'completed',
      surveyResponseId,
      surveyResponses,
    };
    const response = await manageAssessmentSurveyInstanceAction(apiService, data);
    const redirectUrl = response?.redirectUrl;
    const timeout = window.setTimeout(() => {
      if (redirectUrl) {
        router.push(redirectUrl);
      } else {
        router.push(`/assessments/${assessment}/results`);
      }
    }, 5000);

    return () => clearTimeout(timeout);
  };

  const setSurveyLanguage = async ({
    sender, language,
  }) => {
    const data = {
      currentSurveyPage: sender.currentPageNo,
      surveyResponseId,
      language,
    };
    await setAssessmentSurveyLanguageAction(apiService, data);
  };

  const handleValueChanging = async (sender, { name, value, question }) => {
    if (!!question && !question.isVisible) return;

    if (name.endsWith('-Comment') && value === '') return;

    await submitSurveyAnswer({
      sender,
      questionCode: name,
      answer: value,
    });
    isCurrentSurveyPageChanged = false;
  };

  const handleAfterRenderPage = () => {
    const navigationButtons = document.querySelectorAll('.sd-btn');
    navigationButtons.forEach(button => {
      button.classList.remove('sd-btn'); // Remove the default class
    });
  };

  const handlePageChanging = async sender => {
    if (sender.currentPageNo === 0 && !surveyStarted) {
      await startSurvey(sender);
    }
  };

  const handlePageChanged = () => {
    isCurrentSurveyPageChanged = true;
  };

  const handleAfterRenderQuestion = (_, options) => {
    if (options.question.getType() === 'nouislider') {
      const sliderElement = options.htmlElement.querySelector('.noUi-target');
      if (sliderElement) {
        const noUiSliderInstance = sliderElement.noUiSlider;

        // set the tooltip visibility and set the format of value
        noUiSliderInstance.updateOptions({
          tooltips: options.question.value >= 1 ? [true] : [false],
          format: {
            to(value) {
              return Math.round(value);
            },
            from(value) {
              return Number(value);
            },
          },
        });

        // Show tooltip when the slider is touched or moved
        noUiSliderInstance.on('start', () => {
          noUiSliderInstance.updateOptions({
            tooltips: [true],
          });
        });

        noUiSliderInstance.on('change', () => {
          noUiSliderInstance.updateOptions({
            tooltips: [true],
          });
        });
      }
    }
  };

  const handleComplete = async sender => {
    const surveyResponses = sender.data;
    await completeSurvey(sender, surveyResponses);
  };

  const customCss = {
    navigation: {
      complete: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 rounded-full cursor-pointer group active:ring-4 border-slate-400 disabled:pointer-events-none text-white shadow-sm bg-charcoal-900 border-0 active:ring-charcoal-50 hover:bg-charcoal-600 active:bg-charcoal-600 w-full mr-auto',
      prev: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 bg-white rounded-full cursor-pointer group active:ring-4 disabled:pointer-events-none text-charcoal-800 border-1 border-charcoal-500 active:ring-charcoal-50 hover:bg-charcoal-50 active:bg-charcoal-25',
      next: 'block px-5 py-2 font-sans text-base font-semibold tracking-wider text-center transition-all duration-300 rounded-full cursor-pointer group active:ring-4 border-slate-400 disabled:pointer-events-none text-white shadow-sm bg-charcoal-900 border-0 active:ring-charcoal-50 hover:bg-charcoal-600 active:bg-charcoal-600 w-full mr-auto',
    },
  };

  useEffect(() => {
    if (surveyData && surveyData.responses) {
      survey.mergeData(surveyData.responses);
    }

    if (surveyData && surveyData.currentSurveyPage) {
      survey.currentPageNo = surveyData.currentSurveyPage;
    }

    survey.applyTheme(theme);
    survey.css = customCss;

    // Add event listeners
    survey.onAfterRenderPage.add(handleAfterRenderPage);
    survey.onValueChanging.add(handleValueChanging);
    survey.onCurrentPageChanging.add(handlePageChanging);
    survey.onCurrentPageChanged.add(handlePageChanged);
    survey.onAfterRenderQuestion.add(handleAfterRenderQuestion);
    survey.onComplete.add(handleComplete);

    // Clean up event listeners on unmount
    return () => {
      survey.onAfterRenderPage.remove(handleAfterRenderPage);
      survey.onValueChanging.remove(handleValueChanging);
      survey.onCurrentPageChanging.remove(handlePageChanging);
      survey.onCurrentPageChanged.remove(handlePageChanged);
      survey.onAfterRenderQuestion.remove(handleAfterRenderQuestion);
      survey.onComplete.remove(handleComplete);
    };
  }, [surveyStarted, survey]);

  // To resolve conditional rendering issue
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    survey.locale = selectedLanguage;
    survey.getAllQuestions().forEach(question => {
      const questionElement = document.querySelector(`#${question.id}`);
      if (questionElement) {
        handleAfterRenderQuestion(null, {
          question,
          htmlElement: questionElement,
        });
      }
    });

    if (selectedLanguage !== defaultLanguage && surveyStarted) {
      const updateSurveyLanguage = async () => {
        await setSurveyLanguage({ sender: survey, language: getLanguageName(selectedLanguage) });
        setDefaultLanguage(selectedLanguage);
      };
      updateSurveyLanguage();
    }
  }, [selectedLanguage]);

  // This prevents any client-specific content from rendering until after the initial mount
  if (!isMounted) {
    return null; // Alternatively, a fallback like a loading spinner
  }

  return (
    <div className="flex flex-col h-screen bg-slate-200">
      <SiteHeader pageTitle={pageTitle} location={location} />
      <div className="inline-flex items-center justify-end w-full px-2 py-4 text-white top-16 bg-charcoal-900 h-9">
        <div className="flex items-center gap-1">
          <Icon icon="globe" iconColor="primary" className="w-5 h-5 fill-white" />
          <select
            id="language"
            className="w-[5.25rem] p-1 text-sm font-bold text-white border-none bg-charcoal-900"
            value={selectedLanguage}
            onChange={e => setSelectedLanguage(e.target.value)}
          >
            {languageOptions.map(lang => (
              <option key={lang.code} value={lang.code}>
                {lang.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grow">
        <Survey model={survey} />
      </div>
      <Footer />
    </div>
  );
};

AssessmentSurvey.getAPIDataKey = () => 'asmtSurveyData';
AssessmentSurvey.getData = (apiService, { assessment, groupId }) => {
  let route = `assessments/${assessment}/survey-url`;
  if (groupId) route += `?group=${groupId}`;
  return apiService.get(route).then(data => ({ asmtSurveyData: data }));
};

AssessmentSurvey.propTypes = {
  params: PropTypes.shape({
    assessment: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    displayName: PropTypes.string,
    surveyJson: PropTypes.shape({
      pages: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          type: PropTypes.string,
          elements: PropTypes.arrayOf(
            PropTypes.shape({
              html: PropTypes.objectOf(PropTypes.string),
              name: PropTypes.string,
              type: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
    surveyData: PropTypes.shape({
      surveyResponseId: PropTypes.number,
      currentSurveyPage: PropTypes.number,
      state: PropTypes.string,
      responses: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.any]),
      ),
      languagePreference: PropTypes.string,
    }),
  }).isRequired,
};

export default withServerSideData(AssessmentSurvey);
