import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/Button';

const DeleteAvatar = ({ handleClose, handleConfirm, headerText }) => (
  <>
    <h3 className="text-left">{headerText}</h3>
    <div className="flex justify-end gap-2">
      <Button onClick={handleClose} variant="secondary">Cancel</Button>
      <Button onClick={handleConfirm} isWarning>Delete</Button>
    </div>
  </>
);

DeleteAvatar.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default DeleteAvatar;
