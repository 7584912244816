import { useContext } from 'react';

import { RouterContext } from '../../../context/Router';
import { CurrentUserContext } from '../../../context/CurrentUser';

import CopilotUserFeature from '../../../lib/features/Copilot';

const CopilotAuth = ({ children }) => {
  const { currentUser } = useContext(CurrentUserContext);
  const { router } = useContext(RouterContext);

  return new CopilotUserFeature(currentUser)
    .positive(() => children)
    .negative(() => {
      router.replace('/404');
      return null;
    })
    .execute();
};

export default CopilotAuth;
