const uploadTeamAvatarAction = (apiService, teamId, file) =>
  apiService.upload(`team/${teamId}/image`, file);

const deleteTeamAvatarAction = (apiService, teamId) =>
  apiService.delete(`team/${teamId}/image`);

export {
  uploadTeamAvatarAction,
  deleteTeamAvatarAction,
};
