import { find } from 'lodash';
import React from 'react';
import ProfileChart from '../ProfileChart';

const dataTransform = props => {
  if (!props.profiles) {
    return {
      hideAvatar: props.hideAvatar,
      cultureDisclaimer: props.cultureDisclaimer,
      cultureLinkText: props.cultureLinkText,
      cultureUrl: props.cultureUrl,
      hasResizeSupport: props.hasResizeSupport,
      location: props.location,
      profiles: [props],
      hideProfileToggleText: props.hideProfileToggleText,
      fullScreenRef: props.fullScreenRef,
      isHistoryHidden: props.isHistoryHidden,
      onClick: props.onClick,
      compareProfile: props.compareProfile,
      myProfileVisibility: props.myProfileVisibility,
      dimensionLabelGroups: props.dimensionLabelGroups,
      teamProfiles: props.teamProfiles,
      updateProfiles: props.updateProfiles,
      isProfile: props.isProfile,
      isHistoryProfile: props.isHistoryProfile,
      displayDimensionLink: props.displayDimensionLink,
      isAdviceComparison: props.isAdviceComparison,
      isDashboard: props.isDashboard,
    };
  }
  return props;
};

const checkForCulture = props => !!find(props.profiles, { type: 'culture' });
const checkForOrg = props => !!find(props.profiles, { type: 'org' });
const checkForOrgSegments = props => !!find(props.profiles, { type: 'segment' });

const ProfileChartTransformer = props => {
  const newProps = dataTransform(props);
  return (
    <ProfileChart
      {...newProps}
      hasOrg={checkForOrg(newProps)}
      hasCulture={checkForCulture(newProps)}
      hasSegment={checkForOrgSegments(newProps)}
      cultureDisclaimer="Culture profiles represent many people, but are not absolutes."
      cultureUrl="https://aperian.zendesk.com/hc/en-us/articles/360038799154-How-Culture-Placements-on-the-Profile-are-Determined"
      cultureLinkText="How we create culture profiles"
    />
  );
};

export default ProfileChartTransformer;
export { dataTransform };
