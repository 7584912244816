import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Combobox } from '@headlessui/react';

import { propTypes as selectedComparablesPropTypes } from '../../../propTypes/selectedComparables';
import Button from '../../common/Button';
import Tag from '../../common/Tag';
import Icon from '../../common/Icon';

const SelectedComparables = ({
  selectedComparables,
  filteredComparables,
  filterValue,
  onRemove,
  updateSelectedComparables,
  onFilter,
  handleClear,
  handleCreateComparison,
}) => {
  const filterInputRef = useRef(null);

  const nonSelectedFilteredItems = useMemo(() => {
    const selectedComparableIds = selectedComparables?.map(sc => sc.id);
    return filteredComparables?.filter(fc => !selectedComparableIds.includes(fc.id));
  }, [selectedComparables, filteredComparables]);

  if (!filteredComparables || !selectedComparables) return null;

  const handleCompareSelect = item => {
    updateSelectedComparables(item, true);
  };

  return (
    <Combobox
      value={filterValue}
      onChange={handleCompareSelect}
    >
      <div className="relative">
        <Icon icon="search" className="absolute flex-shrink-0 w-4 h-4 top-3.5 left-4" iconColor="dark-gray" />
        {/* Input */}
        <Combobox.Input
          ref={filterInputRef}
          className="w-full px-4 py-2 pl-10 border !border-gray-200 rounded-md focus:ring-1 focus:ring-inset focus:ring-rust-500 focus:shadow-input"
          placeholder="Start typing..."
          onChange={event => onFilter(event.target.value)}
        />

        {/* Dropdown */}
        <Combobox.Options className="absolute z-10 w-full mt-2 bg-white border rounded-md shadow-lg">
          {nonSelectedFilteredItems.map(item =>
            (item.type === 'segment'
              ? (item.values?.map(value => {
                const isFilterMatch = value.name.toLowerCase().includes(filterValue.toLowerCase());
                return isFilterMatch ? (
                  <Combobox.Option
                    key={value.id}
                    value={{
                      ...item,
                      values: item.values.map(v =>
                        (v.id === value.id
                          ? { ...v, checked: true }
                          : v
                        )),
                    }}
                    className={({ active }) =>
                      `cursor-pointer px-4 py-2 ${active ? 'bg-ivory-100 border-l-4 border-l-rust-500' : ''}`}
                  >
                    {item.name} - {value.name}
                  </Combobox.Option>
                ) : null;
              })
              ) : (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    `cursor-pointer px-4 py-2 ${active ? 'bg-ivory-100 border-l-4 border-l-rust-500' : ''}`}
                >
                  {item.name}
                </Combobox.Option>
              )))}
        </Combobox.Options>

      </div>
      {selectedComparables.length > 0 ? (
        <>
          <p className="visible block my-3 font-sans text-sm font-medium leading-6 text-left text-gray-700">
            Selected Profiles
          </p>
          <ul className="flex flex-wrap items-center flex-grow gap-2 px-4 mb-2 list-none">
            {selectedComparables.map(({ id, name, type, values }) => (
              type === 'segment'
                ? (
                  values.map(({ name: valueName, id: valueId, checked }) => (checked ? (
                    <Tag
                      key={`segment-${valueId}`}
                      text={`${name} / ${valueName}`}
                      onIconClick={() => onRemove({ type, id, valueId }, false)}
                    />
                  ) : null)))
                : (
                  <Tag
                    key={id}
                    text={name}
                    onIconClick={() => onRemove({ type, id }, false)}
                  />
                )))}
            <li className="flex gap-4 ml-auto">
              <Button
                variant="tertiary"
                className="w-full bg-transparent md:w-auto"
                onClick={filterValue ? () => onFilter('') : handleClear}
                isSmall
              >
                Clear All
              </Button>
              <Button
                className="w-full md:w-auto "
                variant="primary"
                onClick={handleCreateComparison}
                isSmall
              >
                Create
              </Button>
            </li>
          </ul>
        </>
      ) : null}

    </Combobox>
  );
};

SelectedComparables.propTypes = {
  selectedComparables: selectedComparablesPropTypes,
  filteredComparables: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    })),
  filterValue: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  handleCreateComparison: PropTypes.func,
  updateSelectedComparables: PropTypes.func.isRequired,
};

SelectedComparables.defaultProps = {
  selectedComparables: [],
  filteredComparables: [],
  handleClear: null,
  handleCreateComparison: null,
};

export default SelectedComparables;
