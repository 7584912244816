import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import ElementQuery from 'react-eq';

import { Link } from 'react-router';
import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import withServerSideData from '../../../HOC/withServerSideData';

import formatDate from '../../../lib/dateFormatter';
import Button from '../../../components/common/Button';
import DeleteCohortButton from '../../../components/Cohorts/DeleteCohortButton';
import CohortParticipantsTable from '../../../components/Cohorts/CohortParticipantsTable';
import CopyText from '../../../components/CopyText';
import BackArrowLink from '../../../components/common/BackArrowLink';

import getDownloadFileName from '../../../lib/fileDownload';
import Notification from '../../../components/Notifications/Notification';
import { deleteCohortAction, cohortsDirectoryDownloadAction, deactivateCohortAction } from '../../../actions/cohorts';
import { CurrentUserContext } from '../../../context/CurrentUser';

import { determineVirtualEventCohort } from '../../../lib/cohorts/determineCohortByType';

const queries = { 'card-layout': 400 };

const CohortsOverview = ({
  initialData: {
    name,
    displayName,
    code,
    sfJobCode,
    sfLink,
    linkExpiryDate,
    evaluationLink,
    participants,
    type,
    cohortTypeId,
    deactivated: isCohortDeactived,
    org: {
      name: orgName,
      auth0ConnectionId,
    },
    cohortPlans,
    cohortEventsData,
    linkedGroupId,
    linkedGroup,
    linkedTeamId,
    linkedTeam,
  },
}) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { currentUser: { allowSkillsTracking } } = useContext(CurrentUserContext);
  const [notification, setNotification] = useState(null);
  const [deactivated, setDeactivated] = useState(isCohortDeactived);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [participantsList, setParticipantsList] = useState(participants);

  const handleConfirmDelete = () => setConfirmDelete(true);
  const handleCancelDelete = () => setConfirmDelete(false);

  const handleSetRefresh = () => setRefreshRequired(true);

  const formatMeetingDateTime = meetingDatetime => new Date(meetingDatetime).toLocaleString([], {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  });

  useEffect(() => {
    if (refreshRequired) {
      const fetchData = async () => {
        const cohortData = await CohortsOverview.getData(apiService, { code });
        setParticipantsList(cohortData.cohort.participants);
        setRefreshRequired(false);
      };
      fetchData()
        .catch(console.error);
    }
  }, [refreshRequired]);

  const handleCompleteDelete = () => {
    deleteCohortAction(apiService, code)
      .then(() => router.push('/cohorts/search'))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not remove member from cohort: ${err}`,
        });
      });
  };

  const handleDeactivateCohort = () => {
    deactivateCohortAction(apiService, code)
      .then(() => {
        setDeactivated(true);
        setNotification({
          type: 'success',
          message: 'Your cohort enrollment link is now deactivated. Please inform your members that enrollment is closed.',
        });
      })
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not deactivate cohort enrollment link: ${err}`,
        });
      });
  };

  const userError = err => {
    setNotification({
      type: 'warning',
      message: `Could not remove member from cohort: ${err}`,
    });
  };

  const exportParticipantsCSV = e => {
    const { dataset: { code: cohortCode } } = e.currentTarget;
    cohortsDirectoryDownloadAction(apiService, cohortCode)
      .then(async response => ({
        filename: getDownloadFileName(response),
        blob: await response.blob(),
      }))
      .then(({ blob, filename }) => saveAs(blob, filename))
      .catch(err => {
        setNotification({
          type: 'warning',
          message: `Could not download cohort directory: ${err}`,
        });
      });
  };

  const renderCohortStatus = () => {
    if (new Date() > new Date(linkExpiryDate)) {
      return 'Expired';
    }
    if (deactivated) {
      return 'Deactivated';
    }
    return 'Active';
  };

  const renderCohortPlans = () => {
    const plansString = cohortPlans.join(', ');
    return plansString;
  };

  const enrollmentLink = `${process.env.SITE_URL}/enroll/cohorts/${code}${auth0ConnectionId ? `?sp=${auth0ConnectionId}` : ''}`;
  return (
    <>
      <div className="flex mx-0 mt-0 mb-3 text-center align-self-start text-uppercase text-md">
        <BackArrowLink to="/cohorts/search">
          Back to Cohorts
        </BackArrowLink>
      </div>
      <div className="admin-dashboard-layout__header">
        <h2 className="mb-1 font-heading-2">{displayName}</h2>
        {(determineVirtualEventCohort(cohortTypeId)) ? null : (
          <Button
            variant="primary"
            isSmall
            to={`/cohorts/${code}/edit`}
          >Edit
          </Button>
        )}
      </div>
      <Notification {...notification} />
      <div className="mb-1 container--flat">
        <div>
          <h2 className="mb-4 font-heading-2">Cohort Details</h2>
          <div className=" md:grid md:grid-cols-3">
            <p className="font-medium"><b>Enrollment Link:</b></p>
            <span><CopyText text={enrollmentLink} showText /></span>
          </div>
          <div>
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Enrollment Link Type:</b> </p>
              <span>{auth0ConnectionId ? 'SSO' : 'Email / Password'}</span>
            </div>
            {linkExpiryDate && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>Link Expiration:</b> </p>
                <span>{formatDate(linkExpiryDate)}</span>
              </div>
            )}
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Cohort Code:</b> </p>
              <span>{code}</span>
            </div>
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Cohort Status:</b> </p>
              <span>{renderCohortStatus()}</span>
            </div>
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Cohort Type:</b> </p>
              <span>{type}</span>
            </div>
            {linkedTeam && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>Linked Team:</b> </p>
                <span>
                  <Link to={`/profile/teams/${linkedTeamId}`}>
                    {linkedTeam}
                  </Link>
                </span>
              </div>
            )}
            {linkedGroup && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>Linked Group:</b> </p>
                <span>
                  <Link to={`/assessments/ibi/group/${linkedGroupId}`}>
                    {linkedGroup}
                  </Link>
                </span>
              </div>
            )}
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Plan{cohortPlans.length > 1 ? 's' : null}:</b> </p>
              <span className="md:col-span-2">{renderCohortPlans()}</span>
            </div>
            <div className="md:grid md:grid-cols-3">
              <p className="font-medium"><b>Evaluation Link:</b> </p>
              <span>{evaluationLink}</span>
            </div>
            {(determineVirtualEventCohort(cohortTypeId)) ? (
              <>
                <div className="md:grid md:grid-cols-3">
                  <p className="font-medium"><b>Internal Name:</b> </p>
                  <span>{name}</span>
                </div>
                <h2 className="mt-6 mb-4 font-heading-2">Meeting Details</h2>
                {cohortEventsData.map(event => (
                  <div key={event.meetingId} className="mb-4 meeting-item">
                    <div className="md:grid md:grid-cols-3">
                      <p className="font-medium"><b>Meeting Date/Time:</b></p>
                      <span>{formatMeetingDateTime(event.meetingDatetime)}</span>
                    </div>
                    <div className="md:grid md:grid-cols-3">
                      <p className="font-medium"><b>Meeting Link:</b></p>
                      <span>https://aperian.zoom.us/j/{event.meetingId}</span>
                    </div>
                    <div className="md:grid md:grid-cols-3">
                      <p className="font-medium"><b>Facilitator:</b> </p>
                      <span>{event.facilitator}</span>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
        </div>
        {orgName && (
          <div>
            <h2 className="mt-6 mb-4 font-heading-2">Client Details</h2>
            {orgName && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>Organization Name:</b> </p>
                <span>{orgName}</span>
              </div>
            )}

            {sfJobCode && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>SF Job Code:</b> </p>
                <span>{sfJobCode}</span>
              </div>
            )}
            {sfLink && (
              <div className="md:grid md:grid-cols-3">
                <p className="font-medium"><b>SF Link:</b> </p>
                <a
                  href={sfLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >{sfLink}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <CohortParticipantsTable
        onExportParticipants={exportParticipantsCSV}
        participants={participantsList}
        cohortName={displayName}
        cohortCode={code}
        setReload={handleSetRefresh}
        error={userError}
        setNotification={setNotification}
        cohortType={type}
        allowSkillsTracking={allowSkillsTracking}
      />
      <div className="flex flex-col items-end gap-2 mb-6">
        <div className="mt-2 text-center">
          <Button
            isSmall
            disabled={deactivated}
            onClick={handleDeactivateCohort}
            variant="secondary"
          >Deactivate enrollment link
          </Button>
        </div>
        <ElementQuery queries={queries}>
          <div className="box-border w-11/12 mt-1 text-center ">
            <DeleteCohortButton
              isDeleteConfirmed={confirmDelete}
              confirmDelete={handleConfirmDelete}
              cancelDelete={handleCancelDelete}
              completeDelete={handleCompleteDelete}
            />
          </div>
        </ElementQuery>
      </div>
    </>
  );
};

CohortsOverview.getAPIDataKey = () => 'cohort';

CohortsOverview.getData = (apiService, { code }) => apiService.get(`cohorts/${code}/overview`)
  .then(cohort => ({ cohort }));

CohortsOverview.propTypes = {
  initialData: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    cohortTypeId: PropTypes.number.isRequired,
    sfJobCode: PropTypes.string,
    sfLink: PropTypes.string,
    linkExpiryDate: PropTypes.string,
    evaluationLink: PropTypes.string,
    deactivated: PropTypes.bool.isRequired,
    participants: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        surveyStatus: PropTypes.string,
        dateJoined: PropTypes.string,
        email: PropTypes.string,
        ibiSurveyStatus: PropTypes.string,
        attendanceStatus: PropTypes.string,
        attendanceDuration: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        culturalAgilitySkillStatus: PropTypes.string,
        teamCollaborationSkillStatus: PropTypes.string,
      }),
    ).isRequired,
    org: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      auth0ConnectionId: PropTypes.string,
    }),
    cohortPlans: PropTypes.arrayOf(PropTypes.string),
    cohortEventsData: PropTypes.arrayOf(
      PropTypes.shape({
        meetingId: PropTypes.number.isRequired,
        meetingDatetime: PropTypes.string.isRequired,
        facilitator: PropTypes.string.isRequired,
      }),
    ),
    linkedGroupId: PropTypes.number,
    linkedGroup: PropTypes.string,
    linkedTeamId: PropTypes.number,
    linkedTeam: PropTypes.string,
  }),
  params: PropTypes.shape({
    code: PropTypes.string,
  }).isRequired,
};

CohortsOverview.defaultProps = {
  initialData: {
    evaluationLink: '',
    cohortEventsData: [],
    linkedGroupId: '',
    linkedGroup: '',
    linkedTeamId: '',
    linkedTeam: '',
  },
};

export default withServerSideData(CohortsOverview);
