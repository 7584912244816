import React, { forwardRef, useContext } from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';

import { CurrentUserContext } from '../../../context/CurrentUser';

import Avatar from '../../common/Avatars/Avatar';
import StreamingText from '../../StreamingText';
import CopyText from '../../CopyText';
import AperianMonogram from '../../AperianMonogram';

import { stripMarkdown } from '../../../lib/stripMarkdown';

const ChatHistory = forwardRef(({ chatHistory, onCopy, scrollToBottom }, ref) => {
  const { currentUser } = useContext(CurrentUserContext);
  return chatHistory.map(({ id, question, response, stream }) => (
    <div key={id} className="flex flex-col justify-end w-full h-full">
      <div className="flex flex-row w-full max-w-full gap-2 mb-2">
        <Avatar
          className="!self-start flex-shrink-0 basis-10 mt-1"
          avatarUrl={currentUser.avatarUrl}
          avatarImgAlt={currentUser.firstName}
        />
        <p className="p-3 m-0 text-base text-left whitespace-pre-line rounded-2xl ring-1 ring-inset ring-gray-900/5">
          {question}
        </p>
      </div>

      <div className="flex flex-row w-full max-w-full gap-2 mb-2">
        <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 p-1.5 mt-1 rounded-full basis-10 bg-charcoal-900">
          <AperianMonogram />
        </div>
        <div className={`relative p-3 m-0 [&_p]:text-base [&_span]:text-base [&_>span]:font-light [&_h3]:text-xl [&_h4]:text-base [&_ol]:mb-4 [&_ul>li]:text-base [&_ol>li]:text-base text-left whitespace-pre-line rounded-2xl bg-gray-50 ring-1 ring-inset ring-gray-900/5${response ? ' w-full' : ''}`}>
          {response
            ? (
              <>
                {(response && stream)
                  ? (
                    <StreamingText
                      ref={ref}
                      text={response}
                      speed={10}
                      scrollToBottom={scrollToBottom}
                    />
                  )
                  : <Markdown>{response}</Markdown>}
                <div className="absolute right-0 -bottom-5">
                  <CopyText
                    text={stripMarkdown(response)}
                    handleCopy={onCopy}
                  />
                </div>
              </>
            )
            : (
              <div className="flex items-center justify-center h-full space-x-1">
                <span className="sr-only">Loading Response...</span>
                <div className="h-2 w-2 bg-ivory-900 rounded-full animate-bounce [animation-delay:-0.3s]" />
                <div className="h-2 w-2 bg-ivory-900 rounded-full animate-bounce [animation-delay:-0.15s]" />
                <div className="w-2 h-2 rounded-full bg-ivory-900 animate-bounce" />
              </div>
            )}
        </div>
      </div>
    </div>
  ),
  );
});

ChatHistory.displayName = 'ChatHistory';

ChatHistory.propTypes = {
  chatHistory: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      title: PropTypes.string,
      createdAt: PropTypes.string,
    }),
  ).isRequired,
  scrollToBottom: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
};

ChatHistory.defaultProps = {
  onCopy: null,
};

export default ChatHistory;
