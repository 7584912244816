import React, { useContext, useEffect, useState } from 'react';

import { Context } from '../../../../context/ContextProvider';

import UserSearchForm from '../../Users/UserSearchForm';
import OrganizationUserList from './OrganizationUserList';
import UserSearch from '../../Users/UserSearch';

import { renderNotifications, getErrorMessage } from '../../RenderNotification';
import OrganizationAddMemberAction from '../../../../actions/organizations/organizationAddMember';
import OrganizationRemoveMemberAction from '../../../../actions/organizations/organizationRemoveMember';

const OrganizationManageUser = () => {
  const { apiService, router: { params: { id } } } = useContext(Context);
  const [notifications, setNotifications] = useState(null);
  const [orgId, setOrgId] = useState(id);
  const [usersArray] = useState([]); // eslint-disable-line no-unused-vars

  useEffect(() => {
    setOrgId(id);
  }, []);

  const displayNotification = notification => {
    setNotifications(notification);
    let notificationTimer;
    if (notificationTimer) window.clearTimeout(notificationTimer);
    notificationTimer = setTimeout(() => { setNotifications(null); }, 3000);
  };

  const onAdd = userId => new OrganizationAddMemberAction(apiService)
    .execute(orgId, userId)
    .then(({ updatedUser }) => {
      displayNotification({
        type: 'confirm',
        message: 'User added.',
      });
      return updatedUser;
    })
    .catch(err => {
      displayNotification({
        type: 'warning',
        message: `Error adding user: ${getErrorMessage(err)}`,
      });
    });

  const onRemove = userId => new OrganizationRemoveMemberAction(apiService)
    .execute(orgId, userId)
    .then(({ updatedUser }) => {
      displayNotification({
        type: 'confirm',
        message: 'User removed.',
      });
      return updatedUser;
    })
    .catch(err => {
      displayNotification({
        type: 'warning',
        message: `Error removing user: ${getErrorMessage(err)}`,
      });
    });

  return (
    <UserSearch
      render={({
        firstName,
        lastName,
        email,
        onSearchParamChange,
        users,
        updateUserState,
        notification,
      }) => {
        const onAddWithUpdateUserState = userId => {
          onAdd(userId).then(updateUserState);
        };
        const onRemoveWithUpdateUserState = userId => {
          onRemove(userId).then(updateUserState);
        };
        return (
          <>
            {renderNotifications([notification, notifications])}
            <div className="grid gap-2 mt-4 mb-10 md:grid-cols-6">
              <div className="md:col-span-2">
                <h5 className="mb-4 font-serif text-xl">Manage Users in Organization</h5>
              </div>
              <div className="flex flex-col gap-4 pb-4 overflow-x-auto md:col-span-4">
                <UserSearchForm
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  onChange={onSearchParamChange}
                />
              </div>
            </div>
            <div className="mb-4 overflow-x-auto overflow-y-auto">
              <OrganizationUserList
                orgId={orgId}
                users={users}
                onAdd={userId => onAddWithUpdateUserState(userId)}
                onRemove={userId => onRemoveWithUpdateUserState(userId)}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default OrganizationManageUser;
