import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Context } from '../../../../context/ContextProvider';

import DropdownMenu from '../../../common/Dropdowns/DropdownMenu';

import { languageOptions } from '../../../../lib/language';

const ProfileHeader = ({ retrieveTranslations }) => {
  const { selectedLanguage, onChangeLanguage } = useContext(Context);
  useEffect(() => { retrieveTranslations(); }, [selectedLanguage]);
  return (
    <header className="flex flex-row items-baseline justify-between md:items-center print:hidden">
      <h4 className="mb-4">
        About My Profile
      </h4>
      <div className="mb-5">
        <DropdownMenu
          options={languageOptions('profile')}
          value={selectedLanguage || 'en'}
          onChangeValue={onChangeLanguage}
          isTransparent
        />
      </div>
    </header>
  );
};

ProfileHeader.propTypes = {
  retrieveTranslations: PropTypes.func.isRequired,
};

export default ProfileHeader;
