import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import withServerSideData from '../../../HOC/withServerSideData';

import { APIContext } from '../../../context/API';

import CopilotChatbot from '../../../components/CopilotChatbot';
import ThreadHistory from '../../../components/Administration/Copilot/ThreadHistory';
import CopilotConfiguration from '../../../components/Administration/Copilot/CopilotConfiguration';

import { getCopilotConfig, updateCopilotConfig } from '../../../actions/copilot';

const CopilotAdmin = ({ location, initialData }) => {
  const { apiService } = useContext(APIContext);
  const [copilotConfig, setCopilotConfig] = useState(initialData.configuration);
  const [threads, setThreads] = useState(initialData.threadHistory);

  const handleInput = e => {
    const { target: { name, value } } = e;
    const formattedValue = name === 'pineconeThreshold' ? Number(parseFloat(value).toFixed(1)) : value;
    setCopilotConfig(prev => ({ ...prev, [name]: formattedValue }));
  };

  const handleUpdateConfig = async e => {
    e.preventDefault();
    await updateCopilotConfig(apiService, copilotConfig);
    const { threadHistory, configuration } = await getCopilotConfig(apiService);
    setCopilotConfig(configuration);
    setThreads(threadHistory);
  };

  return (
    <div className="grid h-full gap-4 p-4 lg:px-0 lg:py-4 xl:grid-cols-8">
      <div className="flex flex-col xl:px-4 xl:col-span-5">
        <h5 className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700">Chat</h5>
        <CopilotChatbot threadId={location.query.c} />
      </div>
      <div className="xl:col-span-3">
        {threads.length > 0 ? (
          <>
            <h5 className="visible block mb-2 font-sans text-sm font-medium leading-6 text-left text-gray-700">
              History
            </h5>
            <ThreadHistory threadHistory={threads} />
          </>
        ) : null}
        <CopilotConfiguration
          configuration={copilotConfig}
          handleInput={handleInput}
          handleUpdate={handleUpdateConfig}
        />
      </div>
    </div>
  );
};

CopilotAdmin.propTypes = {
  location: PropTypes.shape({
    query: PropTypes.shape({
      c: PropTypes.string,
    }),
  }).isRequired,
  initialData: PropTypes.shape({
    configuration: PropTypes.shape({
      promptText: PropTypes.string,
      noMatchText: PropTypes.string,
      pineconeThreshold: PropTypes.number,
      openAiModel: PropTypes.string,
    }).isRequired,
    threadHistory: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
  }).isRequired,
};

CopilotAdmin.getAPIDataKey = () => 'copilotAdmin';
CopilotAdmin.getData = apiService => apiService.get('copilot/admin')
  .then(({ threadHistory, configuration }) => ({ copilotAdmin: { threadHistory, configuration } }));

export default withServerSideData(CopilotAdmin);
