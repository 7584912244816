/* eslint-disable new-cap */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { APIContext } from '../../context/API';

import { saveThreadMessage } from '../../actions/copilot';

import ChatHistory from './ChatHistory';
import Button from '../common/Button';

const japaneseRegex = /[\u3000-\u303F\u3040-\u30FF\u4E00-\u9FFF]/;

const CopilotChatbot = ({ defaultMessage, threadId, onSubmit, onCopy }) => {
  const { apiService } = useContext(APIContext);

  const [questionText, setQuestionText] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(threadId || null);
  const [textareaHeight, setTextareaHeight] = useState(null);

  const textContainerRef = useRef();

  useEffect(() => {
    const getSingleThread = async id => {
      const { messages } = await apiService.get(`copilot/thread/${id}`);
      setChatHistory(messages.map(message => ({ ...message, stream: false })));
    };
    if (threadId) {
      getSingleThread(threadId);
    }
  }, [threadId]);

  const scrollToBottom = () => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop = textContainerRef.current.scrollHeight;
    }
  };

  // Scroll to the bottom when new content is added
  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleQueryChange = e => {
    if (questionText.length <= 1) {
      setTextareaHeight(e.target.style.height);
    }
    setQuestionText(e.target.value);
    e.target.style.height = 'auto'; // Reset height to calculate scroll height correctly
    e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on content
  };

  const callCopilot = async question => {
    try {
      const response = await saveThreadMessage(apiService, question, currentThreadId);
      if (onSubmit) {
        await onSubmit(apiService, question);
      }

      setChatHistory(prevHistory => {
        const newHistory = [...prevHistory];
        const lastIndex = newHistory.length - 1;
        newHistory[lastIndex] = { ...newHistory.at(-1), response: response.response };
        return newHistory;
      });
      if (!currentThreadId) setCurrentThreadId(response.threadId);
    } catch (error) {
      console.error('Error querying the assistant', error);
    }
  };

  const handleQuerySubmit = async question => {
    setQuestionText('');
    setChatHistory(prev => ([
      ...prev,
      {
        id: uuidv4(),
        question,
        answer: null,
        stream: true,
      }]));
    await callCopilot(question);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter' && !e.shiftKey && !japaneseRegex.test(questionText)) {
      e.target.style.height = textareaHeight;
      e.preventDefault();
      handleQuerySubmit(questionText);
    }
  };

  return (
    <div ref={textContainerRef} className="flex flex-col items-center justify-between flex-grow h-auto gap-8 p-4 overflow-y-auto bg-white rounded-lg lg:py-8 lg:px-6 max-h-[70vh]">
      {chatHistory.length > 0
        ? (
          <ChatHistory
            chatHistory={chatHistory}
            scrollToBottom={scrollToBottom}
            ref={textContainerRef}
            onCopy={onCopy}
          />
        ) : (
          <div className="flex flex-col items-center justify-center w-10/12 gap-4 text-center grow">
            <svg className="w-20 h-20 fill-slate-400" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 113 113"><path className="cls-1" d="M56.22,63.55h-.26c-14.98,0-27.13-12.14-27.13-27.13V9.79c0-.52.42-.94.94-.94h26.38c15.12,0,27.38,12.26,27.38,27.38h0c0,15.09-12.23,27.33-27.33,27.33Z" /><path className="cls-1" d="M55.71,106.27c19.7,0,35.76-15.61,36.47-35.14.03-.74-.57-1.36-1.32-1.36H20.55c-.75,0-1.34.62-1.32,1.36.72,19.53,16.77,35.14,36.48,35.14Z" /></svg>
            <div>{defaultMessage}</div>
          </div>
        )}
      <form className="w-full" onSubmit={handleQuerySubmit}>
        <div className="box-border flex items-center justify-between p-1 border-0 rounded-md ring-1 ring-inset ring-stone-400 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus-within:ring-rust-500 focus-within:shadow-input sm:text-sm sm:leading-6">

          <textarea
            value={Parser(DOMPurify.sanitize(questionText))}
            onChange={handleQueryChange}
            onKeyDown={handleKeyDown}
            placeholder="Ask Copilot a question"
            className="block py-0 overflow-hidden overflow-y-auto font-sans font-normal border-0 resize-none max-h-48 grow focus:ring-0 focus:border-0"
            rows="1"
          />
          <Button
            className="self-end border-0"
            leadingButtonIcon="send"
            iconColor="dark-gray"
            onClick={handleQuerySubmit}
            isCircle
          />
        </div>
      </form>
    </div>
  );
};

CopilotChatbot.propTypes = {
  threadId: PropTypes.string,
  defaultMessage: PropTypes.node,
  onSubmit: PropTypes.func,
  onCopy: PropTypes.func,
};

CopilotChatbot.defaultProps = {
  threadId: null,
  defaultMessage: null,
  onSubmit: null,
  onCopy: null,
};

export default CopilotChatbot;
