/* eslint-disable new-cap */
import React from 'react';
import Parser from 'html-react-parser';
import DOMPurify from 'isomorphic-dompurify';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import Avatar from '../../../common/Avatars/Avatar';

const Tip = ({
  tip,
  currentTipId,
  hasPremiumAccess,
  trackClickLink,
}) => (
  <div
    data-show={currentTipId === tip.id}
    className="opacity-0 data-[show=true]:opacity-100 transition-opacity ease-in duration-500"
  >
    <div
      data-show={currentTipId === tip.id}
      className="hidden data-[show=true]:flex flex-wrap gap-4 xs:flex-nowrap"
    >
      {tip.guidesLink && (
      <Avatar
        isCulture
        avatarUrl={`${tip.flagIcon}`}
        avatarImgAlt={`${tip.title} flag`}
        size="lg"
        className="!min-w-20 !min-h-20 rounded-md drop-shadow-xl"
      />
      )}
      <div className="flex flex-col">
        {hasPremiumAccess ? (
          <a
            href={`${tip.guidesLink}${tip.tipLink}`}
            onClick={() => trackClickLink(tip)}
          >
            <h5 className="mb-2 text-lg">{tip.title}</h5>
          </a>
        ) : (<Link to="/dashboard?purchase&plan=starter-6mopaid">{tip.title}</Link>)}
        <div className="[&>p]:text-sm [&>p]:md:text-base">
          {Parser(DOMPurify.sanitize(tip.text))}
        </div>
      </div>
    </div>
  </div>
);

Tip.propTypes = {
  hasPremiumAccess: PropTypes.bool,
  currentTipId: PropTypes.number,
  trackClickLink: PropTypes.func,
  tip:
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      text: PropTypes.string,
      tipLink: PropTypes.string,
      flagIcon: PropTypes.string,
      guidesLink: PropTypes.string,
      tipType: PropTypes.string,
    }),
};

Tip.defaultProps = {
  hasPremiumAccess: true,
  currentTipId: '',
  trackClickLink: () => { },
  tip: {},
};

export default Tip;
