import React from 'react';
import PropTypes from 'prop-types';
import OrganizationManageUserButton from '../OrganizationManageUserButton';
import Table from '../../../../common/Table';

const OrganizationUserList = ({ orgId, users, onAdd, onRemove }) => {
  if (!users || !users.length) return null;

  return (
    <Table
      className="w-full min-w-full divide-y divide-gray-300 md:table-fixed"
      emptyMessage="There are no scheduled upcoming sessions at this time."
      columns={[
        {
          title: 'Last Name',
          headerStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'md:w-1/5 break-words px-3 py-4 text-sm',
          field: 'lastName',
          render: rowData => rowData.lastName,
        },
        {
          title: 'First Name',
          headerStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'md:w-1/5 break-words px-3 py-4 text-sm',
          field: 'firstName',
          render: rowData => rowData.firstName,
        },
        {
          title: 'Email',
          headerStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'break-words md:w-1/3 px-3 py-4 text-sm',
          field: 'email',
          render: rowData => rowData.email,
        },
        {
          title: 'Organizations',
          headerStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'md:w-1/5 break-words px-3 py-4 text-sm',
          field: 'organizations',
          render: rowData => rowData.organizations.map(org => <span className="flex" key={org.id}>{org.name}</span>),
        },
        {
          title: 'Actions',
          headerStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
          cellStyle: 'md:w-1/6 whitespace-nowrap px-3 py-4 text-sm',
          render: rowData => (
            <OrganizationManageUserButton
              orgId={orgId}
              {...rowData}
              onAdd={onAdd}
              onRemove={onRemove}
            />
          ),
        },
      ]}
      data={users}
      options={{
        rowStyle: 'even:bg-ivory-500',
        headersStyle: 'py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900',
        bodyStyle: 'divide-y',
      }}
    />
  );
};

OrganizationUserList.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    organizations: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
    })),
  })).isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default OrganizationUserList;
