import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';
import { fillColorMap } from '../index.js';

const QuotationIcon = ({ className, iconColor }) => (
  <svg className={`${className} ${fillColorMap[iconColor]}`} width="30" height="50" viewBox="0 0 63 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50.9427 8.89692C40.1997 -2.96564 22.7877 -2.96564 12.0573 8.89692C1.31425 20.7595 1.31425 39.986 12.0573 51.8347C14.6772 54.7276 17.6858 56.8731 20.9074 58.3542V85L50.9427 51.8347C61.6858 39.9721 61.6858 20.7456 50.9427 8.89692Z" />
  </svg>
);

QuotationIcon.propTypes = iconPropTypes;
QuotationIcon.defaultProps = defaultIconProps;

export default QuotationIcon;
