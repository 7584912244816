import { useCallback, useEffect, useRef } from 'react';

export function useTimeout(callback, delay) {
  const timeoutRef = useRef(null);
  const callbackRef = useCallback(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (typeof delay === 'number') {
      timeoutRef.current = window.setTimeout(callbackRef, delay);
    }
    return () => window.clearTimeout(timeoutRef.current);
  }, [delay]);

  return timeoutRef;
}
