class UpdateOrganizationAction {
  constructor(apiService) {
    this.apiService = apiService;
  }

  execute(id, data) {
    return this.apiService.patch(`organizations/${id}`, data);
  }
}

export default UpdateOrganizationAction;
