import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types';

const StreamingText = forwardRef(({ text, speed, scrollToBottom }, ref) => {
  const [displayedText, setDisplayedText] = useState('');

  // Memoize the text to avoid re-renders unless the text itself changes
  const memoizedText = useMemo(() => text, [text]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      setDisplayedText(prev => prev + memoizedText[index]);
      index += 1;
      if (index >= memoizedText.length) clearInterval(interval);
    }, speed);

    return () => clearInterval(interval);
  }, [memoizedText, speed]);

  // Scroll to the bottom when new content is added
  useEffect(() => {
    if (ref?.current) scrollToBottom();
  }, [displayedText]);

  return <Markdown>{displayedText}</Markdown>;
});

StreamingText.displayName = 'StreamingText';

StreamingText.propTypes = {
  text: PropTypes.string.isRequired,
  speed: PropTypes.number,
  scrollToBottom: PropTypes.func.isRequired,
};

StreamingText.defaultProps = {
  speed: 10,
};

export default StreamingText;
