import React from 'react';
import PropTypes from 'prop-types';

import Card from '../../../../../../../Card';
import Button from '../../../../../../../common/Button';

import formatDate from '../../../../../../../../lib/dateFormatter';

const RaterCard = ({
  raterEmail,
  raterName,
  raterSurveyStatus,
  joinedAt,
  wasReminded,
  remindedAt,
  handleRemindRater,
  handleConfirmRemove,
}) => {
  const raterDescription = (
    <div className="-mt-2">
      {raterEmail}<br /> <br />
      <b>Date Accepted: </b>{(joinedAt) ? formatDate(joinedAt) : '-'}<br />
      <b>Survey Status: </b> {(raterSurveyStatus === 'finished')
        ? 'Completed'
        : [
          (raterSurveyStatus === 'started')
            ? 'In Progress'
            : 'Not Started',
        ]}

      {remindedAt ? (<><br /><b>Reminded At: </b> {formatDate(remindedAt)}</>) : null}
    </div>
  );

  return (
    <Card
      displayName={raterName}
      displayDescription={raterDescription}
    >
      {raterSurveyStatus !== 'finished'
        ? (
          <Button
            className="mb-1"
            isFullWidth
            isNormalCase
            variant="primary"
            onClick={handleRemindRater}
          >
            {wasReminded ? 'Reminder Sent' : 'Remind'}
          </Button>
        )
        : null}

      <Button
        isFullWidth
        isWarning
        id="removeRater"
        onClick={() => handleConfirmRemove(true)}
      >
        Remove
      </Button>
    </Card>
  );
};

RaterCard.propTypes = {
  raterEmail: PropTypes.string.isRequired,
  raterName: PropTypes.string.isRequired,
  joinedAt: PropTypes.string.isRequired,
  raterSurveyStatus: PropTypes.string,
  wasReminded: PropTypes.bool.isRequired,
  remindedAt: PropTypes.string.isRequired,
  handleRemindRater: PropTypes.func.isRequired,
  handleConfirmRemove: PropTypes.func.isRequired,
};

RaterCard.defaultProps = {
  raterSurveyStatus: null,
};

export default RaterCard;
