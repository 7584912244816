import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { APIContext } from '../../../context/API';
import { RouterContext } from '../../../context/Router';
import { NotificationContext } from '../../../context/Notification';
import withServerSideData from '../../../HOC/withServerSideData';

import PageHeader from '../../PageHeader';
import Notification from '../../Notifications/Notification';
import InvitationForm from '../../Invitations/InvitationForm';

import groupAddRaterAction from '../../../actions/assessments/groups/groupAddRater';
import { trackAddRaters } from '../../../lib/tracker/rater';

const AddGroupRaters = ({ params: { assessment, id, memberId }, initialData }) => {
  const { apiService } = useContext(APIContext);
  const { router } = useContext(RouterContext);
  const { addNotification } = useContext(NotificationContext);

  const {
    asmtData: { displayName },
    asmtGroupData: {
      id: groupId,
      name: groupName,
    },
    memberData: {
      name: memberName,
      memberUserId,
      raterCustomMessage,
      ratersAddedByMember,
    },
  } = initialData;
  const [errorMessage, setErrorMessage] = useState(null);
  const handleFormSubmit = ({ invitations, customMessage }) => {
    groupAddRaterAction(
      apiService,
      groupId,
      memberUserId,
      {
        invitations,
        ...(memberId
          ? { raterCustomMessage: customMessage }
          : { raterCustomMessageByMember: customMessage }),
      },
    )
      .then(response => {
        if (response.message) {
          const readableErrorMessage = response.message.includes('E_VALIDATION')
            ? 'An email you submitted was invalid.'
            : 'Something went wrong. Please try again later.';
          window.scroll(0, 0);
          setErrorMessage(readableErrorMessage);
        } else {
          trackAddRaters({
            groupName,
            asmtName: displayName,
            asmtType: assessment,
            raterEmails: invitations,
            buildMethod: 'One-by-One',
            memberToRate: memberName,
          });
          if (memberId) {
            router.push({ pathname: `/assessments/${assessment}/group/${id}`, state: { memberId } });
          } else {
            addNotification({
              type: 'success',
              message: 'You successfully invited raters.',
            });
            router.push({ pathname: `/assessments/${assessment}` });
          }
        }
      })
      .catch(() => setErrorMessage('Something went wrong. Please try again later.'));
  };

  return (
    <>

      {memberId ? (
        <PageHeader
          pageTitle={`${displayName} - Add Raters`}
          icon="ibi-symbol"
          backLink={{ to: `/assessments/${assessment}/group/${groupId}`, text: `Back to ${groupName}` }}
          skipTarget="#add-raters"
        />
      ) : (
        <PageHeader
          pageTitle={`${displayName} - Add Your Own Raters`}
          icon="ibi-symbol"
          backLink={{ to: `/assessments/${assessment}`, text: `Back to ${displayName} Home` }}
          skipTarget="#add-raters"
        />
      )}
      <div id="add-raters" className="mb-8 text-charcoal-900 bg-ivory-400 rounded-2xl">
        <div>
          <h3>{groupName}</h3>
          {memberId ? (
            <p className="max-w-4xl m-0 mb-6">Use this form to invite Raters for <span className="font-normal">{memberName}</span>. We recommend notifying raters about the 360 process prior to sending invitations. Read more and see an email template in our <a href="https://aperian.zendesk.com/hc/en-us/articles/4415922920083" target="_blank" rel="noopener noreferrer">Help Center</a>.</p>
          ) : (
            <p className="max-w-4xl m-0 mb-6">
              As part of the IBI 360, we encourage you to invite your colleagues
              to provide feedback on your inclusive behaviors in the workplace.
              By receiving the feedback of people you work closely with,
              you can learn if your impact is matching your intent.
              The people you invite will rate you anonymously,
              and when at least three have completed their survey,
              you will receive your IBI 360 report.
              We recommend inviting at least four or five people.&nbsp;
              <a href="https://aperian.zendesk.com/hc/en-us/articles/4432540772627-Criteria-for-Selecting-Raters" target="_blank" rel="noopener noreferrer">Here are some best practices for adding raters</a>.
            </p>
          )}
        </div>
        {errorMessage && <Notification type="failure" message={errorMessage} />}
        <InvitationForm
          sendMessage="Send Invitation"
          invitationType="Rater invitation"
          handleFormSubmit={handleFormSubmit}
          customMessage={raterCustomMessage}
          isCustomMessageVisible
          ratersAddedByMember={ratersAddedByMember}
          isAorPage={!memberId}
        />
      </div>
    </>
  );
};

AddGroupRaters.getAPIDataKey = () => 'asmtRaterInviteData';

AddGroupRaters.getData = (apiService, { assessment, id, memberId }) => {
  let url = `invitations/assessments/${assessment}?group=${id}`;
  if (memberId) {
    url += `&memberId=${memberId}`;
  }
  return apiService.get(url).then(data => ({ asmtRaterInviteData: data }));
};

AddGroupRaters.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    assessment: PropTypes.string.isRequired,
    memberId: PropTypes.string,
  }).isRequired,
  initialData: PropTypes.shape({
    asmtData: PropTypes.shape({
      displayName: PropTypes.string,
    }),
    asmtGroupData: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      assessment: PropTypes.number,
      deleted: PropTypes.bool,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
    }),
    memberData: PropTypes.shape({
      name: PropTypes.string,
      memberUserId: PropTypes.string,
      raterCustomMessage: PropTypes.string,
      ratersAddedByMember: PropTypes.arrayOf(
        PropTypes.shape({
          recipientEmail: PropTypes.string,
        }),
      ),
    }),

  }).isRequired,
};

export default withServerSideData(AddGroupRaters);
