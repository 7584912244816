const addOrganizationSegment = (apiService, id, data) => apiService.post(`organizations/${id}/segments`, data);

const updateOrganizationSegment = (apiService, id, data) => apiService.put(`organizations/${id}/segments`, data);

const deleteOrganizationSegment = (apiService, id, data) => apiService.delete(`organizations/${id}/segments`, data);

export {
  addOrganizationSegment,
  updateOrganizationSegment,
  deleteOrganizationSegment,
};
