import React from 'react';
import { iconPropTypes, defaultIconProps } from './types';

const Icon360 = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" className={className} viewBox="0 0 100 100" version="1.1">
    <g id="surface1">
      <path fillRule="nonzero" fillOpacity="1" stroke="none" fill="rgb(100%,100%,100%)" d="M 98.5 50 C 98.5 23.214844 76.785156 1.5 50 1.5 C 23.214844 1.5 1.5 23.214844 1.5 50 C 1.5 76.785156 23.214844 98.5 50 98.5 C 76.785156 98.5 98.5 76.785156 98.5 50 Z M 98.5 50 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 24.667969 30.746094 C 22.746094 28.828125 19.628906 28.828125 17.707031 30.746094 C 15.789062 32.667969 15.789062 35.785156 17.707031 37.707031 C 19.628906 39.625 22.746094 39.625 24.667969 37.707031 C 26.589844 35.785156 26.589844 32.667969 24.667969 30.746094 Z M 24.667969 30.746094 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 30.183594 19.863281 C 27.355469 19.195312 24.523438 20.945312 23.851562 23.773438 C 23.183594 26.601562 24.933594 29.433594 27.761719 30.101562 C 30.589844 30.769531 33.421875 29.019531 34.089844 26.195312 C 34.757812 23.367188 33.007812 20.53125 30.183594 19.863281 Z M 30.183594 19.863281 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 43.367188 14.980469 C 41.179688 12.792969 37.632812 12.792969 35.445312 14.980469 C 33.261719 17.164062 33.261719 20.710938 35.445312 22.898438 C 37.632812 25.085938 41.179688 25.085938 43.367188 22.898438 C 45.554688 20.710938 45.554688 17.164062 43.367188 14.980469 Z M 43.367188 14.980469 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 54.46875 11.667969 C 51.621094 9.90625 47.886719 10.789062 46.128906 13.636719 C 44.371094 16.484375 45.253906 20.21875 48.101562 21.976562 C 50.949219 23.738281 54.679688 22.855469 56.441406 20.007812 C 58.199219 17.160156 57.316406 13.425781 54.46875 11.667969 Z M 54.46875 11.667969 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 21.660156 45.558594 C 21.660156 43.03125 19.609375 40.980469 17.078125 40.980469 C 14.550781 40.980469 12.5 43.03125 12.5 45.558594 C 12.5 48.089844 14.550781 50.140625 17.078125 50.140625 C 19.609375 50.140625 21.660156 48.089844 21.660156 45.558594 Z M 21.660156 45.558594 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 17.414062 53.375 C 15.078125 53.183594 13.03125 54.917969 12.839844 57.253906 C 12.648438 59.585938 14.382812 61.632812 16.71875 61.824219 C 19.050781 62.019531 21.097656 60.28125 21.292969 57.949219 C 21.484375 55.613281 19.746094 53.566406 17.414062 53.375 Z M 17.414062 53.375 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 25.078125 68.980469 C 25.078125 66.835938 23.34375 65.101562 21.199219 65.101562 C 19.058594 65.101562 17.320312 66.835938 17.320312 68.980469 C 17.320312 71.121094 19.058594 72.859375 21.199219 72.859375 C 23.34375 72.859375 25.078125 71.121094 25.078125 68.980469 Z M 25.078125 68.980469 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 32.5 78.21875 C 32.5 76.265625 30.914062 74.679688 28.960938 74.679688 C 27.003906 74.679688 25.421875 76.265625 25.421875 78.21875 C 25.421875 80.175781 27.003906 81.761719 28.960938 81.761719 C 30.914062 81.761719 32.5 80.175781 32.5 78.21875 Z M 32.5 78.21875 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 42.621094 84.261719 C 42.621094 86.019531 41.179688 87.460938 39.421875 87.460938 C 37.660156 87.460938 36.21875 86.019531 36.21875 84.261719 C 36.21875 82.5 37.660156 81.058594 39.421875 81.058594 C 41.179688 81.058594 42.621094 82.5 42.621094 84.261719 Z M 42.621094 84.261719 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 51.964844 83.601562 C 50.425781 83.238281 48.886719 84.1875 48.523438 85.726562 C 48.160156 87.261719 49.109375 88.804688 50.648438 89.167969 C 52.183594 89.53125 53.726562 88.578125 54.089844 87.039062 C 54.453125 85.503906 53.5 83.964844 51.964844 83.601562 Z M 51.964844 83.601562 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 65.699219 84.261719 C 65.699219 85.640625 64.578125 86.78125 63.179688 86.78125 C 61.78125 86.78125 60.660156 85.660156 60.660156 84.261719 C 60.660156 82.859375 61.78125 81.738281 63.179688 81.738281 C 64.578125 81.738281 65.699219 82.859375 65.699219 84.261719 Z M 65.699219 84.261719 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 75.820312 78.21875 C 75.820312 79.421875 74.839844 80.398438 73.640625 80.398438 C 72.441406 80.398438 71.460938 79.421875 71.460938 78.21875 C 71.460938 77.019531 72.441406 76.039062 73.640625 76.039062 C 74.839844 76.039062 75.820312 77.019531 75.820312 78.21875 Z M 75.820312 78.21875 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 83.21875 68.980469 C 83.21875 70 82.398438 70.820312 81.378906 70.820312 C 80.359375 70.820312 79.539062 70 79.539062 68.980469 C 79.539062 67.960938 80.359375 67.140625 81.378906 67.140625 C 82.398438 67.140625 83.21875 67.960938 83.21875 68.980469 Z M 83.21875 68.980469 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 87.019531 57.640625 C 87.019531 58.460938 86.359375 59.121094 85.539062 59.121094 C 84.71875 59.121094 84.058594 58.460938 84.058594 57.640625 C 84.058594 56.820312 84.71875 56.160156 85.539062 56.160156 C 86.359375 56.160156 87.019531 56.820312 87.019531 57.640625 Z M 87.019531 57.640625 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 82.199219 34.21875 C 82.199219 34.660156 81.839844 35.019531 81.398438 35.019531 C 80.960938 35.019531 80.601562 34.660156 80.601562 34.21875 C 80.601562 33.78125 80.960938 33.421875 81.398438 33.421875 C 81.839844 33.421875 82.199219 33.78125 82.199219 34.21875 Z M 82.199219 34.21875 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 74.101562 24.980469 C 74.101562 25.238281 73.898438 25.441406 73.640625 25.441406 C 73.378906 25.441406 73.179688 25.238281 73.179688 24.980469 C 73.179688 24.71875 73.378906 24.519531 73.640625 24.519531 C 73.898438 24.519531 74.101562 24.71875 74.101562 24.980469 Z M 74.101562 24.980469 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 63.640625 18.941406 C 63.640625 19.199219 63.441406 19.398438 63.179688 19.398438 C 62.921875 19.398438 62.71875 19.199219 62.71875 18.941406 C 62.71875 18.679688 62.921875 18.480469 63.179688 18.480469 C 63.441406 18.480469 63.640625 18.679688 63.640625 18.941406 Z M 63.640625 18.941406 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 42.78125 56 C 42.738281 63.460938 30.140625 63.699219 28.558594 56.941406 L 32.578125 55.878906 C 33.078125 57.878906 35.738281 58.839844 37.480469 57.621094 C 38.738281 56.761719 38.621094 54.539062 37.179688 53.921875 C 36.101562 53.359375 34.660156 53.421875 33.480469 53.421875 L 33.480469 50.261719 C 34.578125 50.238281 35.980469 50.378906 36.960938 49.839844 C 38.160156 49.300781 38.320312 47.320312 37.261719 46.578125 C 35.820312 45.460938 33.480469 46.238281 33.160156 48.101562 L 29.140625 47.179688 C 30.378906 42.539062 36.71875 41.28125 40.378906 43.800781 C 43.359375 45.660156 42.980469 50.878906 39.320312 51.679688 L 39.320312 51.761719 C 41.28125 52.199219 42.859375 53.941406 42.839844 55.980469 Z M 42.78125 56 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 59.898438 55.160156 C 59.480469 65.820312 41.941406 62.71875 46.078125 52.300781 C 46.78125 50.320312 50.820312 44.878906 51.960938 43.019531 L 57.199219 43.019531 C 57.121094 43.140625 52.679688 49.261719 52.621094 49.398438 C 56.199219 48.339844 60.039062 51.421875 59.878906 55.160156 Z M 55.578125 55.261719 C 55.699219 51.441406 49.679688 51.480469 49.820312 55.28125 C 49.71875 59.078125 55.738281 59.140625 55.578125 55.261719 Z M 55.578125 55.261719 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 76.578125 52.121094 C 77 65.179688 61.859375 65.140625 62.28125 52.121094 C 62.121094 47.621094 64.238281 42.359375 69.460938 42.539062 C 74.679688 42.359375 76.738281 47.640625 76.578125 52.121094 Z M 72.140625 52.121094 C 72.398438 44.199219 66.480469 44.261719 66.71875 52.121094 C 66.460938 60.058594 72.421875 60.101562 72.140625 52.121094 Z M 72.140625 52.121094 " />
      <path fill="rgb(93.725491%,32.941177%,24.313726%)" fillOpacity="1" fillRule="nonzero" stroke="none" d="M 84.640625 38.78125 C 80.539062 37.039062 76.039062 41.359375 78.539062 45.359375 C 78.960938 46.039062 79.558594 46.621094 80.261719 47 C 84.28125 49.199219 89.019531 44.980469 87.019531 41.019531 C 86.519531 40.019531 85.660156 39.21875 84.640625 38.78125 Z M 80.28125 42.039062 C 81.460938 38.578125 86.261719 40.679688 85.078125 43.960938 C 83.921875 47.359375 79.101562 45.28125 80.28125 42.039062 Z M 80.28125 42.039062 " />
    </g>
  </svg>
);

Icon360.propTypes = iconPropTypes;
Icon360.defaultProps = defaultIconProps;

export default Icon360;
