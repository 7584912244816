import React from 'react';
import ReactDOM from 'react-dom';
import { Router, browserHistory } from 'react-router';
import * as braze from '@braze/web-sdk';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-datepicker/dist/react-datepicker.css';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import 'survey-core/defaultV2.min.css';
import 'nouislider/distribute/nouislider.css';
import * as SurveyCore from 'survey-core';
import * as widgets from 'surveyjs-widgets';

import './lib/rollbar';
import './lib/mixpanel';
import './lib/csTracker';
import './lib/safeFocus';

import routes from './routes';

widgets.nouislider(SurveyCore);

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line global-require
  require('../styles/index.css');
}

function logPageView() {
  window?.Appcues?.page();
}

braze.initialize(process.env.BRAZE_SDK_API_KEY, {
  baseUrl: process.env.BRAZE_SDK_ENDPOINT,
  contentSecurityNonce: window?.brazeNonce,
});
braze.showInAppMessage();
braze.toggleLogging();

window.braze = braze;

ReactDOM.hydrate(
  <Router history={browserHistory} onUpdate={logPageView}>
    {routes}
  </Router>,
  document.getElementById('root'),
);
