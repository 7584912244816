import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button';
import LabelInput from '../../LabelInput';
import Spinner from '../../../Spinner';

const ConfirmModal = ({
  title,
  confirmText,
  onConfirm,
  onCancel,
  confirmButtonText,
  children,
}) => {
  const [confirmInput, setConfirmInput] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);
    onConfirm();
  };

  const handleChange = e => {
    setConfirmInput(e.target.value);
    if (e.target.value === confirmText) setIsDisabled(false);
    else setIsDisabled(true);
  };

  return (
    <>
      <h4>{title}</h4>
      {children}
      <div className="mb-4">
        <LabelInput
          id="confirmInput"
          name="confirmInput"
          value={confirmInput}
          isDisabled={isLoading}
          onChangeValue={handleChange}
        />
      </div>
      {isLoading
        ? (
          <div className="flex justify-center">
            <Spinner margin="0.5rem 0 0" />
          </div>
        )
        : (
          <div className="flex justify-end gap-2">
            <Button
              onClick={onCancel}
              isSmall
              variant="secondary"
            >
              No, Cancel
            </Button>
            <Button
              onClick={handleClick}
              disabled={isDisabled}
              isSmall
              isWarning
            >
              {confirmButtonText}
            </Button>
          </div>
        )}
    </>
  );
};

ConfirmModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  confirmButtonText: PropTypes.string.isRequired,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ConfirmModal.defaultProps = {
  children: null,
  confirmText: 'DELETE',
};

export default ConfirmModal;
