import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import DOMPurify from 'isomorphic-dompurify';
import Icon from '../Icon';

const TimePickerInput = forwardRef((props, ref) => {
  const [dates, setDates] = useState(() => {
    if (props.value) {
      const sanitized = DOMPurify.sanitize(props.value);
      const date = new Date(sanitized);
      if (isNaN(date.getTime())) {
        return props.multiple ? [] : null;
      }
      return props.multiple ? [date] : date;
    }
    return props.multiple ? [] : null;
  });
  const [newDate, setNewDate] = useState(null);

  useEffect(() => {
    if (!props.multiple) {
      setDates(null); // Ensure it resets to null when switching to single mode
      if (typeof props.handleTimeChange === 'function') {
        props.handleTimeChange(null); // Notify parent component
      }
    }
  }, [props.multiple]);

  useEffect(() => {
    if (props.errorMessage && ref.current) {
      ref.current.input.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      ref.current.setFocus();
    }
  }, [props.errorMessage]);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);

  const handleAddDate = () => {
    if (newDate instanceof Date && !isNaN(newDate.getTime())) {
      const updatedDates = [...(Array.isArray(dates) ? dates : []), newDate];
      setDates(updatedDates);
      setNewDate(null);
      props.handleTimeChange(updatedDates);
    }
  };

  const handleDateChange = (dateOrIndex, date) => {
    const selectedDate = props.multiple ? date : dateOrIndex;

    if (selectedDate instanceof Date && !isNaN(selectedDate.getTime())) {
      if (props.multiple) {
        if (typeof dateOrIndex === 'number') {
        // Update a specific index in multiple mode
          const updatedDates = [...dates];
          updatedDates[dateOrIndex] = selectedDate;
          setDates(updatedDates);
          props.handleTimeChange(updatedDates);
        } else {
        // Append new date if in multiple mode
          setDates(prevDates => [...prevDates, selectedDate]);
          props.handleTimeChange([...dates, selectedDate]);
        }
      } else {
      // Single mode should only store one date
        setDates(selectedDate);
        props.handleTimeChange(selectedDate);
      }
    } else if (!props.multiple) {
      setDates(null); // Ensure reset for single mode
      props.handleTimeChange(null);
    }
  };

  const handleRemoveDate = index => {
    const updatedDates = dates.filter((_, i) => i !== index);
    setDates(updatedDates);
    props.handleTimeChange(updatedDates);
  };

  const getSelectedDate = () => {
    if (props.multiple) {
      return newDate instanceof Date && !isNaN(newDate.getTime()) ? newDate : null;
    }
    return dates instanceof Date && !isNaN(dates.getTime()) ? dates : null;
  };

  return (
    <div className="w-full">
      <label
        htmlFor={`${props.id}`}
        className={`font-sans block text-sm text-left font-medium leading-6 text-gray-700 ${
          props.labelText ? 'visible mb-2' : 'invisible'
        }`}
      >
        {props.labelText}
      </label>

      <div className="relative datepicker">
        <DatePicker
          ref={ref}
          id={`${props.id}${props.multiple ? '-new' : ''}`}
          wrapperClassName="w-full rounded-lg shadow-sm"
          className="font-sans font-normal box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500 focus:shadow-input sm:text-sm sm:leading-6"
          dateFormat={props.dateFormat || 'MM/dd/yyyy h:mm aa'}
          selected={getSelectedDate()}
          onChange={props.multiple ? setNewDate : handleDateChange}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          showTimeSelect={props.showTimeSelect}
          showTimeSelectOnly={props.showTimeSelectOnly}
          timeIntervals={props.timeIntervals}
          timeCaption={props.timeCaption}
          popperPlacement="auto"
          minDate={props.allowPreviousDates ? null : currentDate}
          placeholderText="Select a date and time"
        />
        <i
          role="button"
          aria-label="Clock"
          className={`absolute flex items-center justify-center w-6 h-6 cursor-pointer top-[0.375rem] ${
            props.multiple ? 'right-12' : 'right-2'
          }`}
          onClick={props.handleIconClick}
          onKeyDown={props.handleIconClick}
          tabIndex={-1}
        >
          <Icon icon="clock" iconColor="dark-gray" className="top-[0.635rem] w-4 h-4" />
        </i>
        {props.multiple && (
          <button
            onClick={handleAddDate}
            disabled={!newDate}
            className="absolute px-2 py-1 ml-2 text-sm font-medium text-white rounded-md top-1 right-1 bg-rust-500 hover:bg-rust-600 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Add
          </button>
        )}
      </div>

      {props.helperText && !props.errorMessage ? (
        <p className="mt-2 font-sans text-sm text-left text-gray-500">{props.helperText}</p>
      ) : null}
      {props.errorMessage ? (
        <p className="h-4 mt-2 font-sans text-sm text-left text-rust-500">{props.errorMessage}</p>
      ) : null}

      {props.multiple && dates && dates.length > 0
        && dates.every(date => date instanceof Date && !isNaN(date.getTime())) && (
        <div className="mt-4">
          <h3 className="mb-2 font-sans text-sm font-medium text-gray-700">Selected Dates:</h3>
          <ul className="space-y-3">
            {dates.map((date, index) => (
              <li key={date.getTime()} className="flex items-center space-x-3">
                <div className="relative flex-1 datepicker">
                  <DatePicker
                    wrapperClassName="w-full rounded-lg shadow-sm"
                    className="font-sans font-normal box-border block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-50 placeholder:text-gray-500 focus:ring-1 focus:ring-inset focus:ring-rust-500 focus:shadow-input sm:text-sm sm:leading-6"
                    dateFormat={props.dateFormat || 'MM/dd/yyyy h:mm aa'}
                    selected={date}
                    onChange={selectedDate => handleDateChange(index, selectedDate)}
                    onBlur={props.onBlur}
                    onKeyDown={props.onKeyDown}
                    showTimeSelect={props.showTimeSelect}
                    showTimeSelectOnly={props.showTimeSelectOnly}
                    timeIntervals={props.timeIntervals}
                    timeCaption={props.timeCaption}
                    popperPlacement="auto"
                    minDate={props.allowPreviousDates ? null : currentDate}
                  />
                  <i
                    role="button"
                    aria-label="Clock"
                    className="absolute flex items-center justify-center w-6 h-6 cursor-pointer top-[0.375rem] right-2"
                    onClick={props.handleIconClick}
                    onKeyDown={props.handleIconClick}
                    tabIndex={-1}
                  >
                    <Icon icon="clock" iconColor="dark-gray" className="top-[0.635rem] w-4 h-4" />
                  </i>
                </div>
                <button
                  onClick={() => handleRemoveDate(index)}
                  className="text-sm font-medium text-rust-500 hover:text-rust-700"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

TimePickerInput.displayName = 'TimePickerInput';

TimePickerInput.propTypes = {
  id: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.array,
  ]),
  dateFormat: PropTypes.string,
  onKeyDown: PropTypes.func,
  handleTimeChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  helperText: PropTypes.string,
  errorMessage: PropTypes.string,
  handleIconClick: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeIntervals: PropTypes.number,
  timeCaption: PropTypes.string,
  allowPreviousDates: PropTypes.bool,
  multiple: PropTypes.bool,
};

TimePickerInput.defaultProps = {
  labelText: '',
  value: '',
  dateFormat: '',
  onKeyDown: () => {},
  onBlur: () => {},
  handleIconClick: () => {},
  helperText: '',
  errorMessage: '',
  showTimeSelect: false,
  showTimeSelectOnly: false,
  timeIntervals: 0,
  timeCaption: '',
  allowPreviousDates: false,
  multiple: false,
};

export default TimePickerInput;
