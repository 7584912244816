/* eslint-disable react/function-component-definition */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Confetti from 'react-confetti';
import PropTypes from 'prop-types';

import PopoverComponent from '../../../components/common/Popover';
import ShareSkillsPopover from '../ShareSkillsPopover';
import Button from '../../../components/common/Button';
import Icon from '../../../components/common/Icon';

export default function SkillCelebration({
  skill,
  isCopied,
  onClose,
  skillBadgeShareOptions,
  allowShareSkillsBadge,
}) {
  const [isOpen, setIsOpen] = useState(true);
  const [screenSize, setScreenSize] = useState(null);

  useEffect(() => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => onClose(), 1000);
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(true);
  }, [skill]);

  function closeModal() {
    setIsOpen(false);
  }

  if (!skill) return null;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Confetti
              width={screenSize?.width}
              height={screenSize?.height}
              numberOfPieces={250}
              colors={['#FF532A', '#5F9B5F', '#F5C86E', '#3791CD', '#8080CE']}
            />
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="z-20 w-full max-w-md p-6 text-center align-middle transition-all transform bg-white border shadow-2xl rounded-2xl">
                <div className="flex justify-end">
                  <Button
                    className="p-0 bg-white border-none h-fit focus:bg-white"
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    isSmall
                  >
                    <Icon
                      icon="x-close"
                      className="w-3 h-3"
                      iconColor="dark-gray"
                    />
                  </Button>
                </div>
                <Dialog.Title
                  as="h1"
                  className="text-3xl font-medium leading-6 text-gray-900"
                >
                  Congratulations!
                </Dialog.Title>
                <div className="flex flex-col items-center gap-4 mt-2">
                  <img
                    alt={skill.name}
                    src={skill.iconImage}
                    className="max-w-48 max-h-48"
                  />
                  <div>
                    <h4 className="mb-0">You just achieved your </h4>
                    <h4>{skill.name} skill!</h4>
                  </div>
                  {allowShareSkillsBadge && (
                  <PopoverComponent
                    position="bottom"
                    content={(
                      <ShareSkillsPopover
                        isCopied={isCopied}
                        skillShareOptions={skillBadgeShareOptions(
                          skill.id,
                          skill.name,
                          skill.shareId,
                        )}
                      />
                    )}
                    className="w-max"
                    singleLineClassName="px-4 py-2"
                    isStatic
                  >
                    <Button
                      isExtraSmall
                      variant="secondary"
                      trailingButtonIcon="share"
                      iconColor="primary"
                      className="flex justify-center mt-2"
                    >
                      Share
                    </Button>
                  </PopoverComponent>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

SkillCelebration.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    iconImage: PropTypes.string.isRequired,
    shareId: PropTypes.string,
  }),
  isCopied: PropTypes.bool,
  onClose: PropTypes.func,
  skillBadgeShareOptions: PropTypes.func,
  allowShareSkillsBadge: PropTypes.bool.isRequired,
};

SkillCelebration.defaultProps = {
  skill: null,
  isCopied: false,
  onClose: () => {},
  skillBadgeShareOptions: () => {},
};
