import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '../../common/Avatars/Avatar';

const CompareCard = ({
  className,
  comparable,
  cardDisplayName,
  avatarUrl,
  checked,
  onChangeCard,
  isCulture,
}) => (
  <div className={className}>
    <input
      id={comparable.id}
      type="checkbox"
      checked={checked}
      className="hidden peer"
      onChange={e => onChangeCard(comparable, e.target.checked)}
    />
    <label
      htmlFor={comparable.id}
      className="flex flex-col items-center justify-center w-full h-full transition-shadow duration-300 ease-in-out bg-white rounded-lg shadow-md cursor-pointer hover:shadow-xl peer-checked:ring-1 peer-checked:ring-inset peer-checked:shadow-input peer-checked:ring-rust-500"
    >
      <Avatar avatarUrl={avatarUrl} isCulture={isCulture} />
      <span className="mt-2 font-sans text-sm font-medium text-center line-clamp-2 text-charcoal-900 text-balance">{cardDisplayName}</span>
    </label>
  </div>
);

CompareCard.propTypes = {
  className: PropTypes.string.isRequired,
  cardDisplayName: PropTypes.string.isRequired,
  onChangeCard: PropTypes.func.isRequired,
  comparable: PropTypes.shape({
    id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    type: PropTypes.string.isRequired,
    htmlElementId: PropTypes.func.isRequired,
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  isCulture: PropTypes.bool.isRequired,
};

export default CompareCard;
