import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import SidebarLayout from '../SidebarLayout';
import PageHeader from '../../PageHeader';

import SkipTrigger from '../../SkipTrigger';

const AdminLayout = ({ location, pageTitle, children, icon }) => (
  <div className="flex flex-col min-h-screen bg-slate-100">
    <Helmet>
      <title>{pageTitle} | Aperian</title>
    </Helmet>
    <SkipTrigger target="#admin-content" text={`Skip to ${pageTitle}`} />
    <SidebarLayout
      pageTitle={pageTitle}
      location={location}
      icon={icon}
      layoutWidth={location.pathname.includes('copilot') ? '3xl' : 'md'}
    >
      <PageHeader
        pageTitle={pageTitle}
        location={location}
        icon={icon}
      />
      {children}
    </SidebarLayout>
  </div>
);

AdminLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  icon: PropTypes.string,
};

AdminLayout.defaultProps = {
  children: null,
  icon: '',
};

export default AdminLayout;
