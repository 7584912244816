import React from 'react';
import PropTypes from 'prop-types';

import { prefixHostname } from '../../../../lib/cdn';

export const SlickPrevArrow = ({ style, onClick }) => (
  <button
    aria-label="previous"
    className="block cursor font-0 leading-none p-0 -left-6 absolute top-1/2 w-5 -translate-y-1/2 bg-stone-300 hover:!bg-stone-500 opacity-75 h-full disabled:opacity-25"
    style={{ ...style }}
    onClick={onClick}
  >
    <img
      src={prefixHostname('/images/left-chevron.svg')}
      alt="Previous"
    />
  </button>
);

export const SlickNextArrow = ({ style, onClick }) => (
  <button
    aria-label="next"
    className="block cursor font-0 leading-none p-0 -right-6 absolute top-1/2 w-5 -translate-y-1/2 bg-stone-300 hover:!bg-stone-500 opacity-75 h-full disabled:opacity-25"
    style={{ ...style }}
    onClick={onClick}
  >
    <img
      src={prefixHostname('/images/right-chevron.svg')}
      alt="Next"
    />
  </button>
);

SlickNextArrow.propTypes = {
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

SlickNextArrow.defaultProps = {
  style: undefined,
  onClick: undefined,
};

SlickPrevArrow.propTypes = {
  style: PropTypes.shape({}),
  onClick: PropTypes.func,
};

SlickPrevArrow.defaultProps = {
  style: undefined,
  onClick: undefined,
};

export const sliderSettings = {
  variableWidth: true,
  dots: true,
  infinite: false,
  speed: 500,
  swipeToSlide: true,
  slidesToScroll: 5,
  nextArrow: <SlickNextArrow />,
  prevArrow: <SlickPrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        swipeToSlide: true,
        slidesToScroll: 3,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        swipeToSlide: true,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        swipeToSlide: true,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
  // eslint-disable-next-line react/no-unstable-nested-components
  customPaging: i => (
    <button className="before:!text-sm before:!text-gray-500">
      {i}
    </button>
  ),
};
