/* eslint-disable quotes */
/* eslint-disable quote-props */
import React from 'react';
import PropTypes from 'prop-types';

import {
  AperianLive,
  ArrowRight,
  Award,
  BellIcon,
  Bars3Icon,
  Building,
  Calendar,
  CalendarPlus,
  Check,
  CheckCircleBroken,
  Chevron,
  Clock,
  Close,
  Cog6Icon,
  Compare,
  CopilotSymbol,
  Copy,
  CreditCard,
  Culture,
  DefaultProfileChart,
  Download,
  Edit,
  Expand,
  Eye,
  EyeOff,
  File,
  Globe,
  GroupPlus,
  HelpCircle,
  Home,
  Ibi360,
  IBIAvatar,
  IBIProduct,
  IbiSymbol,
  Info,
  Invite,
  LearningModule,
  Link,
  LinkedIn,
  Location,
  Lock,
  Mail,
  Minimize,
  Minus,
  NewComparison,
  OrgAvatar,
  Passcode,
  Pencil,
  Plus,
  Profile,
  QuarterPie,
  Retake,
  Save,
  Search,
  Send,
  Share,
  StayCurious,
  Success,
  TeamAvatar,
  TrashCan,
  Upload,
  UserIcon,
  UserIconAvatar,
  SegmentAvatar,
  Quote,
} from './icons';

export const iconMap = {
  'aperian-live': AperianLive,
  'arrow-right': ArrowRight,
  'award': Award,
  "bell": BellIcon,
  "bars3": Bars3Icon,
  "calendar": Calendar,
  "calendar-plus": CalendarPlus,
  'building': Building,
  'check': Check,
  'check-circle-broken': CheckCircleBroken,
  'chevron-down': Chevron,
  'chevron-left': Chevron,
  'chevron-right': Chevron,
  'chevron-up': Chevron,
  'clock': Clock,
  'cog-6': Cog6Icon,
  'compare': Compare,
  'copilot': CopilotSymbol,
  'copy': Copy,
  'credit-card': CreditCard,
  'culture-guide': Culture,
  'default-profile-chart': DefaultProfileChart,
  'download': Download,
  'edit': Edit,
  'expand': Expand,
  'eye-off': EyeOff,
  'eye': Eye,
  'file': File,
  'globe': Globe,
  'group-plus': GroupPlus,
  'help': HelpCircle,
  'home': Home,
  'ibi-360': Ibi360,
  'ibi': IBIAvatar,
  'ibi-product': IBIProduct,
  'ibi-symbol': IbiSymbol,
  'info': Info,
  'invite': Invite,
  'learning': LearningModule,
  'link': Link,
  'linkedin': LinkedIn,
  'location': Location,
  'lock': Lock,
  'minimize': Minimize,
  'mail': Mail,
  'minus': Minus,
  'new-compare': NewComparison,
  'org': OrgAvatar,
  'passcode': Passcode,
  'pencil': Pencil,
  'plus': Plus,
  'profile': Profile,
  'quarter-pie': QuarterPie,
  'quote': Quote,
  'retake': Retake,
  'save': Save,
  'send': Send,
  'search': Search,
  'segment': SegmentAvatar,
  'share': Share,
  'stay-curious': StayCurious,
  'success': Success,
  'team': TeamAvatar,
  'trash': TrashCan,
  'upload': Upload,
  'user-icon-avatar': UserIconAvatar,
  'user-icon': UserIcon,
  'x-close': Close,
};

export const strokeColorMap = {
  primary: 'stroke-charcoal-900 group-disabled:stroke-gray-300',
  'medium-primary': 'stroke-charcoal-300 group-disabled:stroke-gray-300',
  secondary: 'stroke-rust-500 group-disabled:stroke-gray-300',
  red: 'stroke-red-500 group-disabled:stroke-gray-300',
  yellow: 'stroke-yellow-500 group-disabled:stroke-gray-3000',
  green: 'stroke-green-500 group-disabled:stroke-gray-300',
  blue: 'stroke-blue-400 group-disabled:stroke-gray-300',
  violet: 'stroke-violet-400 group-disabled:stroke-gray-300',
  white: 'stroke-white',
  black: 'stroke-black',
  gray: 'stroke-slate-400',
  'dark-gray': 'stroke-slate-900',
  current: 'stroke-current',
};

export const fillColorMap = {
  primary: 'fill-charcoal-900 group-disabled:fill-gray-300',
  'medium-primary': 'fill-charcoal-300 group-disabled:fill-gray-300',
  secondary: 'fill-rust-500 group-disabled:fill-gray-300',
  red: 'fill-red-500 group-disabled:fill-gray-300',
  yellow: 'fill-yellow-500 group-disabled:fill-gray-300',
  green: 'fill-green-500 group-disabled:fill-gray-300',
  blue: 'fill-blue-400 group-disabled:fill-gray-300',
  violet: 'fill-violet-400 group-disabled:fill-gray-300',
  white: 'fill-white',
  black: 'fill-black',
  gray: 'fill-slate-400',
  'dark-gray': 'fill-slate-900',
};

const Icon = ({ icon, className, iconColor }) => {
  const Component = iconMap[icon];
  return (
    <Component
      icon={icon}
      className={className}
      iconColor={iconColor}
    />
  );
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconColor: PropTypes.string,
};

Icon.defaultProps = {
  className: 'w-5 h-5',
  iconColor: 'black',
};

export default Icon;
