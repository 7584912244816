import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import TipsForm from '../New/TipsForm';
import { APIContext } from '../../../../context/API';
import { NotificationContext } from '../../../../context/Notification';
import { deleteTipAction, updateTipAction } from '../../../../actions/tips';
import withServerSideData from '../../../../HOC/withServerSideData';
import Button from '../../../common/Button';
import { ModalContext } from '../../../../context/Modal';
import TipDeleteModal from './TipDeleteModal';
import { RouterContext } from '../../../../context/Router';
import GlobeSmartModal from '../../../GlobeSmartModal';

const TipsEdit = ({ initialData, params: { id } }) => {
  const { apiService } = useContext(APIContext);
  const { tipDetails: { title } } = initialData;
  const { handleOpenModal, handleCloseModal } = useContext(ModalContext);
  const { router } = useContext(RouterContext);

  const {
    notificationMessage,
    removeNotification,
    addNotification,
  } = useContext(NotificationContext);

  useEffect(() => {
    let timeout;
    if (notificationMessage) {
      timeout = window.setTimeout(() => {
        removeNotification();
      }, 5000);
    }
    return () => {
      window.clearTimeout(timeout);
      if (notificationMessage) removeNotification();
    };
  }, [notificationMessage]);

  const formSubmit = async tipData => {
    try {
      await updateTipAction(apiService, id, tipData);
      addNotification({
        type: 'success',
        message: 'Tip updated',
      });
    } catch (err) {
      addNotification({
        type: 'failure',
        message: `Error: ${err.message}`,
      });
    }
  };

  const handleCompleteDelete = () => {
    deleteTipAction(apiService, id)
      .then(() => {
        handleCloseModal();
        router.push('/administration/tips/search');
        addNotification({
          type: 'success',
          message: `${title} tip is deleted successfully.`,
        });
      })
      .catch(err => {
        addNotification({
          type: 'failure',
          message: `Could not delete the tip: ${err}`,
        });
      });
  };

  const handleConfirmDelete = e => {
    e.preventDefault();
    handleOpenModal({
      content: (
        <TipDeleteModal
          onConfirm={handleCompleteDelete}
          onCancel={handleCloseModal}
        />),
      className: 'max-w-md',
    });
  };

  return (
    <>
      <TipsForm
        formData={initialData}
        handleFormSubmit={formSubmit}
        buttonText="Update Tip"
        subHeader="Edit the Tip details"
        isEditForm
      />
      <Button onClick={handleConfirmDelete} isWarning isSmall className="float-right mb-4">
        Delete Tip
      </Button>
      <GlobeSmartModal />
    </>
  );
};

TipsEdit.getAPIDataKey = () => 'tipDetails';
TipsEdit.getData = (apiService, { id }) => apiService.get(`tips/${id}`)
  .then(tipDetails => ({ tipDetails }));

TipsEdit.propTypes = {
  initialData: PropTypes.shape({
    tipDetails: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      link: PropTypes.string,
      cultureProfile: PropTypes.number,
      type: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    }),
  }).isRequired,
  params: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

export default withServerSideData(TipsEdit);
