import React, { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Button from '../../../common/Button';
import { trackViewOnboardingSignupForm } from '../../../../lib/tracker/onboarding';
import LabelInput from '../../../common/LabelInput';
import Checkbox from '../../../common/Checkbox';
import Tooltip from '../../../common/Tooltip';
import PasswordInput from '../PasswordInput';
import Spinner from '../../../Spinner';
import { validateCreatorEmail } from '../../../../lib/onboarding';
import Icon from '../../../common/Icon';
import { prefixHostname } from '../../../../lib/cdn';

const InvalidEmailMessageTooltip = ({ isEdu }) => {
  if (isEdu) {
    // TODO: Add proper `href` for `click here to purchase` link.
    return (
      <>
        This trial is for organizations interested in licensing Aperian. If you are a professor or student looking for individual access, please <a href="https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Educational-Licensing-" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">click here to purchase</a> or <a href="https://aperian.com/contact" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">get in touch</a>.
      </>
    );
  }
  return (
    <>
      This trial is for organizations interested in licensing Aperian. Please sign up using your work email address or <a href="https://aperian.com/our-pricing-plans/" target="_blank" rel="noopener noreferrer" className="underline text-rust-500">purchase access</a>.
    </>
  );
};

InvalidEmailMessageTooltip.propTypes = {
  isEdu: PropTypes.bool.isRequired,
};

const OnboardingSingleForm = ({
  formData: {
    email,
    firstName,
    lastName,
    orgName,
    password,
    track,
  },
  onSubmit,
  handleUpdateForm,
  hasError,
}) => {
  const [formError, setFormError] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      firstName: null,
      lastName: null,
      email: null,
      isInvalid: null,
      password: null,
      track: null,
      isEdu: null,
    });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = e => {
    handleUpdateForm({ [e.target.name]: e.target.value });
    setFormError({ [e.target.name]: null });
  };

  useEffect(() => {
    trackViewOnboardingSignupForm();
  }, []);

  useEffect(() => {
    setIsTermsChecked(false);
  }, [hasError]);

  useEffect(() => {
    if (email === null) return;

    const { isEdu, isInvalid } = validateCreatorEmail(email);

    setFormError({ isEdu, isInvalid });
  }, [email]);

  useEffect(() => {
    if (password) {
      setFormError({ password: null });
    }
  }, [password]);

  const validateEmail = () => {
    if (!email) {
      return 'You must enter your email address.';
    }
    if (!email?.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/i)) {
      return 'Enter a valid email address.';
    }
    return null;
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    const errors = {
      firstName: !firstName && 'You must enter your first name.',
      lastName: !lastName && 'You must enter your last name.',
      email: validateEmail(),
      password: !password && 'You must enter a valid password.',
      track: !track && 'Please select any option.',
      orgName: !orgName && 'You must enter an organization name.',
    };

    if (Object.values(errors).some(value => value)
      || Object.values(formError).some(value => value)) {
      setFormError(errors);
      return;
    }
    setIsSubmitted(true);
    await onSubmit(e);
  };

  return (
    <div className="flex flex-wrap gap-4 duration-300 ease-in-out md:flex-nowrap md:flex-row sm:gap-1 xl:min-w-[1250px]">
      <div className="flex flex-col gap-6 p-4 md:p-12 md:w-1/2">
        <h1 className="font-serif text-4xl tracking-wider xl:text-[52px] text-charcoal-900">
          Start your trial
        </h1>
        <div className="flex flex-col [&>input]:bg-white gap-2 lg:gap-4 [&>div]:w-11/12">
          <LabelInput
            id="firstName"
            name="firstName"
            type="text"
            placeholderText="First Name"
            labelText="First Name"
            labelType="text"
            value={firstName}
            errorMessage={formError.firstName}
            onChangeValue={handleChange}
          />
          <LabelInput
            id="lastName"
            name="lastName"
            type="text"
            placeholderText="Last Name"
            labelText="Last Name"
            labelType="text"
            value={lastName}
            errorMessage={formError.lastName}
            onChangeValue={handleChange}
          />
          <Tooltip
            content={(formError?.isInvalid || formError?.isEdu)
              && <InvalidEmailMessageTooltip isEdu={formError?.isEdu} />}
          >
            <LabelInput
              id="email"
              name="email"
              type="text"
              placeholderText="Work Email"
              labelText="Work Email"
              labelType="text"
              value={email}
              onChangeValue={handleChange}
              errorMessage={formError.email}
              leadingIcon="mail"
            />
          </Tooltip>
          <PasswordInput
            isSingleStepForm
            handleUpdateForm={handleUpdateForm}
            passwordError={formError.password}
          />
          <LabelInput
            id="orgName"
            name="orgName"
            type="text"
            placeholderText="Organization Name"
            labelText="Organization"
            labelType="text"
            value={orgName}
            errorMessage={formError.orgName}
            onChangeValue={handleChange}
            leadingIcon="building"
            className="underline-transition text-charcoal-900 hover:text-rust-500 decoration-rust-500"
          />
        </div>
        <Checkbox
          id="termsOfUse"
          name="termsOfUse"
          checked={isTermsChecked}
          onChange={e => setIsTermsChecked(e.target.checked)}
          labelClasses="text-lg"
          labelText={(
            <span>I agree to the&nbsp;
              <a
                href="https://app.aperian.com/content/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a
                href="https://app.aperian.com/content/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
          )}
        />
        <div>
          {
            isSubmitted && !hasError
              ? (<Spinner margin="0.5rem" isSmall />)
              : (
                <Button
                  variant="primary"
                  disabled={!isTermsChecked && hasError}
                  onClick={handleSubmitForm}
                >Start 7-day Trial
                </Button>
              )
          }
        </div>
        <div>
          <p className="m-0 font-sans text-sm font-normal">
            Are you a professor or student? <a href="https://aperian.zendesk.com/hc/en-us/articles/18926425773971-Academic-Licensing">Create an academic account</a>.
          </p>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full p-6 bg-black md:w-1/2">
        <div className="relative text-center max-w-[33rem]">
          <div className="absolute z-10 flex right-3 -top-8">
            <Icon icon="quote" className="w-8 h-14" iconColor="secondary" />
            <Icon icon="quote" className="w-8 h-14" iconColor="secondary" />
          </div>
          <div className="relative p-8 bg-ivory-300 rounded-xl shadow-lg [clip-path:polygon(0%_0%,100%_0%,100%_100%,75px_100%,0%_64%)]">
            <p className="font-serif text-3xl leading-snug text-right text-charcoal-900">
              “Our employees love Aperian &ndash; they&#39;ve cascaded
              it through their teams and beyond.
              The platform has made them recognize things about themselves
              they weren&#39;t aware of and has led to real behavior change.”
            </p>
            <div className="text-right mt-7">
              <p className="mb-0 font-sans text-gray-900 text-md md:text-xl">&ndash; Lauren Rollins</p>
              <p className="ml-16 font-sans text-base text-gray-900">Global Talent Development Manager, <br /> Akamai Technologies</p>
            </div>
          </div>

          <div className="absolute bottom-4 -left-4">
            <img
              className="max-w-32 max-h-32 !md:max-w-32 !md:max-h-32"
              src={prefixHostname('/images/lauren-rollins.png')}
              alt="Lauren-Rollins Avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

OnboardingSingleForm.propTypes = {
  handleUpdateForm: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    invitationToken: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    userId: PropTypes.string,
    orgName: PropTypes.string,
    password: PropTypes.string,
    track: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
};

export default OnboardingSingleForm;
