const imageToDataUrl = async image => {
  const avatarSRC = image.getAttribute('src');
  const img = new Image();
  img.src = avatarSRC;
  img.crossOrigin = 'anonymous';
  await new Promise(resolve => {
    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      image.setAttribute('src', canvas.toDataURL('image/png'));
      resolve();
    });
  });
};

export default imageToDataUrl;
